import { BzDateFns, isNullish, IsoDateString } from '@breezy/shared'
import { Button, notification } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useInterval } from 'react-use'
import { getConfig } from '../config'
import { useFeatureFlag } from '../hooks/useFeatureFlags'

export const LATEST_PLATFORM_VERSION_KEY = 'latestPlatformVersionSessionKey'
const LAST_PLATFORM_VERSION_REFRESH_KEY = 'lastPlatformVersionRefreshKey'

export const clearCacheAndHardReloadOnAllBrowsers = () => {
  if ('caches' in window) {
    caches.keys().then(names => {
      names.forEach(async name => {
        await caches.delete(name)
      })
    })
  }

  // eslint-disable-next-line no-self-assign
  window.location.href = window.location.href

  // @ts-expect-error - Parameter is deprecated, but works on some browsers still
  window.location.reload(true)
}

export const openNotification = () =>
  notification.open({
    message: 'The version of the app you are using is out of date',
    description:
      'Please click the refresh button below to get the latest version',
    duration: 0,
    btn: (
      <Button
        type="primary"
        size="middle"
        onClick={clearCacheAndHardReloadOnAllBrowsers}
      >
        Refresh
      </Button>
    ),
    closeIcon: <>X</>,
  })

const apiUrl = getConfig().apiUrl
const myPlatformVersion = getConfig().platformVersion
const myLatestBreakingVersion = getConfig().latestBreakingVersion
const shouldCheckPlatformVersion = getConfig().shouldCheckPlatformVersion
const interval = 1000 * 60 // Every minute

// This wrapper is only used in the AccountExperience app due to the structure of the router. Eventually
// we should migrate to the AsyncPlatformVersionWrapper but due to the product nature of the AccountExperience,
// users aren't typically navigating between pages and it's usually a short-lived session that lasts only a few minutes.
export const PlatformVersionWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [latestPlatformVersion, setLatestPlatformVersion] =
    useState(myPlatformVersion)

  const platformVersionMismatchNotificationEnabled = useFeatureFlag(
    'platformVersionMismatchNotification',
  )

  const checkVersion = useCallback(() => {
    if (!shouldCheckPlatformVersion) return
    if (!platformVersionMismatchNotificationEnabled) return

    fetch(`${apiUrl}/platform-version`)
      .then(res => res.json())
      .then(({ platformVersion, latestBreakingVersion }) => {
        if (
          parseFloat(myLatestBreakingVersion) <
          parseFloat(latestBreakingVersion)
        ) {
          console.info('Refreshing because breaking version change')
          clearCacheAndHardReloadOnAllBrowsers()
        } else if (latestPlatformVersion !== platformVersion) {
          console.info(`Detected that current version is out of date`, {
            currentVersion: myPlatformVersion,
            latestVersion: platformVersion,
            currentBreakingVersion: myLatestBreakingVersion,
            latestBreakingVersion,
          })
          setLatestPlatformVersion(platformVersion)

          // Only show the notification once, otherwise it would appear multiple times if there were multiple releases
          if (latestPlatformVersion === myPlatformVersion) {
            openNotification()
          }
        }
      })
      .catch(e => {
        console.error(
          `There was an error fetching the latest platform version`,
          e,
        )
      })
  }, [
    latestPlatformVersion,
    setLatestPlatformVersion,
    platformVersionMismatchNotificationEnabled,
  ])

  useInterval(checkVersion, interval)
  return <>{children}</>
}

/**
 * This hook is used to poll our server in the background for the latest platform version
 * and update the session storage with the latest version. This will be used in conjunction
 * with the useVersionCheckOnNavigation hook to ensure that our users are always on the latest
 * platform version.
 */
export const useBackgroundVersionRefresher = () => {
  const [latestPlatformVersion, setLatestPlatformVersion] = useState('')
  useEffect(() => {
    const latestPlatformVersion = window.sessionStorage.getItem(
      LATEST_PLATFORM_VERSION_KEY,
    )
    if (latestPlatformVersion) {
      setLatestPlatformVersion(latestPlatformVersion)
    }
  }, [])

  const checkVersion = useCallback(() => {
    fetch(`${apiUrl}/platform-version`)
      .then(res => res.json())
      .then(({ platformVersion }) => {
        if (
          !isNullish(platformVersion) &&
          latestPlatformVersion !== platformVersion
        ) {
          console.info('Refreshing latest platform version', {
            platformVersion,
          })

          window.sessionStorage.setItem(
            LATEST_PLATFORM_VERSION_KEY,
            platformVersion,
          )
          setLatestPlatformVersion(platformVersion)
        }
      })
      .catch(e => {
        console.error(
          `There was an error fetching the latest platform version`,
          e,
        )
      })
  }, [latestPlatformVersion])

  useInterval(checkVersion, interval)
}

/**
 * This hook is used to check if the current platform version is out of date when a user navigates to a new page.
 * If it is, we will reload the page to ensure that the user is on the latest platform version. This is used in conjunction
 * with the useBackgroundVersionRefresher hook which polls our server for the latest platform version in the background instead
 * of adding a server round trip to every navigation event.
 */
export const useVersionCheckOnNavigation = () => {
  const location = useLocation()
  const navigationPlatformRefreshKillSwitchEnabled = useFeatureFlag(
    'navigation-platform-refresh-disabled',
  )

  // This will run on every navigation event.
  useEffect(() => {
    const latestPlatformVersion = window.sessionStorage.getItem(
      LATEST_PLATFORM_VERSION_KEY,
    )
    // Check if it's been at least 1 minute since last refresh so we don't get stuck in any sort of refresh deadlock
    const lastRefresh = window.sessionStorage.getItem(
      LAST_PLATFORM_VERSION_REFRESH_KEY,
    ) as IsoDateString
    const now = new Date().toISOString()
    if (
      lastRefresh &&
      BzDateFns.differenceInMinutes(
        BzDateFns.parseISO(now, BzDateFns.UTC),
        BzDateFns.parseISO(lastRefresh, BzDateFns.UTC),
      ) < 1
    ) {
      console.info('not refreshing because it was too soon since last refresh')
      return
    }

    if (
      !isNullish(latestPlatformVersion) &&
      latestPlatformVersion &&
      latestPlatformVersion !== myPlatformVersion
    ) {
      if (navigationPlatformRefreshKillSwitchEnabled) {
        console.info(
          'Navigation platform refresh kill switch is enabled, skipping version check',
        )
        return
      }

      console.info(
        'Detected that current version is out of date... reloading',
        {
          currentVersion: myPlatformVersion,
          latestVersion: latestPlatformVersion,
        },
      )
      window.sessionStorage.setItem(LAST_PLATFORM_VERSION_REFRESH_KEY, now)
      clearCacheAndHardReloadOnAllBrowsers()
    }
  }, [location, navigationPlatformRefreshKillSwitchEnabled])
}

// This wrapper is used in our Office and Technician apps to check for the latest platform version in the background
// and to hard-refresh the page if the current version is out of date during page navigation.
export const AsyncPlatformVersionWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  useBackgroundVersionRefresher()
  useVersionCheckOnNavigation()
  return <>{children}</>
}
