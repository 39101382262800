import { Dfns, dateFnsGenerateConfig } from '@breezy/shared'
import { Calendar as BaseCalendar, DatePicker as BaseDatePicker } from 'antd'

import React, { ComponentProps } from 'react'

export const CustomDatePicker = BaseDatePicker.generatePicker<Date>(
  dateFnsGenerateConfig,
)

export type DatePickerProps = ComponentProps<typeof CustomDatePicker>

export const DatePickerPropsDefault = {
  format: 'MM/dd/yyyy',
  disabledDate: date => !Dfns.isValid(date) || date.getFullYear() < 1900,
} satisfies DatePickerProps

/**
 * antd's DatePicker configured with date-fns and has some default properties.
 *
 * See {@link DatePickerPropsDefault} for set default properties.
 */

const DatePicker = React.forwardRef((props: DatePickerProps, ref) => {
  const resolvedProps: DatePickerProps = {
    ...DatePickerPropsDefault,
    ...props,
  }

  return <CustomDatePicker {...resolvedProps} />
})

export const Calendar = BaseCalendar.generateCalendar<Date>(
  dateFnsGenerateConfig,
)

export default DatePicker
