export const phoneAgentIdentity = (companyName: string, firstName: string, lastName: string): string => {
  const specialChars = /[^a-zA-Z0-9_]/g
  return `${companyName.replace(specialChars, '')}_${firstName.replace(specialChars, '')}_${lastName.replace(
    specialChars,
    '',
  )}`
}

export const externalCallId = (idSource: string, id: string): string => {
  return `${idSource}_${id}`
}

export const twilioPhoneNumberToBreezyPhone = (phoneNumber: string): string => {
  const cleanedNumber = phoneNumber.replace(/[^\d]/g, '')
  if (cleanedNumber.length === 11 && cleanedNumber.startsWith('1')) {
    const areaCode = cleanedNumber.substring(1, 4)
    const middleThree = cleanedNumber.substring(4, 7)
    const lastFour = cleanedNumber.substring(7, 11)
    return `(${areaCode}) ${middleThree}-${lastFour}`
  }
  return phoneNumber // Return original if it doesn't match expected format
}

export const toBreezyPhoneNumber = (phoneNumber: string): string => {
  // Remove all non-digit characters
  const cleanedNumber = phoneNumber.replace(/\D/g, '')

  // Check if it's a valid 10-digit number or an 11-digit number starting with 1
  if (cleanedNumber.length === 10 || (cleanedNumber.length === 11 && cleanedNumber.startsWith('1'))) {
    // If it's 11 digits and starts with 1, remove the leading 1
    const tenDigitNumber = cleanedNumber.length === 11 ? cleanedNumber.slice(1) : cleanedNumber

    // Format the number as (XXX) XXX-XXXX
    const areaCode = tenDigitNumber.slice(0, 3)
    const middleThree = tenDigitNumber.slice(3, 6)
    const lastFour = tenDigitNumber.slice(6)

    return `(${areaCode}) ${middleThree}-${lastFour}`
  }

  // If it doesn't match expected formats, return the original number
  // ATTN: This might need additional handling for international numbers
  return phoneNumber
}

export const breezyPhoneNumberToTwilioPhone = (phoneNumber: string): string => {
  const cleanedNumber = phoneNumber.replace(/\D/g, '')
  // Check if the number is already in E.164 format
  if (cleanedNumber.startsWith('1') && cleanedNumber.length === 11) {
    return `+${cleanedNumber}`
  }

  // If it's a 10-digit number, assume it's a US number and add +1
  if (cleanedNumber.length === 10) {
    return `+1${cleanedNumber}`
  }

  // If it doesn't match expected formats, return the original number
  // ATTN: This might need additional handling for international numbers
  return phoneNumber
}
