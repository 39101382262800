import { getMapAppType, MapAppType } from '@breezy/shared'
import { faApple, faGoogle, faWaze } from '@fortawesome/free-brands-svg-icons'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { message } from 'antd'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import BzSelect from '../../elements/BzSelect/BzSelect'
import { BzSelectOption } from '../../elements/BzSelect/BzSelectTypes'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import {
  GQL_USER_SETTINGS_QUERY,
  GQL_USER_SETTINGS_SET_MAP_PREFERENCE,
} from './MapAppPreferencePicker.gql'

export const MapAppPreferencePicker = m(() => {
  const userGuid = useExpectedUserGuid()
  const companyGuid = useExpectedCompanyGuid()

  const query = useQuery({
    query: GQL_USER_SETTINGS_QUERY,
    variables: { companyGuid, userGuid },
  })

  const [, set] = useMutation(GQL_USER_SETTINGS_SET_MAP_PREFERENCE)

  const onChange = useCallback(
    async (value: MapAppType | undefined) => {
      const mapAppType = getMapAppType(value)
      if (mapAppType) {
        await set({
          obj: {
            userGuid,
            companyGuid,
            updatedAt: new Date().toISOString(),
            preferredMapApp: mapAppType,
          },
        })
        message.success('Successfully updated Map App preference')
      }
    },
    [set, companyGuid, userGuid],
  )

  const options: BzSelectOption[] = useMemo(
    () => [
      {
        label: 'Google Maps',
        value: 'google',
        customNode: <MapAppOption label="Google Maps" icon={faGoogle} />,
      },
      {
        label: 'Apple Maps',
        value: 'apple',
        customNode: <MapAppOption label="Apple Maps" icon={faApple} />,
      },
      {
        label: 'Waze',
        value: 'waze',
        customNode: <MapAppOption label="Waze" icon={faWaze} />,
      },
    ],
    [],
  )

  return (
    <GqlQueryLoader
      query={query}
      render={data => {
        const preferredMapApp =
          data.userSettings.length > 0
            ? getMapAppType(data.userSettings[0].preferredMapApp)
            : undefined
        return (
          <div className="flex flex-col gap-2">
            <div className="text-sm font-semibold leading-[22px]">
              Preferred Map App
            </div>
            <BzSelect
              title="Preferred Map App"
              options={options}
              allowClear={false}
              value={preferredMapApp}
              onChange={(value: MapAppType) => onChange(value)}
              className="grey10 w-full min-w-[200px] font-semibold"
            />
          </div>
        )
      }}
    />
  )
})

const MapAppOption = ({
  label,
  icon,
}: {
  label: string
  icon: IconDefinition
}) => {
  return (
    <div className="flex w-full flex-row items-center py-4 text-base text-base font-semibold">
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {label}
    </div>
  )
}
