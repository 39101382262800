import { CompanyGuid } from '@breezy/shared'
import React, { useMemo } from 'react'
import { useQuery } from 'urql'
import { FETCH_PRICEBOOK_SETTINGS_CONFIG_QUERY } from '../gql/CompanyConfig.gql'
import { useExpectedPrincipal } from '../providers/PrincipalUser'
import { useStrictContext } from '../utils/react-utils'

type PricebookPhotosEnabledContextType = {
  pricebookPhotosEnabled: boolean
  fetching: boolean
  refetch: () => void
}

const PricebookPhotosEnabledContext = React.createContext<
  PricebookPhotosEnabledContextType | undefined
>(undefined)

export const PricebookPhotosEnabledProvider =
  React.memo<React.PropsWithChildren>(({ children }) => {
    const principal = useExpectedPrincipal()
    if (principal.company) {
      return (
        <UnauthPricebookPhotosEnabledProvider
          companyGuid={principal.company.companyGuid}
        >
          {children}
        </UnauthPricebookPhotosEnabledProvider>
      )
    }

    return <>{children}</>
  })

type UnauthPricebookPhotosEnabledProviderProps = React.PropsWithChildren<{
  companyGuid: CompanyGuid
}>

export const UnauthPricebookPhotosEnabledProvider =
  React.memo<UnauthPricebookPhotosEnabledProviderProps>(
    ({ companyGuid, children }) => {
      const [{ data, fetching }, refetch] = useQuery({
        query: FETCH_PRICEBOOK_SETTINGS_CONFIG_QUERY,
        variables: { companyGuid },
        requestPolicy: 'network-only',
      })

      const pricebookPhotosCompanyConfigEnabled = useMemo(() => {
        return data?.companyConfigByPk?.pricebookPhotosEnabled ?? false
      }, [data])

      return (
        <PricebookPhotosEnabledContext.Provider
          value={{
            pricebookPhotosEnabled: pricebookPhotosCompanyConfigEnabled,
            fetching,
            refetch,
          }}
        >
          {children}
        </PricebookPhotosEnabledContext.Provider>
      )
    },
  )

export const useIsPricebookPhotosEnabled = () => {
  return useStrictContext(PricebookPhotosEnabledContext)
}
