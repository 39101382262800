import { faEnvelope, faMobileScreen } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import cn from 'classnames'
import React from 'react'
import useIsMobile from '../../../hooks/useIsMobile'

const MESSAGE_OPTIONS = ['email', 'sms'] as const
export type MessageOption = (typeof MESSAGE_OPTIONS)[number]
export type PreviewMessageOptionSelectionProps = {
  messageOption: MessageOption
  setMessageOption: (messageOption: MessageOption) => void
  className?: string
  smsFirst?: boolean // New prop to control order
}

export const PreviewMessageOptionSelection =
  React.memo<PreviewMessageOptionSelectionProps>(
    ({ messageOption, setMessageOption, className, smsFirst = true }) => {
      const isMobile = useIsMobile()

      const options = React.useMemo(() => {
        const smsOption = [
          faMobileScreen,
          isMobile ? 'SMS' : 'Send as an SMS',
          'sms',
        ] as const
        const emailOption = [
          faEnvelope,
          isMobile ? 'Email' : 'Send as an email',
          'email',
        ] as const
        return smsFirst ? [smsOption, emailOption] : [emailOption, smsOption]
      }, [isMobile, smsFirst])

      return (
        <div className={cn('mb-4 flex flex-row space-x-3', className)}>
          {options.map(([icon, label, newMessageOptionValue]) => (
            <Button
              key={label}
              size="large"
              className={cn(
                'flex h-[44px] flex-1 flex-row items-center rounded-lg text-sm font-semibold',
                {
                  'border-bz-primary bg-daybreak-blue-100 text-bz-primary':
                    messageOption === newMessageOptionValue,
                },
              )}
              icon={<FontAwesomeIcon icon={icon} className="text-2xl" />}
              onClick={() => setMessageOption(newMessageOptionValue)}
            >
              {label}
            </Button>
          ))}
        </div>
      )
    },
  )

export default PreviewMessageOptionSelection
