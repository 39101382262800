import { Log } from './logging'
import { nextGuid } from './uuid-utils'

type Options = {
  timeoutMs?: number
  retries?: number
  verbose?: boolean
  retryId?: string
}

export const withRetry = async <T>(fn: () => Promise<T>, options: Options = {}): Promise<T> => {
  const { timeoutMs = 1000, retries = 5, verbose = false, retryId = nextGuid() } = options
  let attempt = 0

  if (verbose) {
    Log.info(`Running with retries: ${retryId}`)
  }
  while (attempt < retries) {
    try {
      const res = await fn()
      if (verbose) {
        Log.info(`Completed running with retries: ${retryId}. Final attempt: ${attempt + 1}`)
      }
      return res
    } catch (e) {
      if (attempt === retries) {
        if (verbose) {
          Log.error(`Exceeded maximum retries for ${retryId}`)
        }
        throw e
      }

      const delay = timeoutMs * Math.pow(2, attempt)
      const jitter = Math.random() * delay
      const finalDelay = delay + jitter
      if (verbose) {
        Log.warn(`Attempt ${attempt + 1} for ${retryId} failed. Retrying in ${finalDelay.toFixed(0)}ms...`)
      }
      await new Promise(resolve => setTimeout(resolve, finalDelay))
      attempt++
    }
  }
  throw new Error('Exceeded maximum retries')
}
