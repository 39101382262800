import { faCircle, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Link } from '../../elements/Link/Link'
import { useIsTouchScreen } from '../../hooks/useIsMobile'

const DEFAULT_LONGER_THAN_EXPECTED_TIMEOUT = 1000 * 20

interface CommonLoadingSpinnerProps {
  simple?: boolean
  spinnerClassName?: string
  checkLongerThanExpected?: boolean
  longerThanExpectedTimeout?: number
  spinnerColor?: string
}

interface BaseLoadingSpinnerProps extends CommonLoadingSpinnerProps {
  size: number
  className?: string
}

export const BaseLoadingSpinner = React.memo<BaseLoadingSpinnerProps>(
  ({
    size,
    simple,
    spinnerClassName,
    className,
    checkLongerThanExpected,
    longerThanExpectedTimeout = DEFAULT_LONGER_THAN_EXPECTED_TIMEOUT,
    spinnerColor,
  }) => {
    const isTouchScreen = useIsTouchScreen()
    const [takingTooLong, setTakingTooLong] = useState(false)
    useEffect(() => {
      if (checkLongerThanExpected) {
        const timeout = setTimeout(
          () => setTakingTooLong(true),
          longerThanExpectedTimeout,
        )
        return () => clearTimeout(timeout)
      }
    }, [checkLongerThanExpected, longerThanExpectedTimeout])
    const height = `${size * 4}px`
    const width = `${size * 4}px`
    const colorClassName = spinnerColor
      ? `text-[${spinnerColor}]`
      : 'text-daybreak-blue-400'
    return (
      <div
        className={classNames(`relative inline-block`, className)}
        style={{
          height,
          width,
          lineHeight: `${size * 4}px`,
        }}
      >
        {!simple && (
          <FontAwesomeIcon
            spin
            icon={faCircle}
            className={classNames(
              'absolute inset-0 text-bz-gray-500',
              spinnerClassName,
            )}
            style={{
              height,
              width,
            }}
          />
        )}
        <FontAwesomeIcon
          spin
          icon={faSpinnerThird}
          className={classNames(
            simple ? 'text-bz-gray-100' : 'absolute inset-0',
            colorClassName,
            spinnerClassName,
          )}
          style={{
            height,
            width,
          }}
        />
        {takingTooLong && (
          <div
            className="absolute ml-[50%] w-[200px] translate-x-[-50%] text-base leading-5"
            style={{ top: `${size * 4 + 16}px` }}
          >
            This page is taking longer than expected.{' '}
            <Link onClick={() => window.location.reload()}>
              {isTouchScreen ? 'Tap' : 'Click'} here
            </Link>{' '}
            to refresh.
          </div>
        )}
      </div>
    )
  },
)

interface LoadingSpinnerProps extends CommonLoadingSpinnerProps {
  noMinHeight?: boolean
  className?: string
  size?: number
  spinnerColor?: string
}

export const LoadingSpinner = React.memo<LoadingSpinnerProps>(
  ({ noMinHeight, className, size = 14, spinnerColor, ...rest }) => (
    <div
      className={classNames(
        'flex h-full w-full items-center justify-center',
        {
          'min-h-14': !noMinHeight,
        },
        className,
      )}
    >
      <BaseLoadingSpinner size={size} spinnerColor={spinnerColor} {...rest} />
    </div>
  ),
)

export const FullScreenLoadingSpinner = (
  <div className="fixed inset-0 h-screen w-screen bg-white">
    <LoadingSpinner checkLongerThanExpected />
  </div>
)
