import { gql } from '../../generated'

export const TIME_CLOCK_PROVIDER_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription TimeClockProviderData(
    $companyGuid: uuid!
    $userGuid: uuid!
    $startTime: timestamptz!
  ) {
    timesheetEntries(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
        finalStartTime: { _gte: $startTime }
      }
      orderBy: { finalStartTime: ASC, finalEndTime: DESC_NULLS_LAST }
    ) {
      finalStartTime
      finalEndTime
      entryLengthInMinutes
      timesheetEntryActivity {
        activityName
      }
      timesheetEntryLinkData {
        jobGuid
        jobAppointmentGuid
        jobAppointmentAssignmentGuid
      }
    }
  }
`)

// TODO: this is probably redundant with the above query
export const TECH_CLOCKED_IN_QUERY = gql(/* GraphQL */ `
  subscription TechnicianFetchTimeClockStatus(
    $companyGuid: uuid!
    $userGuid: uuid!
  ) {
    timesheetEntries(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
        endTime: { _isNull: true }
      }
      orderBy: { startTime: DESC }
      limit: 1
    ) {
      companyGuid
      userGuid
      timesheetEntryGuid
      finalStartTime
    }
  }
`)
