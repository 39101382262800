import { Select, SelectProps } from 'antd'
import React from 'react'
import { useIsMobileOrTouchOrTechApp } from '../../hooks/useIsMobile'
import { typedMemo } from '../../utils/react-utils'
import {
  BzMobileMultiSelect,
  BzMobileSingleSelect,
} from '../BzMobileSelect/BzMobileSelect'
import {
  BzSelectMode,
  BzSelectOption,
  BzSelectSize,
  BzSheetSize,
} from './BzSelectTypes'

export type BzSelectProps<
  SelectMode extends BzSelectMode = 'default',
  T extends string = '',
> = Omit<SelectProps<T>, 'mode' | 'options' | 'onChange'> & {
  mode?: SelectMode
  title: string
  placeholder?: string
  disabled?: boolean
  options: BzSelectOption[]
  size?: BzSelectSize
  sheetSize?: BzSheetSize
  className?: string
  name?: string
  ddActionName?: string
  onOpen?: () => void
  onClose?: () => void
  hideInput?: boolean
} & (SelectMode extends 'default'
    ? {
        value: T | undefined
        onChange: (value: T) => void
      }
    : SelectMode extends 'multiple' | 'tags'
    ? {
        values: T[] | []
        onChange: (values: T[]) => void
        tagRender?: (props: {
          value: T
          label: React.ReactNode
          onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
        }) => React.ReactElement
      }
    : never)

export const BzSelect = typedMemo(
  <SelectMode extends BzSelectMode = 'default', T extends string = ''>(
    props: BzSelectProps<SelectMode, T>,
  ) => {
    // Including sheetSize here because if it's in "rest", and we pass it to "Select", we get a React error
    const { mode = 'default', onChange, sheetSize, ...rest } = props

    const shouldShowMobile = useIsMobileOrTouchOrTechApp()

    if (shouldShowMobile) {
      if (mode === 'default') {
        return (
          <BzMobileSingleSelect {...(props as BzSelectProps<'default', T>)} />
        )
      }
      return (
        <BzMobileMultiSelect {...(props as BzSelectProps<'multiple', T>)} />
      )
    }

    return (
      <Select
        mode={mode === 'default' ? undefined : mode}
        name={props.name}
        data-testid={props.name}
        data-dd-action-name={props.ddActionName}
        // This is cast to any because the antd types are less strict
        // than the BzSelect types.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(value, option) => onChange(value as any)}
        onBlur={rest.onBlur ?? rest.onClose}
        onFocus={rest.onFocus ?? rest.onOpen}
        {...rest}
      />
    )
  },
)

export default BzSelect
