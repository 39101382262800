import { AppointmentType, JobClass } from '@breezy/shared'
import {
  faCheck,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import { JobAppointmentAssignmentStatusTypesEnum } from '../../generated/user/graphql'
import { JobClassAccentBadge } from '../JobClassAccentBadge'

export type AppointmentStatusHeaderProps = {
  assignmentStatus?: JobAppointmentAssignmentStatusTypesEnum
  jobClass: JobClass
  jobTypeName: string
  appointmentType: AppointmentType
  jobDisplayId: number
  hasMaintenancePlan?: boolean
  maintenancePlanPrimaryColorHex?: string
}

export const AppointmentStatusHeader = React.memo<AppointmentStatusHeaderProps>(
  ({
    assignmentStatus,
    jobClass,
    jobTypeName,
    appointmentType,
    jobDisplayId,
    hasMaintenancePlan,
    maintenancePlanPrimaryColorHex,
  }) => {
    const completed = assignmentStatus === 'COMPLETED'
    return (
      <div
        className={classNames('text-base', {
          'text-bz-text-tertiary line-through': completed,
        })}
      >
        <div className="flex min-h-8 flex-row items-center">
          {completed && (
            <div className="mr-1.5 flex h-7 w-7 items-center justify-center rounded-full bg-bz-green-700 text-white">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          )}
          <JobClassAccentBadge jobClass={jobClass} />
          <div className="ml-1.5 truncate whitespace-nowrap text-xl font-semibold">
            {appointmentType}
          </div>
          <div
            className={classNames(
              'mx-2 flex-1 truncate whitespace-nowrap',
              completed ? 'text-bz-text-tertiary' : 'text-bz-text-secondary',
            )}
          >
            ({jobTypeName} #{jobDisplayId})
          </div>
          {hasMaintenancePlan && (
            <div
              className="flex h-8 w-8 items-center justify-center rounded-full bg-bz-fill-tertiary"
              style={{
                color: maintenancePlanPrimaryColorHex,
              }}
            >
              <FontAwesomeIcon icon={faScrewdriverWrench} />
            </div>
          )}
        </div>
      </div>
    )
  },
)
