import { times } from '../lodash-helpers'
import { stringToCharArray } from '../string-utils'
import { RandomNumberGenerator } from './RandomNumberGenerator'

export class ReferenceNumberGenerator {
  private static readonly REFERENCE_NUMBER_ALPHABET = 'ACDEFGHJKLMNPQRTUVWXY34679'
  private static readonly REFERENCE_NUMBER_FORMAT = 'XXX-XXXX'

  private static readonly REFERENCE_NUMBER_FORMAT_ARRAY = stringToCharArray(
    ReferenceNumberGenerator.REFERENCE_NUMBER_FORMAT,
  )

  private static readonly REFERENCE_NUMBER_ALPHABET_ARRAY = stringToCharArray(
    ReferenceNumberGenerator.REFERENCE_NUMBER_ALPHABET,
  )

  private readonly rng: RandomNumberGenerator

  constructor(private readonly rngOverride?: RandomNumberGenerator) {
    this.rng = rngOverride || new RandomNumberGenerator()
  }

  generate(n = 10): string[] {
    ///
    /// Generate n candidate reference numbers
    ///
    return times(n, () => {
      return ReferenceNumberGenerator.REFERENCE_NUMBER_FORMAT_ARRAY.reduce((acc, c) => {
        if (c !== 'X') return acc + c

        const nextIndex = this.rng.nextInt({
          minInclusive: 0,
          maxExclusive: ReferenceNumberGenerator.REFERENCE_NUMBER_ALPHABET.length,
        })

        const nextChar = ReferenceNumberGenerator.REFERENCE_NUMBER_ALPHABET.charAt(nextIndex)
        return acc + nextChar
      }, '')
    })
  }
}
