import { phoneUtils, PROSPECT_NAME_PLACEHOLDER } from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  OnsiteModal,
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { PhoneNumberSchema } from '../PreviewMessage/PhoneNumberField/PhoneNumberField.schema'
import PreviewMessageOptionSelection, {
  MessageOption,
  PreviewMessageOptionSelectionProps,
} from '../PreviewMessage/PreviewMessageOptionSelection/PreviewMessageOptionSelection'
import { RichTextAreaSchema } from '../PreviewMessage/RichTextAreaField/RichTextAreaField.schema'
import { createSendEmailForm } from '../PreviewMessage/SendEmailForm/SendEmailForm'
import { createSendSmsForm } from '../PreviewMessage/SendSmsForm/SendSmsForm'
import { SmsMessageTextAreaSchema } from '../PreviewMessage/SmsMessageTextArea/SmsMessageTextArea.schema'
import amazonGiftCardLogo from './amazon_logo.svg'
type BreezyReferralModalProps = {
  onClose: () => void
}

const REFERRAL_SCHEDULE_LINK = 'https://getbreezyapp.com/referral'

const createDefaultEmail = (referrerName: string, scheduleLink: string) => `
<strong>Hello ${PROSPECT_NAME_PLACEHOLDER},</strong>

${referrerName} thinks Breezy could be a game-changer for your HVAC shop! With Breezy, you can streamline operations, keep the phone ringing, and gain deeper insights into your customer base—all in one powerful platform.

To sweeten the deal, we're offering a 🎁 $100 Amazon gift card to both you and ${referrerName} when you join us.

Want to see how Breezy can make a difference? Schedule a quick demo here:
👉 <a href="${scheduleLink}" rel="noopener noreferrer">${scheduleLink}</a>

We'd love to show you around and help take your business to the next level.

Shaun Ritchey
CEO & Co-founder, Breezy
`

const createDefaultSms = (referrerName: string, scheduleLink: string) =>
  `Hey ${PROSPECT_NAME_PLACEHOLDER}, ${referrerName} recommends checking out Breezy! 🔥 You both get a $100 Amazon gift card if you sign up. ${scheduleLink}`

const SendReferralAsEmailFormSchema = z.object({
  recipientName: z.string().nonempty().describe('Recipient First Name'),
  emailAddress: z
    .string()
    .nonempty('You must include an email')
    .email()
    .describe('Email Address'),
  subject: z
    .string()
    .nonempty('You must include a subject')
    .describe('Subject Header'),
  body: RichTextAreaSchema.describe('Body').refine(
    body => body.includes(PROSPECT_NAME_PLACEHOLDER),
    {
      message: `Body must include the text: ${PROSPECT_NAME_PLACEHOLDER}`,
    },
  ),
})

type SendReferralAsEmailFormData = z.infer<typeof SendReferralAsEmailFormSchema>

const SendReferralAsSmsFormSchema = z.object({
  recipientName: z.string().nonempty().describe('Recipient First Name'),
  phoneNumber: PhoneNumberSchema.describe('Phone Number').refine(
    val => phoneUtils.isPossibleNumber(val, 'US'),
    val => ({ message: 'Invalid phone number', params: { phoneNumber: val } }),
  ),
  body: SmsMessageTextAreaSchema.describe('Body').refine(
    body => body.includes(PROSPECT_NAME_PLACEHOLDER),
    {
      message: `Body must include the text: ${PROSPECT_NAME_PLACEHOLDER}`,
    },
  ),
})

type SendReferralAsSmsFormData = z.infer<typeof SendReferralAsSmsFormSchema>

type CustomFormProps = React.PropsWithChildren<
  PreviewMessageOptionSelectionProps & {
    onSubmit: () => void
    onCancel: () => void
    onBack?: () => void
    isLoading: boolean
  }
>

// Note: `createTsForm` won't accept something wrapped in `React.memo`
const CustomForm = ({
  children,
  onSubmit,
  onCancel,
  onBack,
  isLoading,
  messageOption,
  setMessageOption,
}: CustomFormProps) => {
  return (
    <OnsiteModalContent
      unpadBottom
      header="Refer Breezy"
      onClose={onCancel}
      onBack={onBack}
      footer={
        <OnsiteModalFooter
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitText="Send"
          loading={isLoading}
        />
      }
    >
      <div>
        <GiftcardCallout />
        <ThinDivider
          dividerStyle="dashed"
          widthPx={1}
          styleOverrides={{
            marginTop: 16,
            marginBottom: 16,
          }}
        />
        <PreviewMessageOptionSelection
          messageOption={messageOption}
          setMessageOption={setMessageOption}
          smsFirst={false}
        />
        <Form
          disabled={isLoading}
          onSubmitCapture={onSubmit}
          layout="vertical"
          className="flex min-h-0 flex-1 flex-col"
        >
          {children}
        </Form>
      </div>
    </OnsiteModalContent>
  )
}

const SendEmailForm = createSendEmailForm(CustomForm)
const SendSmsForm = createSendSmsForm(CustomForm)

export const BreezyReferralModal = React.memo<BreezyReferralModalProps>(
  ({ onClose }) => {
    const user = useExpectedPrincipal()
    const message = useMessage()

    const [messageOption, setMessageOption] = useState<MessageOption>('email')

    const defaultEmailValues = useMemo(() => {
      return {
        emailAddress: '',
        subject: `${user.firstName} Wants You to Check Out Breezy`,
        body: createDefaultEmail(user.firstName, REFERRAL_SCHEDULE_LINK),
      }
    }, [user.firstName])

    const defaultPhoneValues = useMemo(() => {
      return {
        phoneNumber: '',
        body: createDefaultSms(user.firstName, REFERRAL_SCHEDULE_LINK),
      }
    }, [user.firstName])

    const sendReferralMutation =
      trpc.breezyReferral['breezy-referral:send-referral'].useMutation()

    const onEmailSubmit = useCallback(
      async (data: SendReferralAsEmailFormData) => {
        await sendReferralMutation.mutateAsync({
          deliveryMethod: 'EMAIL',
          to: data.emailAddress,
          ...data,
        })
        message.success('Breezy referral link sent!')
        onClose()
      },
      [message, onClose, sendReferralMutation],
    )
    const onSmsSubmit = useCallback(
      async (data: SendReferralAsSmsFormData) => {
        await sendReferralMutation.mutateAsync({
          deliveryMethod: 'SMS',
          to: data.phoneNumber,
          ...data,
        })
        message.success('Breezy referral link sent!')
        onClose()
      },
      [message, onClose, sendReferralMutation],
    )

    const emailForm = useForm<SendReferralAsEmailFormData>({
      defaultValues: defaultEmailValues,
      resolver: zodResolver(SendReferralAsEmailFormSchema),
      reValidateMode: 'onChange',
    })
    const smsForm = useForm<SendReferralAsSmsFormData>({
      defaultValues: defaultPhoneValues,
      resolver: zodResolver(SendReferralAsSmsFormSchema),
      reValidateMode: 'onChange',
    })

    const isDirty = emailForm.formState.isDirty || smsForm.formState.isDirty

    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
      isDirty,
    })

    const onCancelWithConfirm = useCallback(
      () =>
        withConfirmation(() => {
          emailForm.reset()
          smsForm.reset()
          onClose()
        }),
      [emailForm, onClose, smsForm, withConfirmation],
    )

    const onBackWithConfirm = useMemo(() => {
      return () =>
        withConfirmation(() => {
          emailForm.reset()
          smsForm.reset()
        })
    }, [emailForm, smsForm, withConfirmation])

    const content = useMemo(() => {
      return (
        <>
          <div
            className={classNames('flex min-h-0 flex-1 flex-col', {
              hidden: messageOption !== 'email',
            })}
          >
            <SendEmailForm
              form={emailForm}
              schema={SendReferralAsEmailFormSchema}
              onSubmit={onEmailSubmit}
              formProps={{
                isLoading: sendReferralMutation.isLoading,
                onCancel: onCancelWithConfirm,
                onClose: onBackWithConfirm,
                messageOption,
                setMessageOption,
              }}
              defaultValues={defaultEmailValues}
            />
          </div>
          <div
            className={classNames('flex min-h-0 flex-1 flex-col', {
              hidden: messageOption !== 'sms',
            })}
          >
            <SendSmsForm
              form={smsForm}
              schema={SendReferralAsSmsFormSchema}
              onSubmit={onSmsSubmit}
              formProps={{
                isLoading: sendReferralMutation.isLoading,
                onCancel: onCancelWithConfirm,
                onClose: onBackWithConfirm,
                messageOption,
                setMessageOption,
              }}
              defaultValues={defaultPhoneValues}
            />
          </div>
        </>
      )
    }, [
      defaultEmailValues,
      defaultPhoneValues,
      emailForm,
      messageOption,
      onBackWithConfirm,
      onCancelWithConfirm,
      onEmailSubmit,
      onSmsSubmit,
      sendReferralMutation.isLoading,
      smsForm,
    ])

    return (
      <OnsiteModal open onClose={onCancelWithConfirm} size="large">
        {content}
        <CloseConfirmModal {...closeConfirmProps} />
      </OnsiteModal>
    )
  },
)

type GiftcardCalloutProps = {
  className?: string
}
const GiftcardCallout = React.memo<GiftcardCalloutProps>(({ className }) => {
  return (
    <div
      className={classNames(
        'flex flex-col gap-3 rounded-xl border border-solid border-bz-border bg-bz-fill-quaternary p-4',
        className,
      )}
    >
      <div className="flex gap-3">
        <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-bz-fill-secondary">
          <img
            src={amazonGiftCardLogo}
            alt="Amazon Gift Card"
            className="h-5 w-5"
          />
        </div>
        <div className="flex flex-col gap-[2px] text-base text-bz-text">
          <div className="font-semibold ">Earn $100 Amazon Gift Cards</div>
          <div>
            Send Breezy to a friend. If they sign up, you both get a $100 Amazon
            gift card!
          </div>
        </div>
      </div>
    </div>
  )
})
