import { TechnicianRoutes } from '@breezy/shared'
import React, { useContext, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useSubscription } from 'urql'
import { CurrentVisitDataSubscription } from '../../../../generated/user/graphql'
import { useFeatureFlag } from '../../../../hooks/useFeatureFlags'
import { useExpectedPrincipal } from '../../../../providers/PrincipalUser'
import { TECH_APP_NAV_BAR_HEIGHT } from '../TechnicianAppBottomNavBar'
import { CURRENT_VISIT_CARD_DATA_SUBSCRIPTION } from './CurrentVisitCard.gql'
import { CURRENT_VISIT_CARD_HEIGHT } from './CurrentVisitCardContainer'

const PATHS_TO_HIDE_ON = [
  TechnicianRoutes.APPOINTMENT_DETAILS.path,
  TechnicianRoutes.HOME.path,
] as const

type CurrentVisitContextData = {
  isOpen: boolean
  currentAssignmentData?: CurrentVisitDataSubscription['assignments'][0]
}

export const CurrentVisitContext = React.createContext<
  CurrentVisitContextData | undefined
>(undefined)

export const CurrentVisitProvider = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const currentVisitCardEnabled = useFeatureFlag('current-visit-card')

    const principal = useExpectedPrincipal()
    const location = useLocation()

    const [{ data }] = useSubscription({
      query: CURRENT_VISIT_CARD_DATA_SUBSCRIPTION,
      pause: !principal.company || !principal.userGuid,
      variables: {
        userGuid: principal.userGuid,
      },
    })

    const isOpen = useMemo(() => {
      if (!currentVisitCardEnabled || !data?.assignments?.length) {
        return false
      }
      for (const path of PATHS_TO_HIDE_ON) {
        const match = matchPath(path, location.pathname)
        if (match) {
          if (path === TechnicianRoutes.APPOINTMENT_DETAILS.path) {
            return (
              match.params.appointmentGuidOrAppointmentReferenceNumber !==
              data?.assignments?.[0]?.appointmentGuid
            )
          }
          return false
        }
      }
      return true
    }, [currentVisitCardEnabled, data?.assignments, location.pathname])

    return (
      <CurrentVisitContext.Provider
        value={{
          isOpen,
          currentAssignmentData: data?.assignments?.[0],
        }}
      >
        {children}
      </CurrentVisitContext.Provider>
    )
  },
)

export const useTechAppBottomOffset = () => {
  const context = useContext(CurrentVisitContext)

  return useMemo(() => {
    if (!context) {
      return {
        navBarHeight: 0,
        currentVisitCardHeight: 0,
        bottomOffset: 0,
      }
    }
    const { isOpen } = context
    const navBarHeight = TECH_APP_NAV_BAR_HEIGHT
    const currentVisitCardHeight = isOpen ? CURRENT_VISIT_CARD_HEIGHT : 0
    const bottomOffset = navBarHeight + currentVisitCardHeight
    return {
      navBarHeight,
      currentVisitCardHeight,
      bottomOffset,
    }
  }, [context])
}
