import { R, WithRequiredKeys, isNullish, nextGuid } from '@breezy/shared'
import React, {
  Dispatch,
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  PropsWithoutRef,
  RefAttributes,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { ZodTypeAny, z } from 'zod'

export const m = React.memo

export const typedMemo: <T>(
  c: T,
  propsAreEqual?: (prevProps: Readonly<T>, nextProps: Readonly<T>) => boolean,
) => T = React.memo
// This comes straight from React
// eslint-disable-next-line @typescript-eslint/ban-types
export const typedForwardRef: <T, P = {}>(
  c: ForwardRefRenderFunction<T, P>,
) => ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>> =
  React.forwardRef

export type StateSetter<T> = Dispatch<SetStateAction<T>>

export const stopPropagation: React.MouseEventHandler = e => e.stopPropagation()

export const useEffectDebug = (
  label: string,
  func: Parameters<typeof useEffect>[0],
  deps: Parameters<typeof useEffect>[1],
) => {
  const prevRef = useRef<typeof deps>()
  useEffect(() => {
    if (deps && prevRef.current) {
      for (let i = 0; i < deps?.length; ++i) {
        const newDep = deps[i]
        const oldDep = prevRef.current[i]
        if (newDep !== oldDep) {
          console.info(`useEffectDebug - ${label} - dep i: ${i}`)
          console.info(`\tbefore: ${JSON.stringify(oldDep, null, 2)}`)
          console.info(`\tafter: ${JSON.stringify(newDep, null, 2)}`)
        }
      }
    }
    prevRef.current = deps

    return func()
    // Needs to be exactly the same as the regular `useEffect` to get a really accurate
    // wrapper.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const useLayoutEffectDebug = (
  label: string,
  func: Parameters<typeof useLayoutEffect>[0],
  deps: Parameters<typeof useLayoutEffect>[1],
) => {
  const prevRef = useRef<typeof deps>()
  useLayoutEffect(() => {
    if (deps && prevRef.current) {
      for (let i = 0; i < deps?.length; ++i) {
        const newDep = deps[i]
        const oldDep = prevRef.current[i]
        if (newDep !== oldDep) {
          console.info(`useLayoutEffectDebug - ${label} - dep i: ${i}`)
          console.info(`\tbefore: ${JSON.stringify(oldDep, null, 2)}`)
          console.info(`\tafter: ${JSON.stringify(newDep, null, 2)}`)
        }
      }
    }
    prevRef.current = deps

    return func()
    // Needs to be exactly the same as the regular `useLayoutEffect` to get a really accurate
    // wrapper.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const useMemoDebug = <T>(
  label: string,
  func: () => T,
  deps: Parameters<typeof useMemo>[1],
): T => {
  const prevRef = useRef<typeof deps>()
  return useMemo(() => {
    if (deps && prevRef.current) {
      for (let i = 0; i < deps?.length; ++i) {
        const newDep = deps[i]
        const oldDep = prevRef.current[i]
        if (newDep !== oldDep) {
          console.info(`useMemoDebug - ${label} - dep i: ${i}`)
          console.info(`\tbefore: ${JSON.stringify(oldDep, null, 2)}`)
          console.info(`\tafter: ${JSON.stringify(newDep, null, 2)}`)
        }
      }
    }
    prevRef.current = deps

    return func()
    // Needs to be exactly the same as the regular `useEffect` to get a really accurate
    // wrapper.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

// Need this to match the React type
// eslint-disable-next-line @typescript-eslint/ban-types
export const useCallbackDebug = <T extends Function>(
  label: string,
  callback: T,
  deps: Parameters<typeof useCallback>[1],
): T => {
  return useMemoDebug(`useCallbackDebug - ${label}`, () => callback, deps)
}

// Need this to match the React type
// eslint-disable-next-line @typescript-eslint/ban-types
export const createPropsAreEqualDebug =
  <P extends object>(label: string) =>
  (prevProps: Readonly<P>, nextProps: Readonly<P>): boolean => {
    const objA = prevProps
    const objB = nextProps
    // A lot of this is adapted/copied from facebook/react/packages/shared/shallowEqual.js, which is what it literally
    // uses by default. Note I replaced Object.keys with R.keys here. They do the same thing, but R.keys has better
    // types (using Object.keys made a lower part fail because it didn't think strings could key into P).
    const keysA = R.keys(objA)
    const keysB = R.keys(objB)

    if (keysA.length !== keysB.length) {
      return false
    }

    // Test for A's keys different from B.
    for (let i = 0; i < keysA.length; i++) {
      const currentKey = keysA[i]
      if (
        !Object.prototype.hasOwnProperty.call(objB, currentKey) ||
        objA[currentKey] !== objB[currentKey]
      ) {
        // ---- not copy/pasted from facebook/react/packages/shared/shallowEqual.js ---- //
        console.info(`createPropsAreEqualDebug - ${label} - dep i: ${i}`)
        console.info(`\tbefore: ${JSON.stringify(objA[currentKey], null, 2)}`)
        console.info(`\tafter: ${JSON.stringify(objB[currentKey], null, 2)}`)
        // ---- end not copy/pasted from facebook/react/packages/shared/shallowEqual.js ---- //
        return false
      }
    }

    return true
  }

// replace is passed to navigate. Allows you to control if this change replaces or pushes the history
export const useSetQueryParams = (replace?: boolean) => {
  const navigate = useNavigate()

  return useCallback(
    (values: Record<string, string | undefined>) => {
      const myUrl = new URL(window.location.href)
      for (const key of R.keys(values)) {
        const value = values[key]
        if (isNullish(value)) {
          myUrl.searchParams.delete(key)
        } else {
          myUrl.searchParams.set(key, value)
        }
      }

      navigate(myUrl.search, { replace })
    },
    [navigate, replace],
  )
}

export const useSetQueryParam = (key: string, replace?: boolean) => {
  const setQueryParams = useSetQueryParams(replace)

  return useCallback(
    (value?: string) => setQueryParams({ [key]: value }),
    [key, setQueryParams],
  )
}

// Convenience function for situations where you just want to toggle a boolean. This does `useCallback` so instead of
// doing things like `() => setFlag(true)`, which could cause unnecessary rerenders, or wrapping it in your own
// useCallback, you just get it easily.
export const useBooleanState = (defaultValue = false) => {
  const [open, setOpen] = useState(defaultValue)

  const openModal = useCallback(() => setOpen(true), [])
  const closeModal = useCallback(() => setOpen(false), [])
  return [open, openModal, closeModal, setOpen] as const
}

// Modal state is a common use case for booleans. The function above was originally called this. I ran into places where
// I wanted a boolean flag and it wasn't a modal, and using one called "modal" for that was lame. Instead of refactoring
// all the "useModalState"s to "useBooleanState"s, I decided to alias it because it requires less mental effort to do a
// "useModalState" for a modal instead of a "useBooleanState" and kind of mentally map the boolean to the modal state.
// Slight thing but the alias is basically free so why not?
export const useModalState = useBooleanState

const getUrlVal = <T>(
  key: string,
  locationSearch: string,
  decode: Decoder<T>,
): T | undefined => {
  if (locationSearch) {
    const myUrl = new URL(window.location.href)
    if (myUrl.searchParams.has(key)) {
      return decode(myUrl.searchParams.get(key) ?? '')
    }
  }
}

export const defaultEncoder = R.identity
export const defaultDecoder = R.identity

export const numberEncoder = (num: number) => `${num}`
export const numberDecoder = (param: string, defaultValue = 0) => {
  try {
    const num = parseFloat(param)
    if (isNaN(num)) {
      return defaultValue
    }
    return num
  } catch (e) {
    return defaultValue
  }
}

type Encoder<T> = (value: T) => string
type Decoder<T> = (value: string) => T

// NOTE: we're going to do the `encodeURIComponent` and `decodeURIComponent` for you!
interface UseQueryParamsStateOptions<T> {
  base64Encode?: boolean
  encode?: Encoder<T>
  decode?: Decoder<T>
  // If, when the default value changes and it then matches the query param, you want it to clear, set this to true
  clearOnDefaultChangeAndMatch?: boolean
  // If there is not query param and this is true, return undefined. `defaultValue` will only be used to determine
  // whether or not to delete the query param if the state is set to it.
  returnUndefinedAsDefault?: boolean
  // When this is true, when the value changes it will do a replace instead of a push on the history.
  replace?: boolean
}

type UseQueryParamsStateOptionsWithoutEncodeDecode = Omit<
  UseQueryParamsStateOptions<number>,
  'encode' | 'decode'
>

type ReturnUndefinedAsDefaultOptionsState<
  T,
  Options extends UseQueryParamsStateOptions<T> = UseQueryParamsStateOptions<T>,
> = Options['returnUndefinedAsDefault'] extends true ? T | undefined : T

type OptionalStateSetter<T> = (newState?: T | ((oldVal: T) => T)) => void

// If it's a string then encode and decode (and options) are optional
export function useQueryParamState<
  Options extends UseQueryParamsStateOptions<string> = UseQueryParamsStateOptions<string>,
>(
  key: string,
  defaultValue: string,
  options?: Options,
): readonly [
  state: ReturnUndefinedAsDefaultOptionsState<string, Options>,
  setState: OptionalStateSetter<string>,
]

// If it's not a string, encoded and decode (and thus, options) are required
export function useQueryParamState<
  T,
  Options extends WithRequiredKeys<
    UseQueryParamsStateOptions<T>,
    'encode' | 'decode'
  > = WithRequiredKeys<UseQueryParamsStateOptions<T>, 'encode' | 'decode'>,
>(
  key: string,
  defaultValue: T,
  options: Options,
): readonly [
  state: ReturnUndefinedAsDefaultOptionsState<T, Options>,
  setState: OptionalStateSetter<T>,
]

export function useQueryParamState<
  T = string,
  Options extends UseQueryParamsStateOptions<T> = UseQueryParamsStateOptions<T>,
>(
  key: string,
  defaultValue: T,
  options?: Options,
): readonly [
  state: ReturnUndefinedAsDefaultOptionsState<T, Options>,
  setState: OptionalStateSetter<T>,
] {
  const location = useLocation()

  const encode = useCallback(
    (value: T): string => {
      let encoded = (options?.encode ?? (defaultEncoder as Encoder<T>))(value)
      if (options?.base64Encode) {
        encoded = btoa(encoded)
      }
      // NOTE: our URL setter automatically does `encodeURIComponent`!!!
      return encoded
    },
    // The only dependencies are the options. Those are most likely to be written inline which will be shallow unequal,
    // meaning this function constantly changes triggering other stuff. So basically just only consider the on-mount
    // version of `options`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const decode = useCallback((value: string): T => {
    // NOTE: though our URL SETTER automatically does `encodeURIComponent`, our READER does not automatically do
    // `decodeURIComponent`
    let stringDecoded = decodeURIComponent(value)
    if (options?.base64Encode) {
      stringDecoded = atob(stringDecoded)
    }
    const objDecoded = (options?.decode ?? (defaultDecoder as Decoder<T>))(
      stringDecoded,
    )
    return objDecoded
    // Disabled for the same reasons as above
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const encodedDefault = useMemo(
    () => encode(defaultValue),
    [defaultValue, encode],
  )

  const urlVal = useMemo(() => {
    const qsVal = getUrlVal(key, location.search, decode)
    if (!qsVal && options?.returnUndefinedAsDefault) {
      return undefined
    }
    return qsVal ?? defaultValue
  }, [
    decode,
    defaultValue,
    key,
    location.search,
    options?.returnUndefinedAsDefault,
  ])

  const setQueryParam = useSetQueryParam(key, options?.replace)

  const setLocalState = useCallback<OptionalStateSetter<T>>(
    newStateOrFunc => {
      let newState: T | undefined = undefined

      if (newStateOrFunc instanceof Function) {
        const currentVal = getUrlVal(
          key,
          // Important we do it this way as opposed to `useLocation`. That's designed to cause react to react which we
          // don't want here because we don't ever want this instance of this function to change.
          window.location.search,
          decode,
        )
        newState = newStateOrFunc(currentVal ? currentVal : defaultValue)
      } else {
        newState = newStateOrFunc
      }

      if (isNullish(newState)) {
        setQueryParam(undefined)
        return
      }
      const encodedNewState = encode(newState)

      setQueryParam(
        encodedNewState === encodedDefault ? undefined : encodedNewState,
      )
    },
    [decode, defaultValue, encode, encodedDefault, key, setQueryParam],
  )

  // If either the url or the default change such that they match, clear the query string param
  useEffect(() => {
    if (
      options?.clearOnDefaultChangeAndMatch &&
      R.equals(defaultValue, urlVal)
    ) {
      setQueryParam(undefined)
    }
  }, [
    defaultValue,
    options?.clearOnDefaultChangeAndMatch,
    setQueryParam,
    urlVal,
  ])

  return [
    urlVal as ReturnUndefinedAsDefaultOptionsState<T, Options>,
    setLocalState,
  ] as const
}

const QUERY_PARAM_FLAG_QUERY_STATE_OPTIONS = {
  encode: R.identity,
  decode: (str: string) => (str ? '1' : ''),
}

export const useQueryParamFlag = (
  key: string,
  options?: {
    defaultOn?: boolean
    replace?: boolean
  },
): ReturnType<typeof useBooleanState> => {
  const queryParamStateOptions = useMemo(
    () => ({
      ...QUERY_PARAM_FLAG_QUERY_STATE_OPTIONS,
      replace: options?.replace,
    }),
    [options?.replace],
  )
  const [flag, setFlag] = useQueryParamState<'' | '1'>(
    key,
    options?.defaultOn ? '1' : '',
    queryParamStateOptions,
  )
  const setFlagTrue = useCallback(() => setFlag('1'), [setFlag])
  const setFlagFalse = useCallback(() => setFlag(''), [setFlag])
  const ourSetFlag = useCallback(
    (on: boolean | ((prev: boolean) => boolean)) => {
      setFlag(prev => {
        const ourOn = typeof on === 'function' ? on(prev === '1') : on
        return ourOn ? '1' : ''
      })
    },
    [setFlag],
  )
  return [!!flag, setFlagTrue, setFlagFalse, ourSetFlag]
}

export const useQueryParamStateWithOptions = <
  ValidOptions extends readonly [string, ...string[]],
  Options extends UseQueryParamsStateOptionsWithoutEncodeDecode = UseQueryParamsStateOptionsWithoutEncodeDecode,
>(
  key: string,
  defaultValue: NoInfer<ValidOptions[number]>,
  validOptions: ValidOptions,
  options?: Options,
): readonly [
  state: ValidOptions[number],
  setState: OptionalStateSetter<ValidOptions[number]>,
] => {
  const decode = useCallback(
    (param: string) =>
      validOptions.includes(param as ValidOptions[number])
        ? (param as ValidOptions[number])
        : defaultValue,
    [defaultValue, validOptions],
  )
  const ourOptions = useMemo(
    () => ({
      ...(options ?? {}),
      encode: defaultEncoder as (val: ValidOptions[number]) => string,
      decode,
    }),
    [decode, options],
  )
  return useQueryParamState<ValidOptions[number]>(key, defaultValue, ourOptions)
}

const useQueryParamStateArrayWithValidator = <
  T extends string,
  Validator extends ZodTypeAny,
  Options extends UseQueryParamsStateOptionsWithoutEncodeDecode = UseQueryParamsStateOptionsWithoutEncodeDecode,
>(
  key: string,
  defaultValue: NoInfer<T[]>,
  validator: Validator,
  options?: Options,
): readonly [state: T[], setState: OptionalStateSetter<T[]>] => {
  const decode = useCallback(
    (param: string): T[] => {
      try {
        return z.array(validator).parse(JSON.parse(param)) as T[]
      } catch (e) {
        return defaultValue
      }
    },
    [defaultValue, validator],
  )
  const ourOptions = useMemo(
    () => ({
      ...(options ?? {}),
      encode: JSON.stringify,
      decode,
    }),
    [decode, options],
  )
  return useQueryParamState(key, defaultValue, ourOptions)
}

export const useQueryParamStateArray = <T extends string>(
  key: string,
  defaultValue: NoInfer<T[]>,
) => {
  return useQueryParamStateArrayWithValidator(key, defaultValue, z.string())
}

export const useQueryParamStateEnumArray = <
  ValidOptions extends readonly [string, ...string[]],
  Options extends UseQueryParamsStateOptionsWithoutEncodeDecode = UseQueryParamsStateOptionsWithoutEncodeDecode,
>(
  key: string,
  defaultValue: NoInfer<ValidOptions[number][]>,
  validOptions: ValidOptions,
  options?: Options,
): readonly [
  state: ValidOptions[number][],
  setState: OptionalStateSetter<ValidOptions[number][]>,
] => {
  return useQueryParamStateArrayWithValidator(
    key,
    defaultValue,
    z.enum(validOptions),
    options,
  )
}

export const useQueryParamNumberState = <
  Options extends UseQueryParamsStateOptionsWithoutEncodeDecode = UseQueryParamsStateOptionsWithoutEncodeDecode,
>(
  key: string,
  defaultValue: number,
  options?: Options,
): readonly [
  state: ReturnUndefinedAsDefaultOptionsState<number, Options>,
  setState: OptionalStateSetter<number>,
] => {
  const ourOptions = useMemo(
    () => ({
      ...(options ?? {}),
      encode: numberEncoder,
      decode: (param: string) => numberDecoder(param, defaultValue),
    }),
    [defaultValue, options],
  )
  return useQueryParamState(key, defaultValue, ourOptions)
}

export const LOAD_PREVIOUS_QUERY_STRING_ON_MOUNT_KEY_PREFIX =
  'loadPreviousQueryStringOnMount'

// If you land on a page without query string parameters, this will load whatever the last query string parameters were.
// Whenever the query string parameters change, this updates (of course)
export const useLoadPreviousQueryStringOnMount = () => {
  const location = useLocation()

  const [localStorageState, setLocalStorageState] = useLocalStorage(
    // The key is the URL (without the search param of course).
    `${LOAD_PREVIOUS_QUERY_STRING_ON_MOUNT_KEY_PREFIX}-${window.location.pathname}`,
    '',
  )

  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (localStorageState && !location.search) {
      location.search = localStorageState
      // This triggers useQueryParamState, which needs to look at `useLocation()` from react router. Doing this doesn't
      // seem to set the URL, and doing the history.replaceState below doesn't seem to trigger the react router hook. 😩
      const newUrl = `${window.location.pathname}${localStorageState}`
      // This sets the url
      window.history.replaceState(
        {
          path: newUrl,
        },
        '',
        newUrl,
      )
      setLocalStorageState(localStorageState)
    }
    setIsFirstRender(false)
    // I only want this to happen on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isFirstRender) {
      setLocalStorageState(location.search)
    }
  }, [isFirstRender, location.search, setLocalStorageState])
}

export const clearPreviousQueryStrings = () => {
  for (const key of Object.keys(localStorage)) {
    if (key.startsWith(LOAD_PREVIOUS_QUERY_STRING_ON_MOUNT_KEY_PREFIX)) {
      localStorage.removeItem(key)
    }
  }
}

// Same as `useContext`, but will throw an error if there is no provider.
export const useStrictContext = <T extends object>(
  context: React.Context<T | undefined>,
): T => {
  const value = useContext(context)

  if (isNullish(value)) {
    throw new Error('Context used without a provider')
  }
  return value
}

export function useStateWithSideEffect<S>(
  sideEffect: StateSetter<S>,
  initialState: S | (() => S),
): [S, StateSetter<S>]
export function useStateWithSideEffect<S = undefined>(
  sideEffect: StateSetter<S | undefined>,
): [S | undefined, StateSetter<S | undefined>]

export function useStateWithSideEffect<S = undefined>(
  sideEffect: StateSetter<S | undefined>,
  initialState?: S | (() => S),
): [S | undefined, StateSetter<S | undefined>] {
  const [state, setStateRaw] = useState(initialState)

  const setState = useCallback<StateSetter<S | undefined>>(
    newState => {
      setStateRaw(newState)
      sideEffect(newState)
    },
    [sideEffect],
  )

  return [state, setState]
}

// This is useful for things like drawers/popups/modals that have an animate-out. It's common pattern to tie a drawer
// open state with data (you have a state variable, maybe query-param-bound, that is either some kind of data or keys
// into something that provides data, that is used to conditionally render a drawer whose content depends on that data).
// To close that drawer, you'll clear out that data. When we mount the drawer, it often animates in. It has the ability
// to animate out with an `open` flag, but will just disappear because it's unmounted. What this hook does is it
// maintains the previous data to display while the drawer animates out. When the data changes, if it's null we don't do
// anything (maintain the previous data in "previousData"). Otherwise we overwrite it. So if you clear out the data, we
// return the old data. If the data changes, we update "previousData", which gets passed through (so it's the new data).
// We also return an "isOpen" flag, which is just based on if the passed-in data exists. We also return a "key". This
// updates to a new guid whenever the data changes. This is helpful if you need to force an element to rerender once new
// data comes in.
export const useHidableData = <T>(
  data: T,
): [data: T, isOpen: boolean, key: string] => {
  const [key, setKey] = useState(() => nextGuid())
  const [prevData, setPrevData] = useState<T>()
  useEffect(() => {
    if (data) {
      setPrevData(data)
      setKey(nextGuid())
    }
  }, [data])

  return [prevData ?? data, !!data, key]
}
