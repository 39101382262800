import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ActionsModalActionProps } from '../../../../adam-components/OnsiteModal/ActionsModalContent'
import { lazyLoadOnsiteModal } from '../../../../utils/code-splitting'
import { useModalState, useStrictContext } from '../../../../utils/react-utils'
import { VisitDetailsActionsProps } from './VisitDetailsActions'

const ActionMenu = lazyLoadOnsiteModal(() => import('./ActionMenu'))

export type ActionMenuItem = Omit<
  ActionsModalActionProps,
  'hasNext' | 'children' | 'icon'
> & {
  label: string
  icon: IconDefinition | NonNullable<React.ReactNode>
}

export type DisabledGlobalActionsMap = Partial<{
  createJob: boolean
  createAccount: boolean
  createMaintenancePlan: boolean
}>

export type ActionMenuContextualData =
  | {
      type: 'Dummy'
      props?: never
    }
  | {
      type: 'Visit'
      props: VisitDetailsActionsProps
    } // | { type: 'ADD_NEW_ONES_LIKE_THIS', props: NewThingProps }

type ActionMenuContextType = {
  showContextualActionState: boolean
  contextualActionData: ActionMenuContextualData | undefined
  setContextualActionData: (contextualData: ActionMenuContextualData) => void
  unsetContextualActionData: (contextualData: ActionMenuContextualData) => void
  openActionsMenu: () => void
  closeActionsMenu: () => void
}

export const ActionMenuContext = React.createContext<
  ActionMenuContextType | undefined
>(undefined)

export const useGlobalActionMenu = (
  contextualData: ActionMenuContextualData,
) => {
  const { setContextualActionData, unsetContextualActionData } =
    useStrictContext(ActionMenuContext)

  useEffect(() => {
    const obj = {
      type: contextualData.type,
      props: contextualData.props,
    } as ActionMenuContextualData
    setContextualActionData(obj)
    return () => {
      unsetContextualActionData(obj)
    }
  }, [
    contextualData.props,
    contextualData.type,
    setContextualActionData,
    unsetContextualActionData,
  ])
}

export const ActionMenuProvider = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const [contextualActionData, setContextualActionData] = useState<
      ActionMenuContextualData | undefined
    >(undefined)

    const unsetContextualActionData = useCallback(
      (prevContextualActionData: ActionMenuContextualData) => {
        setTimeout(() => {
          setContextualActionData(current => {
            if (
              current?.type === prevContextualActionData.type &&
              current?.props === prevContextualActionData.props
            ) {
              return undefined
            }
            return current
          })
        }, 200)
      },
      [],
    )

    const deepEqualData = useMemo<ActionMenuContextualData | undefined>(
      () =>
        contextualActionData?.type
          ? ({
              props: contextualActionData.props,
              type: contextualActionData.type,
            } as ActionMenuContextualData)
          : undefined,
      [contextualActionData?.props, contextualActionData?.type],
    )

    const [showContextualActionState, setShowContextualActionState] = useState(
      !!deepEqualData,
    )

    useEffect(() => {
      let kill = false

      setTimeout(() => {
        if (!kill) {
          setShowContextualActionState(!!deepEqualData)
        }
      }, 300)
      return () => {
        kill = true
      }
    }, [deepEqualData])

    const [actionsMenuOpen, openActionsMenu, closeActionsMenu] = useModalState()

    return (
      <ActionMenuContext.Provider
        value={{
          setContextualActionData,
          unsetContextualActionData,
          openActionsMenu,
          closeActionsMenu,
          contextualActionData: deepEqualData,
          showContextualActionState,
        }}
      >
        {children}
        <ActionMenu
          actionsMenuOpen={actionsMenuOpen}
          closeActionsMenu={closeActionsMenu}
          contextualActionData={contextualActionData}
        />
      </ActionMenuContext.Provider>
    )
  },
)
