import { BzDateFns, bzExpect, isNullish } from '@breezy/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSubscription } from 'urql'
import { trpc } from '../../hooks/trpc'
import { useStrictContext } from '../../utils/react-utils'
import { useExpectedPrincipal } from '../PrincipalUser'
import {
  TECH_CLOCKED_IN_QUERY,
  TIME_CLOCK_PROVIDER_DATA_SUBSCRIPTION,
} from './TimeClockProvider.gql'

export type UserTimeClockCurrentAssignment = {
  activity: string
  jobGuid: string
  jobAppointmentGuid: string
  jobAppointmentAssignmentGuid: string
}

type TimeClockContextType = {
  currentAssignment?: UserTimeClockCurrentAssignment
  clockedInTime?: Date
  localElapsedDurationMin: number
  refetch: () => void
  isClockedIn: boolean
  optimisticIsClockedIn: boolean
  fetching: boolean
  clockIn: () => void
  loadingClockedInState?: boolean
}

const TimeClockContext = React.createContext<TimeClockContextType | undefined>(
  undefined,
)

export const useTimeClock = () => useStrictContext(TimeClockContext)

// TODO: this is copy/pasted from older code. It seems incredibly redundant with the other fetch we do and can probably
// be consolidated.
const useIsTechClockedIn = (
  companyGuid: string,
  userGuid: string,
  pause: boolean,
) => {
  const [res] = useSubscription({
    query: TECH_CLOCKED_IN_QUERY,
    variables: {
      companyGuid,
      userGuid,
    },
    pause,
  })

  return useMemo(
    () => ({
      isClockedIn: !isNullish(res.data) && res.data.timesheetEntries.length > 0,
      clockInTime: !isNullish(res.data?.timesheetEntries[0])
        ? res.data?.timesheetEntries[0].finalStartTime
        : undefined,
      loadingClockedInState: !res.data,
    }),
    [res.data],
  )
}

type TimeClockProviderProps = React.PropsWithChildren

export const TimeClockProvider = React.memo<TimeClockProviderProps>(
  ({ children }) => {
    const principal = useExpectedPrincipal()

    const company = principal.company

    const tzId = company?.timezone ?? BzDateFns.UTC
    const startOfToday = useMemo(
      () =>
        BzDateFns.formatISO(BzDateFns.startOfDay(BzDateFns.now(tzId)), tzId),
      [tzId],
    )

    const pause = !principal.company || !principal.userGuid

    const companyGuid = company?.companyGuid ?? ''
    const userGuid = principal.userGuid ?? ''

    const { isClockedIn, clockInTime, loadingClockedInState } =
      useIsTechClockedIn(companyGuid, userGuid, pause)

    const [optimisticIsClockedIn, setOptimisticIsClockedIn] =
      useState<boolean>(isClockedIn)

    useEffect(() => {
      setOptimisticIsClockedIn(isClockedIn)
    }, [isClockedIn])

    const [waitingOnClockState, setIsWaitingOnClockState] = useState<
      'clockIn' | 'clockOut' | undefined
    >(undefined)
    const [res, refetch] = useSubscription({
      query: TIME_CLOCK_PROVIDER_DATA_SUBSCRIPTION,
      variables: {
        companyGuid,
        userGuid,
        startTime: !isNullish(clockInTime) ? clockInTime : startOfToday,
      },
      pause,
    })

    const updateTimeClockStatus =
      trpc.timesheets['timesheets:update-time-clock-status'].useMutation()

    const clockedInTime = useMemo<Date | undefined>(() => {
      if (isNullish(clockInTime)) {
        return undefined
      }

      return BzDateFns.parseISO(bzExpect(clockInTime), tzId)
    }, [clockInTime, tzId])

    const startingElapsedDuration: number = useMemo(() => {
      if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
        return 0
      }

      let elapsedMin: number = res.data.timesheetEntries.reduce<number>(
        (acc, entry) =>
          acc +
          (isNullish(entry.entryLengthInMinutes)
            ? 0
            : entry.entryLengthInMinutes),
        0,
      )

      const currTimesheetEntry =
        res.data.timesheetEntries[res.data.timesheetEntries.length - 1]

      if (isNullish(currTimesheetEntry.finalEndTime)) {
        const startTime = BzDateFns.parseISO(
          bzExpect(currTimesheetEntry.finalStartTime),
          tzId,
        )

        const now = BzDateFns.now(tzId)
        const elapsedMinForCurrTimesheetEntry = BzDateFns.differenceInMinutes(
          startTime,
          now,
        )
        elapsedMin += Math.abs(elapsedMinForCurrTimesheetEntry)
      }

      return elapsedMin
    }, [res.data, tzId])

    const [localElapsedDurationMin, setLocalElapsedDurationMin] =
      useState<number>(startingElapsedDuration)

    const currentAssignment = useMemo<
      UserTimeClockCurrentAssignment | undefined
    >(() => {
      if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
        return undefined
      }

      const { timesheetEntryLinkData, timesheetEntryActivity } =
        res.data.timesheetEntries[res.data.timesheetEntries.length - 1]
      if (
        isNullish(timesheetEntryLinkData[0].jobGuid) ||
        isNullish(timesheetEntryLinkData[0].jobAppointmentGuid) ||
        isNullish(timesheetEntryLinkData[0].jobAppointmentAssignmentGuid)
      ) {
        return undefined
      }

      return {
        activity: bzExpect(timesheetEntryActivity?.activityName),
        jobGuid: bzExpect(timesheetEntryLinkData[0].jobGuid),
        jobAppointmentGuid: bzExpect(
          timesheetEntryLinkData[0].jobAppointmentGuid,
        ),
        jobAppointmentAssignmentGuid: bzExpect(
          timesheetEntryLinkData[0].jobAppointmentAssignmentGuid,
        ),
      }
    }, [res.data])

    useEffect(() => {
      const timer = setTimeout(() => {
        if (waitingOnClockState) {
          setIsWaitingOnClockState(undefined)
        }
      }, 2000)

      return () => clearTimeout(timer)
    }, [waitingOnClockState, setIsWaitingOnClockState])

    useEffect(() => {
      setLocalElapsedDurationMin(startingElapsedDuration)
    }, [startingElapsedDuration])

    useEffect(() => {
      if (!isClockedIn) {
        setLocalElapsedDurationMin(0)
        return
      }

      const intervalId = setInterval(() => {
        setLocalElapsedDurationMin(prev => prev + 1)
      }, 1000 * 60)

      return () => clearInterval(intervalId)
    }, [isClockedIn, waitingOnClockState, setIsWaitingOnClockState])

    const clockIn = useCallback(() => {
      setIsWaitingOnClockState(isClockedIn ? 'clockOut' : 'clockIn')
      setOptimisticIsClockedIn(!isClockedIn)
      updateTimeClockStatus.mutate({})
      refetch()
    }, [updateTimeClockStatus, refetch, setIsWaitingOnClockState, isClockedIn])

    const fetching = !isNullish(waitingOnClockState) || res.fetching

    return (
      <TimeClockContext.Provider
        value={{
          currentAssignment,
          clockedInTime,
          localElapsedDurationMin,
          refetch,
          isClockedIn,
          fetching,
          clockIn,
          loadingClockedInState,
          optimisticIsClockedIn,
        }}
      >
        {children}
      </TimeClockContext.Provider>
    )
  },
)
