import { z } from 'zod'
import { IsoDateString } from './BzDateFns'
import { BreezyErrorSeverity } from './errors/BreezyErrorSeverity'
import { BusinessException, InvalidEntityStateException } from './errors/BusinessException'
import { ThisShouldNeverHappenError } from './errors/PlatformErrors'
import { AsyncFn } from './functional-core'

export const nonTypedEntityEventInputSchema = z.object({
  companyGuid: z.string(),
  entityGuid: z.string(),
})

export type NonTypedEventInputMetadata = {
  readonly companyGuid: string
  readonly entityGuid: string
}

export type EventInputMetadata = {
  readonly companyGuid: string
  readonly entityType: string
  readonly entityGuid: string
}

export type EventInput<TEventData = never> = EventInputMetadata & {
  readonly eventData: TEventData
}

type EventMetadata = EventInputMetadata & {
  readonly entityVersion: number
  readonly actingUserGuid: string
  readonly occurredAt: IsoDateString
}

export type Event<TEventData = never> = EventMetadata & EventPayload<TEventData>

export const createSyncEventHookTrigger = (input: EventInputMetadata): Event<unknown> => ({
  ...input,
  entityVersion: -1,
  eventType: 'Sync',
  actingUserGuid: '00000000-0000-0000-0000-000000000000',
  occurredAt: new Date().toISOString(),
  eventData: undefined,
})

export type EventPayload<TEventData = never> = {
  eventType: string
  eventData: TEventData
}

export const EventInputDto = z.object({
  companyGuid: z.string(),
  entityType: z.string(),
  entityGuid: z.string(),
})

export type CompanyEntityGuidContainer = {
  readonly companyGuid: string
  readonly entityGuid: string
}

export const companyEntityTypeSchema = z.object({
  companyGuid: z.string(),
  entityType: z.string(),
})

export type CompanyEntityTypeContainer = {
  readonly companyGuid: string
  readonly entityType: string
}

export type EntityGuidContainer = {
  readonly entityGuid: string
}

export type IEventEntityAggregator = AsyncFn<CompanyEntityTypeContainer, Event<unknown>[]>
export type IEventEntityGuidsAggregator = AsyncFn<CompanyEntityTypeContainer, EntityGuidContainer[]>
export type IEventStore = {
  read: AsyncFn<CompanyEntityGuidContainer, Event<unknown>[]>
  create: AsyncFn<Event<unknown>>
}

type EntityTypeContainer = {
  readonly entityType: string
}

export type IEventStoreFactory = {
  /** @deprecated */
  get: ({ entityType }: EntityTypeContainer) => IEventStore
}

export class ReadOnlyEventStoreWrapper implements IEventStore {
  constructor(private readonly inner: IEventStore) {}

  /** @deprecated */
  read = async (req: CompanyEntityGuidContainer): Promise<Event<unknown>[]> => this.inner.read(req)

  /** @deprecated */
  create = async (_: Event<unknown>): Promise<void> => {
    throw new ThisShouldNeverHappenError('Cannot create events on a ReadOnlyEventStore')
  }
}

export class InMemoryEventStoreFactory implements IEventStoreFactory {
  private readonly eventStores: Record<string, IEventStore> = {}

  /** @deprecated */
  get = ({ entityType }: EntityTypeContainer): IEventStore => {
    if (!this.eventStores[entityType]) this.eventStores[entityType] = new InMemorySingleEntityEventStore()
    return this.eventStores[entityType]
  }
}

export const sequencedEventHook =
  (...hooks: EventHook[]): EventHook =>
  async (event: Event<unknown>) => {
    for (const hook of hooks) {
      await hook(event)
    }
  }

export type EventHook = (event: Event<unknown>) => Promise<void>
export const parallelEventHook =
  (...hooks: EventHook[]): EventHook =>
  async (event: Event<unknown>) => {
    await Promise.all(hooks.map(hook => hook(event)))
  }

export class AfterEventWriteEventFactory implements IEventStoreFactory {
  constructor(private readonly eventStoreFactory: IEventStoreFactory, private readonly afterWrite: EventHook) {}

  /** @deprecated */
  get = ({ entityType }: EntityTypeContainer): IEventStore =>
    new AfterEventWrite(this.eventStoreFactory.get({ entityType }), this.afterWrite)
}

class AfterEventWrite implements IEventStore {
  constructor(private readonly eventStore: IEventStore, private readonly afterWrite: EventHook) {}

  /** @deprecated */
  read = async (input: CompanyEntityGuidContainer): Promise<Event<unknown>[]> => this.eventStore.read(input)

  /** @deprecated */
  create = async (event: Event<unknown>): Promise<void> => {
    await this.eventStore.create(event)
    this.afterWrite(event) // fire and forget
  }
}

export class ReadOnlyEventStore implements IEventStore {
  constructor(private readonly events: Event<unknown>[]) {}

  /** @deprecated */
  read = async (input: CompanyEntityGuidContainer): Promise<Event<unknown>[]> =>
    this.events.filter(e => e.entityGuid === input.entityGuid)

  /** @deprecated */
  create = async (_: Event<unknown>): Promise<void> => {
    throw new InvalidEntityStateException('Cannot create events on a ReadOnlyEventStore')
  }
}

export class InMemorySingleEntityEventStore implements IEventStore {
  private readonly events: Event<unknown>[] = []
  read = async (_: CompanyEntityGuidContainer): Promise<Event<unknown>[]> => this.events

  create = async (event: Event<unknown>): Promise<void> => {
    this.events.push(event)
  }

  getLastEventAs = <T>() => this.events[this.events.length - 1] as Event<T>
}

export type IEventSourceReferenceNumberProvider = AsyncFn<CompanyEntityTypeContainer, string>

type EntityEventTypeHookElements = {
  entityType: string
  eventType: string
  onError: (err: unknown, event: Event<unknown>) => void
  eventHook: EventHook
}

/** @deprecated */
export const createEntityEventTypeEventHook = (elements: EntityEventTypeHookElements) => {
  const { entityType, eventType, onError, eventHook } = elements
  return async (event: Event<unknown>): Promise<void> => {
    if (event.entityType !== entityType) return
    if (event.eventType !== eventType) return

    try {
      await eventHook(event)
    } catch (err) {
      onError(err, event)
    }
  }
}

/** @deprecated */
export class EntityVersionConflictException extends BusinessException {
  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'WARN') {
    super(message, options, severity)
  }
}
