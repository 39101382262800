import { Call, Device } from '@twilio/voice-sdk'
import { AccountsDetailsQuery } from '../../generated/user/graphql'
import { ContactLite } from './useFetchContactLiteByPhoneNumber'

export type IntegratedPhoneStatus = 'disconnected' | 'connected' | 'busy'

export type TwilioPhoneNumber = string

export type AccountDetails = NonNullable<AccountsDetailsQuery['accountsByPk']>

export type EnrichedCallContactData = {
  avatarText?: string
  contactName?: string
  accountGuid?: string
  contactGuid?: string
  comprehensiveAccount?: AccountDetails
  phoneNumber?: TwilioPhoneNumber
  leadSourceName?: string
  leadSourceGuid?: string
  integratedPhoneCallGuid?: string
}

export type InboundCallData = {
  twilio?: Call
  from: TwilioPhoneNumber
}

export type OutboundCallData = {
  twilio?: Call
  to: TwilioPhoneNumber
}

export type IncomingCall = EnrichedCallContactData &
  InboundCallData & {
    accept: () => void
    reject: () => void
  }

export type ActiveCall = EnrichedCallContactData &
  InboundCallData & {
    answeredAt: Date
    hangup: () => void
  }

export type OutboundCall = EnrichedCallContactData &
  OutboundCallData & {
    startedAt: Date
    hangup: () => void
  }

export type BeginPhoneCallToData = {
  phoneNumber: string
  contact?: ContactLite
}

export type AudioDeviceOption = {
  deviceId: string
  label: string
}

export type AudioDeviceOptions = {
  outputDevice: AudioDeviceOption[]
  inputDevice: AudioDeviceOption[]
}

export type AudioSettings = {
  selectedDeviceIds: {
    outputDeviceId?: string
    inputDeviceId?: string
  }
  audioDevices: AudioDeviceOptions
  setOutputDevice: (device: AudioDeviceOption) => Promise<void>
  setInputDevice: (device: AudioDeviceOption) => Promise<void>
}

export const emptyAudioSettings: AudioSettings = {
  selectedDeviceIds: {
    outputDeviceId: undefined,
    inputDeviceId: undefined,
  },
  audioDevices: {
    outputDevice: [],
    inputDevice: [],
  },
  setOutputDevice: async () => {},
  setInputDevice: async () => {},
}

export type TwilioPhoneContextType = {
  status: IntegratedPhoneStatus
  everInteracted: boolean
  incomingCall: IncomingCall | null
  activeCall: ActiveCall | null
  outboundCall: OutboundCall | null
  beginVoiceCallTo: (req: BeginPhoneCallToData) => Promise<void>
  audioSettings: AudioSettings
  device: Device | null
}
