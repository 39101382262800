import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'
import { BusinessException } from './errors/BusinessException'

export { isPossibleNumber, isValidPhoneNumber } from 'libphonenumber-js'

export const tryFormat = (input: string) => {
  return new AsYouType('US').input(input)
}

export const tryFormatWithFallback = (input: string, fallback: string) => {
  return tryFormat(input) ? tryFormat(input) : fallback
}

export class InvalidPhoneNumberError extends BusinessException {}

export const getFormattedPhoneNumber = (input: string): string => {
  const phoneNumber = parsePhoneNumber(input.trim(), 'US')
  if (phoneNumber) return phoneNumber.formatInternational()

  throw new InvalidPhoneNumberError(input)
}

export const trimPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/[^0-9]/g, '')
}

export const phoneNumberWithHiddenCallerId = (phoneNumber: string) => {
  return `*67${trimPhoneNumber(phoneNumber)}`
}
