import { CalculatePaths } from '@breezy/shared'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentOnsitePageDimensions } from '../../../../adam-components/OnsitePage/onsitePageUtils'
import { useFeatureFlag } from '../../../../hooks/useFeatureFlags'
import { useIsCompanyUser } from '../../../../providers/PrincipalUser'
import { useStrictContext } from '../../../../utils/react-utils'
import { AppointmentStatusHeader } from '../../../AppointmentStatusHeader/AppointmentStatusHeader'
import { TECH_APP_NAV_BAR_HEIGHT } from '../TechnicianAppBottomNavBar'
import {
  CurrentVisitContext,
  useTechAppBottomOffset,
} from './CurrentVisitProvider'

export const CURRENT_VISIT_CARD_HEIGHT = 60

const CURRENT_VISIT_CARD_MARGIN_SPILL = 24

export const CurrentVisitCard = React.memo(() => {
  const { isOpen, currentAssignmentData } =
    useStrictContext(CurrentVisitContext)
  const isCompanyUser = useIsCompanyUser()
  const navigate = useNavigate()

  const currentVisitCardEnabled = useFeatureFlag('current-visit-card')

  const { pageContentMargin } = useCurrentOnsitePageDimensions()

  const currentVisitMargin = useMemo(() => {
    return Math.max(0, pageContentMargin - CURRENT_VISIT_CARD_MARGIN_SPILL)
  }, [pageContentMargin])

  if (!currentVisitCardEnabled || !isCompanyUser || !currentAssignmentData) {
    return null
  }
  const { appointment, job, appointmentGuid } = currentAssignmentData
  const { appointmentType } = appointment
  const { displayId, jobType, maintenancePlan } = job
  return (
    <div
      onClick={() =>
        navigate(CalculatePaths.appointmentDetails({ appointmentGuid }))
      }
      className="fixed z-[1002] cursor-pointer overflow-visible transition-[height] duration-500 ease-in-out"
      style={{
        bottom: `${TECH_APP_NAV_BAR_HEIGHT}px`,
        left: `${currentVisitMargin}px`,
        right: `${currentVisitMargin}px`,
        height: isOpen ? `${CURRENT_VISIT_CARD_HEIGHT}px` : 0,
      }}
    >
      <div className="h-full max-h-full rounded-t-2xl border border-b-0 border-solid border-bz-border bg-white p-4 shadow-secondary">
        <AppointmentStatusHeader
          appointmentType={appointmentType}
          jobTypeName={jobType.name}
          jobClass={jobType.jobClass}
          jobDisplayId={displayId}
          hasMaintenancePlan={!!maintenancePlan}
          maintenancePlanPrimaryColorHex={
            maintenancePlan?.maintenancePlanDefinition?.flare?.primaryColorHex
          }
        />
      </div>
    </div>
  )
})

export const CurrentVisitCardContainer = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const { bottomOffset } = useTechAppBottomOffset()
    return (
      <div
        className="h-full overflow-y-auto overflow-x-hidden"
        style={{
          paddingBottom: `${bottomOffset}px`,
        }}
      >
        {children}
        <CurrentVisitCard />
      </div>
    )
  },
)
