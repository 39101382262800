import { TechnicianRoutes } from '@breezy/shared'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import classNames from 'classnames'
import { ReactNode, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import './TopNavbar.less'

const FLANKING_BUTTON_WIDTH = '24px'

const TopNavbar = () => {
  const newTechAppHeaderEnabled = useFeatureFlag('newTechAppHeader')
  const navigate = useNavigate()

  const location = useLocation()
  const getLongestRouteMatch = useMemo((): ReactNode => {
    const locationPathArray = location.pathname.split('/').slice(1)
    let matchingRouteLabel = ''

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, route] of Object.entries(TechnicianRoutes)) {
      const routePathArray = route.path.split('/').slice(1)

      let matchesRoute = routePathArray.length === locationPathArray.length

      if (matchesRoute) {
        for (let i = 0; i < routePathArray.length; i++) {
          const matchesWildcard =
            (locationPathArray[i] && routePathArray[i].startsWith(':')) ||
            routePathArray[i] === '*'

          if (routePathArray[i] !== locationPathArray[i] && !matchesWildcard) {
            matchesRoute = false
            continue
          }
        }
      }

      if (matchesRoute) {
        matchingRouteLabel = route.label
      }
    }

    return (
      <div
        className={classNames('card-title-lg', {
          'm-0': newTechAppHeaderEnabled,
        })}
      >
        {' '}
        {matchingRouteLabel}{' '}
      </div>
    )
  }, [location.pathname, newTechAppHeaderEnabled])

  return (
    <Header
      className={classNames(
        'z-[500] bg-white',
        newTechAppHeaderEnabled
          ? 'h-[52px] px-4 py-2 shadow-bz-bottom-100'
          : 'technician-experience-header h-[64px] px-6 py-0',
      )}
    >
      <div className="flex h-full items-center justify-between">
        <div className="flex h-full items-center space-x-2">
          {newTechAppHeaderEnabled ? (
            <Button
              className="flex h-9 w-9 items-center justify-center text-lg"
              shape="circle"
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              onClick={() => navigate(-1)}
            />
          ) : (
            <div
              style={{ minWidth: FLANKING_BUTTON_WIDTH }}
              className="flex items-center justify-center"
            >
              <FontAwesomeIcon
                icon={newTechAppHeaderEnabled ? faChevronLeft : faArrowLeft}
                className="fa-icon-aa-fix text-xl"
                onClick={() => navigate(-1)}
              />
            </div>
          )}
        </div>
        {getLongestRouteMatch}
        {/* This is just here to balance the header */}
        <div
          style={{ minWidth: FLANKING_BUTTON_WIDTH }}
          className="relative flex items-center justify-center"
        />
      </div>
    </Header>
  )
}

export default TopNavbar
