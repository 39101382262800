import { withRetry } from '@breezy/shared'
import { Loader } from '@googlemaps/js-api-loader'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { getConfig } from '../config'
import { postErrorAlertWithDD } from '../utils/GraphqlAlerts'
import { useMessage } from '../utils/antd-utils'

const { mapsAPIKey } = getConfig()

type GooglePlacesContextWrapper = {
  googleLoading: boolean
  autocompleteService: google.maps.places.AutocompleteService | undefined
  placesService: google.maps.places.PlacesService | undefined
}

const GooglePlacesContext = createContext<GooglePlacesContextWrapper>({
  googleLoading: true,
  autocompleteService: undefined,
  placesService: undefined,
})

export const useGooglePlaces = () => {
  return useContext(GooglePlacesContext)
}

export const getAutocompleteSessionToken = () => {
  if (google.maps.places?.AutocompleteSessionToken) {
    return new google.maps.places.AutocompleteSessionToken()
  }
  return undefined
}

const GoogleMapsLoader = new Loader({
  apiKey: mapsAPIKey,
  version: 'weekly',
  libraries: ['places'],
})

export const GooglePlacesWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const message = useMessage()
    const [autocompleteService, setAutocompleteService] =
      useState<google.maps.places.AutocompleteService>()
    const [placesService, setPlacesService] =
      useState<google.maps.places.PlacesService>()

    useEffect(() => {
      try {
        withRetry(async () => {
          const placesLibrary = await GoogleMapsLoader.importLibrary('places')
          setAutocompleteService(new placesLibrary.AutocompleteService())
          setPlacesService(
            new placesLibrary.PlacesService(document.createElement('div')),
          )
        })
      } catch (e) {
        console.error('Failed to process scanned label', e)
        postErrorAlertWithDD(
          `Could not load Google Maps Places API: ${(e as Error).toString()}`,
        )
        message.error(
          'Google maps service failed to load. Some features may not work as intended. Consider refreshing the app.',
        )
      }
      // I only want this to happen on mount.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <GooglePlacesContext.Provider
        value={{
          googleLoading: !autocompleteService || !placesService,
          autocompleteService,
          placesService,
        }}
      >
        {children}
      </GooglePlacesContext.Provider>
    )
  },
)
