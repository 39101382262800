import { Guid } from '@breezy/shared'
import { useCallback } from 'react'
import { useMutation } from 'urql'
import { gql } from '../generated'
import { useExpectedUserGuid } from '../providers/PrincipalUser'

const UPSERT_RECENT_PRICEBOOK_ITEMS_MUTATION = gql(/* GraphQL */ `
  mutation UpsertRecentPricebookItems(
    $recentPricebookItems: [RecentPricebookItemsInsertInput!]!
    $onConflict: RecentPricebookItemsOnConflict
  ) {
    insertRecentPricebookItems(
      objects: $recentPricebookItems
      onConflict: $onConflict
    ) {
      __typename
    }
  }
`)

export const useUpsertRecentPricebookItems = () => {
  const userGuid = useExpectedUserGuid()
  const [, upsertRecentPricebookItems] = useMutation(
    UPSERT_RECENT_PRICEBOOK_ITEMS_MUTATION,
  )

  return useCallback(
    (pricebookItemsGuids: Guid[]) =>
      upsertRecentPricebookItems({
        recentPricebookItems: [...new Set(pricebookItemsGuids)].map(guid => ({
          userGuid,
          pricebookItemGuid: guid,
          lastViewedAt: new Date().toISOString(),
        })),
        onConflict: {
          updateColumns: ['lastViewedAt'],
          constraint: 'recent_pricebook_items_pkey',
        },
      }),
    [userGuid, upsertRecentPricebookItems],
  )
}
