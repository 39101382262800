import { gql } from '../../../../generated'

export const CURRENT_VISIT_CARD_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription CurrentVisitData($userGuid: uuid!) {
    assignments: jobAppointmentAssignments(
      where: {
        assignmentStatus: {
          jobAppointmentAssignmentStatusType: { _in: [EN_ROUTE, IN_PROGRESS] }
        }
        technicianUserGuid: { _eq: $userGuid }
      }
      limit: 1
    ) {
      appointmentGuid: jobAppointmentGuid
      job {
        displayId
        jobType {
          name
          jobClass
        }
        maintenancePlan {
          maintenancePlanDefinition {
            flare {
              primaryColorHex
            }
          }
        }
      }
      appointment: jobAppointment {
        appointmentType
      }
    }
  }
`)
