import React, { useCallback, useMemo, useState } from 'react'
import { useModalState } from '../../utils/react-utils'
import { OnsiteConfirmModal, OnsiteConfirmModalProps } from './OnsiteModal'

export type BaseConfirmationModalProps = {
  onCancel: () => void
  onConfirm: () => void
  open?: boolean
}

export type UseConfirmationModalValue<
  T extends BaseConfirmationModalProps = BaseConfirmationModalProps,
> = [withConfirmation: (onConfirm: () => void) => void, props: T]

export const useConfirmationModal = (): UseConfirmationModalValue => {
  const [confirmCloseOpen, openConfirmClose, closeConfirmCancel] =
    useModalState()

  const [confirmAction, setConfirmAction] = useState<
    { action: () => void } | undefined
  >()

  const withConfirmation = useCallback(
    (onCancelConfirm: () => void) => {
      setConfirmAction({
        action: onCancelConfirm,
      })
      openConfirmClose()
    },
    [openConfirmClose],
  )

  const onConfirm = useCallback(() => {
    closeConfirmCancel()
    confirmAction?.action()
  }, [closeConfirmCancel, confirmAction])

  const onCancel = useCallback(() => {
    setConfirmAction(undefined)
    closeConfirmCancel()
  }, [closeConfirmCancel])

  const props = useMemo(
    () => ({ onConfirm, onCancel, open: confirmCloseOpen }),
    [confirmCloseOpen, onCancel, onConfirm],
  )

  return [withConfirmation, props]
}

export type ConfirmationModalProps = BaseConfirmationModalProps &
  OnsiteConfirmModalProps

export const ConfirmationModal = React.memo<ConfirmationModalProps>(
  ({ open = true, confirmText = 'Confirm', ...rest }) => {
    if (!open) {
      return null
    }
    return <OnsiteConfirmModal confirmText={confirmText} {...rest} />
  },
)
