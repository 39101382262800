import { PermissionV1, PermissionV2 } from '@breezy/shared'
import {
  faBuilding,
  faGear,
  faQuestionCircle,
  faRightToBracket,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons'
import {
  faAdd,
  faCalendar,
  faDownload,
  faEllipsis,
  faGift,
  faHome,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBolt,
  faCalendar as faCalendarSolid,
  faHome as faHomeSolid,
  faSearch as faSearchSolid,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { default as classNames } from 'classnames'
import React, { useCallback, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import {
  ActionsModalAction,
  ActionsModalContent,
} from '../../../adam-components/OnsiteModal/ActionsModalContent'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { useCurrentOnsitePageDimensions } from '../../../adam-components/OnsitePage/onsitePageUtils'
import { getConfig } from '../../../config'
import { useIsAuthorized } from '../../../hooks/permission/useIsAuthorized'
import { useAuth } from '../../../hooks/useAuth'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import useIsMobile from '../../../hooks/useIsMobile'
import { UserSettingsView } from '../../../pages/SettingsPage/UserSettingsPage'
import {
  useCompanyGuid,
  useIsCompanyUser,
} from '../../../providers/PrincipalUser'
import { isIOS } from '../../../utils/ios-fixes'
import {
  useHidableData,
  useModalState,
  useStrictContext,
} from '../../../utils/react-utils'
import { TechnicianReactRoutes } from '../../../utils/TechnicianReactRoutes'
import { AdminImpersonateUser } from '../../Admin/AdminImpersonateUser/AdminImpersonateUser'
import { BreezyReferralModal } from '../../BreezyReferralModal/BreezyReferralModal'
import { Authorized } from '../../Permissions/Authorized/Authorized'
import { PWAInstallPromptContext } from '../PWAInstallPrompt/PWAInstallPrompt'
import { ActionMenuContext } from './ActionMenu/ActionMenuProvider'
import './TechnicianAppBottomNavBar.less'
const { platformVersion } = getConfig()

const { officeExperienceUrl } = getConfig()

const NEW_ACTIONS_TOAST_TIMEOUT = 1500

const NEW_ACTIONS_TOAST_MAX_COUNT = 5

type MoreMenuProps = {
  onClose: () => void
}

type MoreMenuItem = [
  string,
  IconDefinition,
  () => void,
  PermissionV1 | PermissionV2 | undefined,
]

const MoreMenu = React.memo<MoreMenuProps>(({ onClose }) => {
  const { logout } = useAuth()
  const companyGuid = useCompanyGuid()
  const [userSettingsOpen, openUserSettings, closeUserSettings] =
    useModalState()
  const [referBreezyOpen, openReferBreezy, closeReferBreezy] = useModalState()
  const { showPrompt: showPWAInstallPrompt } = useStrictContext(
    PWAInstallPromptContext,
  )

  const installPwaPromptEnabled = useFeatureFlag('installPwaPrompt')

  const openIntercomHelp = useCallback(() => {
    if (typeof window.Intercom === 'function') {
      window.Intercom('show')
    }
  }, [])

  const isPWA = window.matchMedia('(display-mode: standalone)').matches

  const menuItems = useMemo<MoreMenuItem[]>(() => {
    const menuItems: MoreMenuItem[] = [
      ['Help', faQuestionCircle, openIntercomHelp, undefined],
    ]
    if (!isPWA && installPwaPromptEnabled) {
      menuItems.push([
        'Install App',
        faDownload,
        showPWAInstallPrompt,
        undefined,
      ])
    }
    if (companyGuid) {
      menuItems.push(
        ['User Settings', faGear, openUserSettings, undefined],
        ['Refer Breezy', faGift, openReferBreezy, undefined],
      )
    }
    menuItems.push(
      [
        'Office Experience',
        faBuilding,
        () => {
          window.location.href = officeExperienceUrl
        },
        PermissionV2.USE_OFFICE_EXPERIENCE,
      ],
      ['Logout', faRightToBracket, logout, undefined],
    )

    return menuItems
  }, [
    openIntercomHelp,
    isPWA,
    installPwaPromptEnabled,
    companyGuid,
    logout,
    showPWAInstallPrompt,
    openUserSettings,
    openReferBreezy,
  ])

  return (
    <>
      <OnsiteBasicModal
        onClose={onClose}
        onBack={userSettingsOpen ? closeUserSettings : undefined}
        header={userSettingsOpen ? 'User Settings' : 'More'}
        headerBordered
      >
        {userSettingsOpen ? (
          <UserSettingsView />
        ) : (
          <ActionsModalContent className="pb-4">
            {menuItems.map(([label, icon, onClick, permission]) => (
              <Authorized to={permission} allowsNonCompanyUser key={label}>
                <ActionsModalAction
                  onClick={onClick}
                  icon={<FontAwesomeIcon icon={icon} />}
                >
                  {label}
                </ActionsModalAction>
              </Authorized>
            ))}
            <div className="text-xs text-gray-300">
              Version {(platformVersion ?? '').slice(0, 7)}
            </div>
            <AdminImpersonateUser onImpersonateChange={onClose} />
          </ActionsModalContent>
        )}
      </OnsiteBasicModal>
      {referBreezyOpen && <BreezyReferralModal onClose={closeReferBreezy} />}
    </>
  )
})

export const TECH_APP_NAV_BAR_HEIGHT = 65 + (isIOS() ? 8 : 0)

type NavButtonProps = {
  icon?: IconDefinition
  activeIcon?: IconDefinition
  onClick?: () => void
  path?: string
  className?: string
  testId?: string
  highlightWhenActive?: boolean
}

const NavButton = React.memo<NavButtonProps>(
  ({
    icon,
    activeIcon,
    onClick,
    path,
    className,
    testId,
    highlightWhenActive = true,
  }) => {
    const resolvedActiveIcon = activeIcon ?? icon

    const isMobile = useIsMobile()
    const button = (
      <div
        data-testid={testId}
        onClick={onClick}
        className={classNames(
          'h-full',
          !path ? ['nav-button ', className] : undefined,
          {
            'px-6': !isMobile,
          },
        )}
      >
        <div className="flex h-10 w-10 items-center justify-center rounded-md text-xl transition-all">
          {icon ? (
            <FontAwesomeIcon icon={icon} className="non-active-icon" />
          ) : null}
          {resolvedActiveIcon ? (
            <FontAwesomeIcon
              icon={resolvedActiveIcon}
              className="active-icon"
            />
          ) : null}
        </div>
      </div>
    )
    if (path) {
      return (
        <NavLink
          to={path}
          className={({ isActive }) =>
            classNames(
              'nav-button h-full transition-none',
              isActive && highlightWhenActive
                ? 'nav-button-active text-bz-primary *:*:bg-bz-primary-bg'
                : 'text-bz-text-secondary',
              className,
            )
          }
        >
          {button}
        </NavLink>
      )
    }
    return button
  },
)

const TechnicianAppBottomNavBar = React.memo(() => {
  const isMobile = useIsMobile()
  const isAuthorized = useIsAuthorized()

  const canViewAccountSearch = useMemo(
    () => isAuthorized(PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL),
    [isAuthorized],
  )

  const { contentWidth } = useCurrentOnsitePageDimensions()
  const isCompanyUser = useIsCompanyUser()
  const { openActionsMenu, contextualActionData, showContextualActionState } =
    useStrictContext(ActionMenuContext)

  const [moreOpen, openMore, closeMore] = useModalState()

  const [newActionsToastOpen, openNewActionsToast, closeNewActionsToast] =
    useModalState()

  useEffect(() => {
    if (contextualActionData?.type && contextualActionData.type !== 'Dummy') {
      const localStorageKey = `newActionsToast-${contextualActionData.type}`
      const count = parseInt(localStorage.getItem(localStorageKey) ?? '0', 10)

      if (count >= NEW_ACTIONS_TOAST_MAX_COUNT) {
        return
      }

      openNewActionsToast()
      localStorage.setItem(localStorageKey, (count + 1).toString())
      setTimeout(closeNewActionsToast, NEW_ACTIONS_TOAST_TIMEOUT)
    }
  }, [closeNewActionsToast, contextualActionData?.type, openNewActionsToast])

  const [actionName] = useHidableData(contextualActionData?.type)

  const openIntercomHelp = useCallback(() => {
    if (typeof window.Intercom === 'function') {
      window.Intercom('show')
    }
  }, [])

  return (
    <>
      <div
        className="pointer-events-none fixed inset-x-0 bottom-0 z-[1003]"
        style={{
          height: `${TECH_APP_NAV_BAR_HEIGHT}px`,
          minHeight: `${TECH_APP_NAV_BAR_HEIGHT}px`,
        }}
      >
        <div className="technician-experience-footer">
          <div className="technician-experience-footer-cutout">
            <div className="technician-experience-footer-cutout-inner" />
            <div className="technician-experience-footer-cutout-top-border technician-experience-footer-cutout-top-border-left" />
            <div className="technician-experience-footer-cutout-top-border technician-experience-footer-cutout-top-border-right" />
          </div>
        </div>
      </div>
      <div
        data-testid="tech-app-bottom-nav"
        className=" pointer-events-none fixed inset-x-0 bottom-0 z-[1004]"
        style={{
          height: `${TECH_APP_NAV_BAR_HEIGHT}px`,
          minHeight: `${TECH_APP_NAV_BAR_HEIGHT}px`,
        }}
      >
        <div className="technician-experience-footer text-bz-text-secondary">
          <div
            className="mx-auto h-full"
            style={{ maxWidth: `${contentWidth}px` }}
          >
            <div
              className={classNames(
                'flex h-full w-full min-w-full flex-row items-start justify-between pt-1',
                {
                  'px-4': isMobile,
                },
              )}
            >
              {isCompanyUser ? (
                <>
                  <NavButton
                    icon={faHome}
                    activeIcon={faHomeSolid}
                    path={TechnicianReactRoutes.HOME.path}
                    testId="my-appointments"
                  />
                  <NavButton
                    icon={faCalendar}
                    activeIcon={faCalendarSolid}
                    path={TechnicianReactRoutes.SCHEDULE.path}
                  />
                  {/* This is not a real button. It will be hidden by the cutout. This is to make the alignment work */}
                  <NavButton />
                  {canViewAccountSearch ? (
                    <NavButton
                      icon={faSearch}
                      activeIcon={faSearchSolid}
                      path={TechnicianReactRoutes.ACCOUNT_SEARCH.path}
                      highlightWhenActive={false}
                    />
                  ) : (
                    <NavButton
                      icon={faQuestionCircle}
                      activeIcon={faSearchSolid}
                      onClick={openIntercomHelp}
                    />
                  )}
                </>
              ) : (
                <>
                  <NavButton />
                  <NavButton />
                  <NavButton />
                  <NavButton />
                </>
              )}
              <NavButton
                icon={faEllipsis}
                onClick={openMore}
                className="pointer-events-auto"
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            'absolute left-1/2 top-[-60px] translate-x-[-50%] whitespace-nowrap rounded-lg bg-bz-primary px-3 py-2 text-white shadow-secondary transition-opacity duration-300 ease-in-out',
            newActionsToastOpen ? 'opacity-100' : 'opacity-0',
          )}
        >
          {actionName} actions available
        </div>

        {moreOpen && <MoreMenu onClose={closeMore} />}
      </div>
      <div
        className="fixed inset-x-0 bottom-0 z-[1002]"
        style={{
          height: `${TECH_APP_NAV_BAR_HEIGHT}px`,
          minHeight: `${TECH_APP_NAV_BAR_HEIGHT}px`,
        }}
      >
        <div
          className={classNames(
            'absolute left-1/2 top-[-10px] h-[230px] w-[230px] translate-x-[-50%] transition-all duration-500 ease-in-out',
            showContextualActionState ? 'rotate-90' : 'rotate-0',
          )}
        >
          <Button
            data-testid="global-action-button"
            disabled={!isCompanyUser}
            icon={<FontAwesomeIcon icon={faAdd} className="h-6 w-6" />}
            shape="circle"
            type="primary"
            className="global-action-button absolute left-1/2 top-0 h-14 w-14 translate-x-[-50%] text-2xl"
            onClick={openActionsMenu}
          />
          <Button
            data-testid="tech-app-bottom-nav-contextual-action-button"
            disabled={!isCompanyUser}
            icon={
              <FontAwesomeIcon icon={faBolt} className="h-[22px] w-[22px]" />
            }
            shape="circle"
            type="primary"
            className="global-action-button absolute left-0 top-1/2 h-14 w-14 translate-y-[-50%] rotate-90 text-2xl"
            onClick={openActionsMenu}
          />
        </div>
      </div>
    </>
  )
})

export default TechnicianAppBottomNavBar
