import { twilioPhoneNumberToBreezyPhone } from '@breezy/shared'
import { RecentPhoneCall } from './CommsPage.gql'

export const getAvatarText = (contact?: {
  firstName: string
  lastName: string
}): string => {
  if (!contact) return '?'
  return `${contact.firstName[0]}${contact.lastName[0]}`.toUpperCase()
}

export const getDisplayNameLongFormat = (
  contact: { fullName?: string } | undefined,
  phoneNumber: string,
): string => {
  return contact?.fullName
    ? `${contact.fullName} - ${phoneNumber}`
    : phoneNumber
}

export const getDisplayNameShortFormat = (
  contact: { fullName?: string } | undefined,
  phoneNumber: string,
): string => {
  return contact?.fullName ?? phoneNumber
}

export const getBreezyFormatExternalPhoneNumber = (call: {
  direction: string
  fromPhoneNumber: string
  toPhoneNumber: string
}): string => {
  return call.direction === 'inbound'
    ? twilioPhoneNumberToBreezyPhone(call.fromPhoneNumber)
    : twilioPhoneNumberToBreezyPhone(call.toPhoneNumber)
}

export const getUserDisplayName = (user?: {
  firstName: string
  lastName: string
}): string => {
  if (!user) return 'You'
  return `${user.firstName} ${user.lastName}`
}

export const getUserAvatarText = (user?: {
  firstName: string
  lastName: string
}): string => {
  if (!user) return '?'
  return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
}

export const getFormattedDate = (startDate: Date, today: Date) => {
  const isToday = startDate.toDateString() === today.toDateString()

  return isToday
    ? `Today, ${startDate.toLocaleTimeString([], {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}`
    : startDate.toLocaleString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
}

export const getContactMethodId = (call: RecentPhoneCall) => {
  return (
    call.contact?.contactGuid ??
    (call.direction === 'inbound' ? call.fromPhoneNumber : call.toPhoneNumber)
  )
}
