import { JobClass } from '@breezy/shared'
import classNames from 'classnames'
import React from 'react'
import { getColorForJobClass } from '../utils/job-utils'

type JobClassAccentBadgeProps = {
  jobClass: JobClass
  className?: string
}

export const JobClassAccentBadge = React.memo<JobClassAccentBadgeProps>(
  ({ jobClass, className }) => (
    <div
      className={classNames(
        'flex h-[18px] flex-row overflow-hidden rounded-l *:h-full *:w-1',
        className,
      )}
    >
      <div className={getColorForJobClass(jobClass).ribbon} />
      <div className={getColorForJobClass(jobClass).background} />
    </div>
  ),
)
