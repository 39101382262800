import { externalCallId } from '@breezy/shared'
import { useQuery } from 'urql'
import { FETCH_INTEGRATED_PHONE_CALL_GUID_BY_EXTERNAL_CALL_ID_QUERY } from './IntegratedPhoneCallGuidByExternalCallId.gql'

export const useFetchIntegratedPhoneCallGuidByExternalCallId = (
  twilioCallSid: string | undefined,
) => {
  const externalId = externalCallId('Twilio', twilioCallSid ?? '')

  const [{ data }] = useQuery({
    query: FETCH_INTEGRATED_PHONE_CALL_GUID_BY_EXTERNAL_CALL_ID_QUERY,
    variables: { externalCallId: externalId ?? '' },
    pause: !twilioCallSid,
  })

  return data?.integratedPhoneCalls[0]?.integratedPhoneCallGuid
}
