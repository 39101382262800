import { JobClass } from '@breezy/shared'
import tailwindConfig from '../tailwind.config'

export type EventColorConfig = {
  ribbon: string
  completedRibbon: string
  background: string
  eventColor: string
  mapPinColor: string
}

export const JOB_CLASS_COLOR_MAP = {
  SERVICE: {
    ribbon: 'bg-bz-cyan-600',
    completedRibbon: 'bg-bz-cyan-400',
    background: 'bg-bz-cyan-200',
    eventColor: tailwindConfig.theme.extend.colors['bz-cyan'][200],
    mapPinColor: tailwindConfig.theme.extend.colors['bz-cyan'][600],
  },
  MAINTENANCE: {
    ribbon: 'bg-geek-blue-600',
    completedRibbon: 'bg-geek-blue-400',
    background: 'bg-geek-blue-200',
    eventColor: tailwindConfig.theme.extend.colors['geek-blue'][200],
    mapPinColor: tailwindConfig.theme.extend.colors['geek-blue'][600],
  },
  INSTALL: {
    ribbon: 'bg-bz-magenta-600',
    completedRibbon: 'bg-bz-magenta-400',
    background: 'bg-bz-magenta-200',
    eventColor: tailwindConfig.theme.extend.colors['bz-magenta'][200],
    mapPinColor: tailwindConfig.theme.extend.colors['bz-magenta'][600],
  },
  SALES: {
    ribbon: 'bg-bz-volcano-600',
    completedRibbon: 'bg-bz-volcano-400',
    background: 'bg-bz-volcano-200',
    eventColor: tailwindConfig.theme.extend.colors['bz-volcano'][200],
    mapPinColor: tailwindConfig.theme.extend.colors['bz-volcano'][600],
  },
} satisfies Partial<Record<JobClass, EventColorConfig>>

export const DEFAULT_EVENT_COLOR_CONFIG = {
  ribbon: 'bg-[#8c8c8c]',
  completedRibbon: 'bg-bz-text-quaternary',
  background: 'bg-[#d9d9d9]',
  eventColor: '#d9d9d9',
  mapPinColor: '#d9d9d9',
} satisfies EventColorConfig

export const getColorForJobClass = (jobClass: JobClass) =>
  JOB_CLASS_COLOR_MAP[jobClass as keyof typeof JOB_CLASS_COLOR_MAP] ??
  DEFAULT_EVENT_COLOR_CONFIG
