import { gql } from 'src/generated'

export const GQL_USER_SETTINGS_QUERY = gql(/* GraphQL */ `
  query FetchUserSettings($companyGuid: uuid!, $userGuid: uuid!) {
    userSettings(
      where: {
        companyGuid: { _eq: $companyGuid }
        _and: { userGuid: { _eq: $userGuid } }
      }
    ) {
      preferredMapApp
    }
  }
`)

export const GQL_USER_SETTINGS_SET_MAP_PREFERENCE = gql(/* GraphQL */ `
  mutation UpsertUserSettingMapPreference($obj: UserSettingsInsertInput!) {
    insertUserSettingsOne(
      object: $obj
      onConflict: {
        constraint: user_settings_pkey
        updateColumns: [preferredMapApp, updatedAt]
      }
    ) {
      __typename
    }
  }
`)
