import React, { useEffect, useMemo, useState } from 'react'
import { useIsTechApp } from '../providers/AppContextWrapper'
import { useStrictContext } from '../utils/react-utils'

const TINY_BREAKPOINT = 375
const MOBILE_BREAKPOINT = 767
const SMALL_SCREEN_BREAKPOINT = 1023
const MEDIUM_SCREEN_BREAKPOINT = 1279
const REAL_LARGE_SCREEN_BREAKPOINT = 1439

type ResizeListenerContextType = {
  windowWidth: number
  windowHeight: number
}

const ResizeListenerContext = React.createContext<
  ResizeListenerContextType | undefined
>(undefined)

type ResizeListenerContextWrapperProps = React.PropsWithChildren

export const BreakpointContextWrapper =
  React.memo<ResizeListenerContextWrapperProps>(({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
      }

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    return (
      <ResizeListenerContext.Provider value={{ windowWidth, windowHeight }}>
        {children}
      </ResizeListenerContext.Provider>
    )
  })

export const useWindowDimensions = () => {
  return useStrictContext(ResizeListenerContext)
}

const useBreakpoint = (breakpoint: number) => {
  const { windowWidth } = useWindowDimensions()
  return windowWidth <= breakpoint
}

export const useIsMobile = () => useBreakpoint(MOBILE_BREAKPOINT)

export const useIsTinyScreen = () => useBreakpoint(TINY_BREAKPOINT)
export const useIsSmallScreen = () => useBreakpoint(SMALL_SCREEN_BREAKPOINT)
export const useIsDesktop = () => !useBreakpoint(SMALL_SCREEN_BREAKPOINT)
export const useIsLargeScreen = () => !useBreakpoint(MEDIUM_SCREEN_BREAKPOINT)
export const useIsRealLargeScreen = () =>
  !useBreakpoint(REAL_LARGE_SCREEN_BREAKPOINT)
export const useIsMediumScreen = () => useBreakpoint(MEDIUM_SCREEN_BREAKPOINT)

export const useIsTouchScreen = () =>
  useMemo(() => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0
  }, [])

export const useHasMouse = () => {
  return useMemo(() => window.matchMedia('(pointer: fine)').matches, [])
}

export const useIsMobileOrTouchOrTechApp = () => {
  const isSmallScreen = useIsSmallScreen()
  const isTouchScreen = useIsTouchScreen()
  const isTechApp = useIsTechApp()
  return useMemo(
    () => isSmallScreen || isTouchScreen || isTechApp,
    [isSmallScreen, isTouchScreen, isTechApp],
  )
}

export default useIsMobile
