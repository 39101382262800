import { Guid, NotNullish } from '@breezy/shared'
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { PricebookItemFragment } from '../generated/user/graphql'
import { useStrictContext } from '../utils/react-utils'
import {
  GET_PRICEBOOK_DATA,
  GET_RECENT_PRICEBOOK_ITEMS,
} from './PricebookProvider.gql'
import { useExpectedPrincipal } from './PrincipalUser'

export type PricebookPickerItem = {
  id: Guid
  name: string
  value: number
  ignoreSearch?: boolean
} & (
  | { cdnUrl?: string; icon?: never }
  | { cdnUrl?: never; icon?: React.ReactNode }
)

export type PricebookPickerCategory<
  T extends PricebookPickerItem = PricebookPickerItem,
> = {
  id: Guid
  name: string
  items: (T | PricebookPickerCategory<T>)[]
} & (
  | { cdnUrl?: string; icon?: never }
  | { cdnUrl?: never; icon?: React.ReactNode }
)

export type PricebookItems = (PricebookPickerItem | PricebookPickerCategory)[]

type PricebookProviderContextType = {
  items: PricebookItems
  rawItems: PricebookItemFragment[]
  recentItems: PricebookItems
  loaded: boolean
  refetchRecentItems: () => void
}

const PricebookProviderContext = React.createContext<
  PricebookProviderContextType | undefined
>(undefined)

const lowPriorityFetch: typeof fetch = (url, opts) =>
  fetch(url, { ...opts, priority: 'low' })

const queryContext = {
  fetch: lowPriorityFetch,
}

type PricebookProviderProps = React.PropsWithChildren

export const PricebookProvider = React.memo<PricebookProviderProps>(
  ({ children }) => {
    const principal = useExpectedPrincipal()

    const [loaded, setLoaded] = useState(false)

    const [{ data }] = useQuery({
      query: GET_PRICEBOOK_DATA,
      pause: !principal.company || !principal.userGuid,
      context: queryContext,
    })

    const [{ data: recentItemsData }, refetchRecentItems] = useQuery({
      query: GET_RECENT_PRICEBOOK_ITEMS,
      variables: {
        userGuid: principal.userGuid,
        limit: 25,
      },
      context: queryContext,
    })

    useEffect(() => {
      if (data) {
        setLoaded(true)
      }
    }, [data])

    const rawItems = useMemo(() => {
      const rawItems = [...(data?.uncategorizedItems ?? [])]

      for (const category of data?.pricebookCategories ?? []) {
        rawItems.push(...category.pricebookItems)
      }

      return rawItems
    }, [data?.pricebookCategories, data?.uncategorizedItems])

    const recentItems = useMemo(() => {
      const recentItemGuids = new Set(
        recentItemsData?.recentPricebookItems.map(
          item => item.pricebookItemGuid,
        ) ?? [],
      )

      const recentItems: PricebookPickerItem[] = rawItems
        .filter(item => recentItemGuids.has(item.pricebookItemGuid))
        .map(item => ({
          id: item.pricebookItemGuid,
          name: item.name,
          value: item.priceUsd,
          cdnUrl: item.photo?.cdnUrl,
          ignoreSearch: true,
        }))

      const recentCategory: PricebookPickerCategory = {
        id: 'recent',
        name: 'Recent Line Items',
        items: recentItems,
        icon: (
          <div className="relative mr-3 flex h-8 w-8 items-center justify-center overflow-hidden rounded border border-solid border-bz-purple-600 bg-purple-100 p-2">
            <FontAwesomeIcon
              className="text-purple-600"
              icon={faClockRotateLeft}
            />
          </div>
        ),
      }
      return [recentCategory]
    }, [recentItemsData?.recentPricebookItems, rawItems])

    const items = useMemo<PricebookItems>(() => {
      type Datum = NotNullish<typeof data>['pricebookCategories'][number]
      const parentGuidToChildMap: Record<string, Datum[]> = {}

      const rootItems: Datum[] = []

      for (const datum of data?.pricebookCategories ?? []) {
        if (datum.parentCategoryGuid) {
          const list = parentGuidToChildMap[datum.parentCategoryGuid] || []
          list.push(datum)
          parentGuidToChildMap[datum.parentCategoryGuid] = list
        } else {
          rootItems.push(datum)
        }
      }

      const populateChildren = (
        categories: Datum[],
      ): PricebookPickerCategory[] =>
        categories.map(category => {
          const ourItems: PricebookPickerItem[] = category.pricebookItems.map(
            item => ({
              id: item.pricebookItemGuid,
              name: item.name,
              value: item.priceUsd,
              cdnUrl: item.photo?.cdnUrl,
            }),
          )
          const childCategoryItems: PricebookPickerCategory[] =
            populateChildren(
              parentGuidToChildMap[category.pricebookCategoryGuid] ?? [],
            )

          return {
            id: category.pricebookCategoryGuid,
            name: category.name,
            cdnUrl: category.photo?.cdnUrl,
            items: [...ourItems, ...childCategoryItems],
          }
        })

      const categories = populateChildren(rootItems)

      const uncategorizedItems =
        data?.uncategorizedItems.map(item => ({
          id: item.pricebookItemGuid,
          name: item.name,
          value: item.priceUsd,
          cdnUrl: item.photo?.cdnUrl,
        })) ?? []

      return [...recentItems, ...categories, ...uncategorizedItems]
    }, [data?.pricebookCategories, data?.uncategorizedItems, recentItems])

    return (
      <PricebookProviderContext.Provider
        value={{ items, rawItems, recentItems, loaded, refetchRecentItems }}
      >
        {children}
      </PricebookProviderContext.Provider>
    )
  },
)

export const usePricebook = () => useStrictContext(PricebookProviderContext)
