import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'

export type BzCloseButtonType = 'default' | 'outline'

type BzCloseButtonProps = {
  readonly onClose: () => void
  readonly onBack?: () => void
  readonly disabled?: boolean
  readonly dataTestId?: string
  readonly type?: BzCloseButtonType
}

export const BzCloseButton = ({
  onClose,
  onBack,
  disabled,
  dataTestId,
  type = 'default',
}: BzCloseButtonProps) => {
  return (
    <Button
      data-testid={dataTestId ?? 'modal-close-button'}
      className={classNames(
        'z-10 flex h-10 w-10 items-center justify-center text-[16px]',
        type === 'default' &&
          'border-color-[#d8d8d8] text-[#4f4f4f] shadow-none hover:border-[#1890FF] hover:bg-[#E6F7FF] hover:text-[#1890FF]',
        type === 'outline' &&
          'border-color-bz-gray-100 hover:border-color-[#1890FF] border border-solid bg-transparent text-bz-gray-100 hover:text-[#1890FF]',
      )}
      shape="circle"
      disabled={disabled}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        ;(onBack ?? onClose)?.()
      }}
    >
      <FontAwesomeIcon icon={onBack ? faChevronLeft : faXmark} />
    </Button>
  )
}
