import { faCheck, faX } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from 'antd'
import React, { useMemo } from 'react'
import { useMutation, useQuery } from 'urql'
import { Card } from '../../adam-components/Card/Card'
import { gql } from '../../generated'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'

const GQL_USER_SETTINGS_QUERY = gql(/* GraphQL */ `
  query UserSettingsQuery($companyGuid: uuid!, $userGuid: uuid!) {
    userSettings(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
      }
    ) {
      hideCallerIdEnabled
    }
  }
`)

export const useHideCallerIdEnabled = () => {
  const companyGuid = useExpectedCompanyGuid()
  const userGuid = useExpectedUserGuid()
  const [{ data, fetching: loading }, refetch] = useQuery({
    query: GQL_USER_SETTINGS_QUERY,
    variables: { companyGuid, userGuid },
  })

  const hideCallerIdEnabled = useMemo(
    () => data?.userSettings[0]?.hideCallerIdEnabled ?? false,
    [data],
  )

  return { hideCallerIdEnabled, refetch, loading }
}

const GQL_UPDATE_USER_SETTINGS_MUTATION = gql(/* GraphQL */ `
  mutation UpdateUserSettings(
    $companyGuid: uuid!
    $userGuid: uuid!
    $hideCallerIdEnabled: Boolean!
  ) {
    insertUserSettingsOne(
      object: {
        companyGuid: $companyGuid
        userGuid: $userGuid
        hideCallerIdEnabled: $hideCallerIdEnabled
      }
      onConflict: {
        constraint: user_settings_pkey
        updateColumns: [hideCallerIdEnabled]
      }
    ) {
      __typename
    }
  }
`)
export const HideCallerIdPreferencePicker = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  const userGuid = useExpectedUserGuid()
  const message = useMessage()
  const {
    hideCallerIdEnabled,
    loading: hideCallerIdLoading,
    refetch,
  } = useHideCallerIdEnabled()
  const [{ fetching: updatingUserSettings }, updateUserSettingsMutation] =
    useMutation(GQL_UPDATE_USER_SETTINGS_MUTATION)

  const onToggleChange = useMemo(
    () => async (checked: boolean) => {
      await updateUserSettingsMutation(
        {
          companyGuid,
          userGuid,
          hideCallerIdEnabled: checked,
        },
        {
          optimistic: true,
          additionalTypenames: ['UserSettings'],
        },
      )
      await refetch()
      message.success('Hide Caller ID preference updated')
    },
    [updateUserSettingsMutation, companyGuid, userGuid, message, refetch],
  )

  const loading = hideCallerIdLoading || updatingUserSettings

  return (
    <div className="flex flex-col gap-1">
      <div className="text-sm font-semibold leading-[22px]">
        Communication Settings
      </div>
      <Card>
        <div className="flex flex-row  justify-between gap-3">
          <div className="flex flex-col gap-[2px] text-sm leading-[22px]">
            <div className="font-semibold">
              Hide Caller ID on Outgoing Calls
            </div>
            <div className="text-sm  leading-5">
              The customer will receive a reminder message the day before their
              visit.
            </div>
          </div>
          <Switch
            checked={hideCallerIdEnabled}
            loading={loading}
            onChange={onToggleChange}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faX} />}
          />
        </div>
      </Card>
    </div>
  )
})
