import { z } from 'zod'

export const REVIEW_TYPES = ['GOOGLE', 'YELP', 'FACEBOOK', 'ANGI'] as const
export type ReviewType = (typeof REVIEW_TYPES)[number]
export const ReviewTypeSchema = z.enum(REVIEW_TYPES)

export const ReviewTypeMetadata = {
  GOOGLE: {
    displayName: 'Google Business Profile',
    reviewLinkDisplayName: 'Google',
    fullReviewLinkDisplayName: 'Google Business Profile',
    logoUrl: 'https://breezy-prod-files.s3.amazonaws.com/assets/google_logo.svg',
    configId: 'googlePlaceId',
    reviewLinkFn: (placeId: string) => `https://search.google.com/local/writereview?placeid=${placeId}`,
  },
  YELP: {
    displayName: 'Yelp',
    reviewLinkDisplayName: 'Yelp',
    fullReviewLinkDisplayName: 'Yelp Business Listing',
    logoUrl: 'https://breezy-prod-files.s3.amazonaws.com/assets/yelp_logo.svg',
    configId: 'yelpId',
    reviewLinkFn: (id: string) => `https://www.yelp.com/writeareview/biz/${id}`,
  },
  FACEBOOK: {
    displayName: 'Facebook',
    reviewLinkDisplayName: 'Facebook',
    fullReviewLinkDisplayName: 'Facebook Business Page',
    logoUrl: 'https://breezy-prod-files.s3.amazonaws.com/assets/facebook_logo.svg',
    configId: 'facebookId',
    reviewLinkFn: (id: string) => `https://www.facebook.com/${id}/reviews`,
  },
  ANGI: {
    displayName: 'Angi',
    reviewLinkDisplayName: `Angi's`,
    fullReviewLinkDisplayName: 'Angi Business Profile',
    logoUrl: 'https://breezy-prod-files.s3.amazonaws.com/assets/angi_logo.svg',
    configId: 'angiId',
    reviewLinkFn: (id: string) => {
      const spid = id.split('-').pop()
      return `https://angi.com/review/${spid}`
    },
  },
} as const satisfies Record<
  ReviewType,
  {
    displayName: string
    reviewLinkDisplayName: string
    fullReviewLinkDisplayName: string
    logoUrl: string
    configId: 'googlePlaceId' | 'yelpId' | 'facebookId' | 'angiId'
    reviewLinkFn: (id: string) => string
  }
>

export type ReviewTypeIdMap = {
  [key in ReviewType]: string | undefined
}

export type ReviewIdConfig = {
  angiId?: string
  facebookId?: string
  googlePlaceId?: string
  yelpId?: string
}

export const convertQueryToReviewTypeIdMap = (companyConfig: ReviewIdConfig | undefined): ReviewTypeIdMap => {
  return {
    ANGI: companyConfig?.angiId,
    FACEBOOK: companyConfig?.facebookId,
    GOOGLE: companyConfig?.googlePlaceId,
    YELP: companyConfig?.yelpId,
  }
}
