import { z } from 'zod'
import { AsyncFn, IsoDateString, formatUsc } from '../../../common'
import { guidSchema, isoDateStringSchema, uscSchema } from '../../../contracts'
import { AccountGuid } from '../../Accounts/Account'
import { CompanyGuid, CompanyGuidContainer, ForCompany } from '../../Company/Company'
import { JobGuid } from '../../Job'
import { telephoneNumberSchema } from '../../PhoneNumbers/Phone'
import { Guid, bzOptional } from '../../common-schemas'
import { ContactGuid } from '../../contacts/Contact'
import { WithMerchantId } from './Lending'

export type AprPercentage = `${number}.${number}${number}%`
export type PrequalStatus = 'PENDING' | 'INITIATED' | 'PREQUALIFIED' | 'DECLINED' | 'EXPIRED' | 'CANCELED'
export const DECLINEABLE_PREQUAL_STATUSES = ['INITIATED', 'PENDING']

export type PrequalRecord = {
  prequalRecordGuid: Guid
  companyGuid: CompanyGuid
  accountGuid: AccountGuid
  contactGuid: ContactGuid
  jobGuid?: JobGuid
  // wisetack merchant guid
  merchantGuid: Guid
  // the prequal guid from the external system
  externalPrequalGuid: Guid
  prequalifiedAmountUsc?: number
  maxQualifiedAmountUsc?: number
  aprRange?: string

  status: PrequalStatus
  expirationDate?: IsoDateString
  prequalifiedAt?: IsoDateString
  declinedAt?: IsoDateString
  expiredAt?: IsoDateString
  createdAt: IsoDateString
  updatedAt: IsoDateString

  prequalLink: string
}

export type PrequalRecordWithContactName = PrequalRecord & {
  contactFullName: string
}

export type PrequalRecordMinimal = {
  name: string
  status: PrequalStatus
  maxAmountUsc: number
}

export type PrequalRecordQuerierRequest =
  | {
      type: 'read-by-prequal-record-guid'
      data: CompanyGuidContainer & {
        prequalRecordGuid: Guid
      }
    }
  | {
      type: 'read-by-guids'
      data: {
        accountGuid: Guid
        companyGuid: Guid
        contactGuid: Guid
        jobGuid?: Guid
      }
    }
  | {
      type: 'read-for-job'
      data: ForCompany<{
        jobGuid: JobGuid
      }>
    }
  | {
      type: 'read-for-account'
      data: {
        accountGuid: AccountGuid
      }
    }
  | {
      type: 'read-by-external-prequal-guid'
      data: {
        externalPrequalGuid: Guid
      }
    }

export type PrequalRecordQuerier = AsyncFn<PrequalRecordQuerierRequest, PrequalRecord[]>

export const InitiatePrequalRequestSchema = z.object({
  companyGuid: guidSchema,
  accountGuid: guidSchema,
  contactGuid: guidSchema,
  jobGuid: bzOptional(guidSchema),
})

export type InitiatePrequalRequest = z.infer<typeof InitiatePrequalRequestSchema>

export type PrequalInitiator = AsyncFn<InitiatePrequalRequest, PrequalRecord>

export const GetJobPrequalRecordsSchema = z.object({
  jobGuid: guidSchema,
})

export type GetJobPrequalRecordsRequest = z.infer<typeof GetJobPrequalRecordsSchema>

export const CreatePrequalRecordRequestSchema = InitiatePrequalRequestSchema.extend({
  merchantGuid: guidSchema,
  externalPrequalGuid: guidSchema,
  prequalLink: z.string(),
})

export type CreatePrequalRecordRequest = z.infer<typeof CreatePrequalRecordRequestSchema>

export type PrequalRecordWriterRequest =
  | {
      type: 'create'
      data: CreatePrequalRecordRequest
    }
  | {
      type: 'update'
      data: PrequalWebhook
    }

export type PrequalRecordWriter = AsyncFn<PrequalRecordWriterRequest, { prequalRecordGuid: Guid }>

export type ExternalInitiatePrequalRequest = WithMerchantId<{
  estimateGuid?: Guid
  jobGuid?: Guid
}>

export type ExternalInitiatePrequalResponse = {
  externalPrequalId: Guid
  prequalLink: string
}

export type ExternalPrequalInitiator = AsyncFn<ExternalInitiatePrequalRequest, ExternalInitiatePrequalResponse>

export const GetAccountPrequalRecordsSchema = z.object({
  accountGuid: bzOptional(guidSchema),
})

export type GetAccountPrequalRecordsRequest = z.infer<typeof GetAccountPrequalRecordsSchema>

export const PrequalStatusDisplayNames: Record<PrequalStatus, string> = {
  PENDING: 'Pending',
  INITIATED: 'Initiated',
  PREQUALIFIED: 'Prequalified',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
  CANCELED: 'Canceled',
}

export const formatPrequalAmount = (prequalRecord: Pick<PrequalRecord, 'status' | 'maxQualifiedAmountUsc'>) => {
  const status = prequalRecord.status
  const amount = prequalRecord.maxQualifiedAmountUsc ?? 0

  switch (status) {
    case 'PREQUALIFIED':
    case 'EXPIRED':
      return formatUsc(amount)
    default:
      return '—'
  }
}
const PrequalStatusSchema = z.union([
  z.literal('PENDING'),
  z.literal('INITIATED'),
  z.literal('PREQUALIFIED'),
  z.literal('DECLINED'),
  z.literal('EXPIRED'),
])
const PrequalWebhookSchema = z.object({
  prequalGuid: guidSchema,
  merchantGuid: guidSchema,
  maxQualifiedAmountUsc: bzOptional(uscSchema),
  aprRange: bzOptional(z.string()),
  updatedAt: isoDateStringSchema,
  status: PrequalStatusSchema,
  prequalifiedAt: bzOptional(isoDateStringSchema),
  declinedAt: bzOptional(isoDateStringSchema),
  expiredAt: bzOptional(isoDateStringSchema),
})

export type PrequalWebhook = z.infer<typeof PrequalWebhookSchema>

export type PrequalWebhookConsumer = AsyncFn<PrequalWebhook>

export const InitiatePrequalRequestUnauthorizedSchema = z.object({
  accountGuid: guidSchema,
  contactGuid: guidSchema,
  jobGuid: bzOptional(guidSchema),
  phoneNumber: telephoneNumberSchema,
})

export type InitiatePrequalRequestUnauthorized = z.infer<typeof InitiatePrequalRequestUnauthorizedSchema>

export type PrequalInitiatorUnauthorized = AsyncFn<InitiatePrequalRequestUnauthorized>
