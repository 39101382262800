import { useMemo } from 'react'
import { FETCH_COMPANY_PHONE_SETTINGS } from '../components/PhoneIntegrated/CompanyPhoneSettings.gql'
import { usePrincipalUser } from '../providers/PrincipalUser'
import { useQueryMaxAttempts } from './useQueryMaxAttempts'

import { useCallback } from 'react'

export interface CompanyPhoneSettings {
  integratedPhoneEnabled: boolean
  phoneNumbers: {
    phoneNumber: string
    leadSourceName?: string
  }[]
}

export const useCompanyPhoneSettings = (): {
  settings: CompanyPhoneSettings | null
  isLoading: boolean
  error: Error | null
  refetch: () => void
} => {
  const { companyGuid } = usePrincipalUser()?.principal?.company ?? {}

  const [{ data, fetching, error }, refetch] = useQueryMaxAttempts({
    query: FETCH_COMPANY_PHONE_SETTINGS,
    pause: !companyGuid,
    variables: { companyGuid: companyGuid ?? '' },
    requestPolicy: 'network-only',
  })

  const settings = useMemo(() => {
    if (!data?.integratedPhoneCompanyConfig[0]) return null

    const config = data.integratedPhoneCompanyConfig[0]
    return {
      integratedPhoneEnabled: config.integratedPhoneEnabled,
      phoneNumbers: config.company.integratedPhones.map(phone => ({
        phoneNumber: phone.phoneNumber,
        leadSourceName:
          phone.companyLeadSource?.canonicalLeadSourceNameOverride ??
          phone.companyLeadSource?.canonicalLeadSourceName,
      })),
    }
  }, [data])

  const refetchSettings = useCallback(() => {
    if (companyGuid) {
      refetch({ requestPolicy: 'network-only' })
    }
  }, [companyGuid, refetch])

  return {
    settings,
    isLoading: fetching,
    error: error ? new Error(error.message) : null,
    refetch: refetchSettings,
  }
}

/**
 * Whether the company has enabled integrated phone functionality in their
 * company-specific settings.
 */
export const useCompanyPhoneEnabled = (): boolean => {
  const { settings } = useCompanyPhoneSettings()
  return !!settings?.integratedPhoneEnabled && !!settings?.phoneNumbers?.length
}
