import { z } from 'zod'
import { BzDateFns, IsoDateString } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { bzOptional } from '../common-schemas'

// These are the different number of days that we can have for our reminder frequency, with "-1" being "don't send"
export const REMINDER_FREQUENCY_DAY_COUNTS = [3, 5, 7, 10, -1] as const

// Literal type of all those values
type ReminderFrequencyDayCount = (typeof REMINDER_FREQUENCY_DAY_COUNTS)[number]
// We want the values in the DB to be specific strings like "AFTER_5_DAYS", but "DO_NOT_SEND" for -1
type FrequencyDayCountToValueType<T extends number> = T extends -1 ? 'DO_NOT_SEND' : `AFTER_${T}_DAYS`

// From a count, give us the right string
const frequencyDayCountToValue = <T extends ReminderFrequencyDayCount>(count: T): FrequencyDayCountToValueType<T> =>
  (count === -1 ? 'DO_NOT_SEND' : `AFTER_${count}_DAYS`) as FrequencyDayCountToValueType<T>

// `.map` always gives an array in TypeScript. We want a tuple to come out (we want ["AFTER_3_DAYS", "AFTER_5_DAYS",
// ...] that matches the order of REMINDER_FREQUENCY_DAY_COUNTS or else zod won't be happy). We can use this TypeScript
// voodoo to accomplish that.
const mapToFreqValues = <T extends ReminderFrequencyDayCount[]>(
  arr: readonly [...T],
): { [K in keyof T]: FrequencyDayCountToValueType<T[K]> } =>
  arr.map(frequencyDayCountToValue) as { [K in keyof T]: FrequencyDayCountToValueType<T[K]> }

export const REMINDER_FREQUENCIES = mapToFreqValues(REMINDER_FREQUENCY_DAY_COUNTS)

export type ReminderFrequency = (typeof REMINDER_FREQUENCIES)[number]

// Given one of those keys, get a nice label and get the count number back out.
export const REMINDER_FREQUENCY_METADATA = REMINDER_FREQUENCY_DAY_COUNTS.reduce(
  (map, count) => ({
    ...map,
    [frequencyDayCountToValue(count)]: { label: count === -1 ? "Don't Send" : `After ${count} days`, count },
  }),
  {} as Record<
    ReminderFrequency,
    {
      label: string
      count: number
    }
  >,
)

// NOTE: make sure everything is optional. We aren't guaranteed a row, hence the defaults specified. You can potentially
// bork some things if you expect fields that aren't there.
export const notificationSettingsSchema = z.object({
  // Reply To
  replyToEmail: bzOptional(z.string().email('Please enter a valid email address')),

  // Accepted Estimates
  estimateAcceptNotifyCreator: bzOptional(z.boolean()).default(true),
  estimateAcceptNotifyUserGuids: bzOptional(z.array(guidSchema).min(1, 'Please include at least one user')),
  estimateAcceptNotifyEmail: bzOptional(z.string().email('Please enter a valid email address')),

  // Viewed Estimates
  estimateReviewedNotifyCreator: bzOptional(z.boolean()).default(true),
  estimateReviewedNotifyUserGuids: bzOptional(z.array(guidSchema).min(1, 'Please include at least one user')),

  // Estimate Reminder
  estimateReminderFrequency: bzOptional(z.enum(REMINDER_FREQUENCIES)).default('AFTER_5_DAYS'),
  estimateReminderCount: bzOptional(z.number().int().min(1).max(3)).default(1),

  // Payment Reminder
  invoicePaymentReminderFrequency: bzOptional(z.enum(REMINDER_FREQUENCIES)).default('AFTER_5_DAYS'),
  invoicePaymentReminderCount: bzOptional(z.number().int().min(1).max(3)).default(1),

  // Failed Payment
  paymentFailedNotifyCreator: bzOptional(z.boolean()).default(true),
  paymentFailedNotifyUserGuids: bzOptional(z.array(guidSchema).min(1, 'Please include at least one user')),
  paymentFailedNotifyEmail: bzOptional(z.string().email('Please enter a valid email address')),

  // Material Restock Report
  materialRestockReportUserGuids: bzOptional(z.array(guidSchema)),
  materialRestockReportEmail: bzOptional(z.string().email('Please enter a valid email address')),

  // Job Requests
  jobLeadCreatedUserGuids: bzOptional(z.array(guidSchema)),
  jobLeadCreatedEmail: bzOptional(z.string().email('Please enter a valid email address')),
})

export const shouldSendReminder = (
  reminderFrequency: ReminderFrequency,
  remindersSent: number,
  lastSent: IsoDateString,
) => {
  const now = BzDateFns.now(BzDateFns.UTC)

  const lastSentDate = BzDateFns.parseISO(lastSent, BzDateFns.UTC)

  const frequencyDays = REMINDER_FREQUENCY_METADATA[reminderFrequency].count

  // Let's say our frequency is 3 days. If we haven't sent a reminder, our next reminder date is the sent date +
  // three days. If we've sent one reminder, then it's the sent date + 6 days.
  const nextReminderDaysAfter = frequencyDays * (remindersSent + 1)
  const nextReminderDate = BzDateFns.addDays(lastSentDate, nextReminderDaysAfter)

  // If the next reminder date hasn't passed yet, skip
  return !BzDateFns.isBefore(now, nextReminderDate)
}
