import classNames from 'classnames'
import React from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import useIsMobile from '../../hooks/useIsMobile'
import { useModalState } from '../../utils/react-utils'
import { ResizedImage } from '../ResizedImage/ResizedImage'

type PricebookPhotoThumbnailProps = {
  cdnUrl?: string
  noPopup?: boolean
  large?: boolean
}

export const PricebookPhotoThumbnail = React.memo<PricebookPhotoThumbnailProps>(
  ({ cdnUrl, noPopup, large }) => {
    const isMobile = useIsMobile()

    const [fullScreenOpen, openFullScreen, closeFullScreen] = useModalState()

    const [width, height, imageMargin] = large ? [44, 44, 5] : [32, 32, 3]

    const hasPopup = !noPopup && cdnUrl

    return (
      <>
        <div
          className={classNames(
            'relative mr-3 flex items-center justify-center overflow-hidden rounded border border-solid border-bz-gray-500',
            {
              'cursor-pointer': hasPopup,
            },
          )}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
          onClick={e => {
            if (hasPopup) {
              e.preventDefault()
              e.stopPropagation()
              openFullScreen()
            }
          }}
        >
          <ResizedImage
            cdnUrl={cdnUrl}
            width={width}
            height={height}
            imageUpscale={1.5}
            imageMargin={imageMargin}
          />
        </div>
        {fullScreenOpen && (
          <OnsiteModal
            size="full"
            onClose={closeFullScreen}
            className="max-w-screen z-[1011] max-h-screen bg-transparent"
          >
            <OnsiteModalContent onClose={closeFullScreen}>
              <div
                className={classNames(
                  'max-h-full max-w-full text-center',
                  isMobile ? 'p-3' : 'p-6',
                )}
              >
                <img
                  src={cdnUrl}
                  alt={cdnUrl}
                  className="max-h-full max-w-full"
                />
              </div>
            </OnsiteModalContent>
          </OnsiteModal>
        )}
      </>
    )
  },
)
