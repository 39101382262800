import { isNullish, WithPartialKeys } from '@breezy/shared'
import React, { useCallback } from 'react'
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning'
import {
  BaseConfirmationModalProps,
  ConfirmationModal,
  ConfirmationModalProps,
  useConfirmationModal,
  UseConfirmationModalValue,
} from './useConfirmationModal'

export type BaseCloseConfirmModalProps = BaseConfirmationModalProps

export const useCloseConfirmModal = (args?: {
  isDirty?: boolean
  noUnsavedChangesWarning?: boolean
}): UseConfirmationModalValue<BaseCloseConfirmModalProps> => {
  const { isDirty, noUnsavedChangesWarning } = args || {}
  const [withConfirmation, props] = useConfirmationModal()

  useUnsavedChangesWarning(
    !noUnsavedChangesWarning && (isNullish(isDirty) || isDirty),
  )

  const ourWithConfirmation = useCallback(
    (onConfirm: () => void) => {
      if (isNullish(isDirty) || isDirty) {
        withConfirmation(onConfirm)
      } else {
        onConfirm()
      }
    },
    [isDirty, withConfirmation],
  )

  return [ourWithConfirmation, props]
}

type CloseConfirmModalProps = WithPartialKeys<ConfirmationModalProps, 'header'>

export const CloseConfirmModal = React.memo<CloseConfirmModalProps>(
  ({
    children,
    header = 'Are you sure you want to exit?',
    danger = true,
    confirmText = 'Yes, Exit',
    ...rest
  }) => {
    return (
      <ConfirmationModal
        header={header}
        danger={danger}
        confirmText={confirmText}
        {...rest}
      >
        {isNullish(children) ? (
          <>
            You have unsaved changes.
            <br />
            <br />
            Are you sure you want to exit?
          </>
        ) : (
          children
        )}
      </ConfirmationModal>
    )
  },
)
