import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'

export type CardProps = React.PropsWithChildren<{
  noBorder?: boolean
  heightClassName?: string
  dataTestId?: string
  className?: string
  style?: React.CSSProperties
  paddingClassName?: string
  shadowClassName?: string
}>

export const Card = React.memo<CardProps>(
  ({
    className,
    noBorder = false,
    heightClassName = 'h-full',
    dataTestId,
    style,
    children,
    paddingClassName,
    shadowClassName = 'shadow-sm',
  }) => {
    const isMobile = useIsMobile()
    return (
      <div
        className={classNames(
          'relative flex min-h-0 w-full flex-col overflow-hidden border-solid border-bz-gray-500 bg-white transition-all ease-in-out',
          noBorder ? 'border-0' : ['rounded-xl border', shadowClassName],
          heightClassName,
          className,
          paddingClassName ?? (isMobile ? 'p-4' : 'px-6 pb-6 pt-4'),
        )}
        style={style}
        data-testid={dataTestId}
      >
        {children}
      </div>
    )
  },
)
