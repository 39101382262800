import { FeatureFlags, UNAUTH_USER_KEY } from '@breezy/shared'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useCallback, useEffect, useState } from 'react'

export const FEATURE_FLAG_OVERRIDE_PREFIX = 'test-ff-override-'

type FeatureFlagReader = (key: keyof FeatureFlags) => boolean

export const useFeatureFlagReader = (): FeatureFlagReader => {
  const ldFlags = useFlags() as FeatureFlags

  const cookiesMap = getCookiesMap()

  return useCallback<FeatureFlagReader>(
    key => cookiesMap[`${FEATURE_FLAG_OVERRIDE_PREFIX}${key}`] ?? ldFlags[key],
    [cookiesMap, ldFlags],
  )
}

export const useFeatureFlag: FeatureFlagReader = flag => {
  return useFeatureFlagReader()(flag)
}

export const useIsLdLoaded = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const ldClient = useLDClient()
  useEffect(() => {
    ldClient?.waitForInitialization().then(() => setIsLoaded(true))
  }, [ldClient])

  return isLoaded
}

/**
 * This hook allows us to dynamically update the LaunchDarkly context for unauthenticated users to include
 * the companyGuid in the LaunchDarkly context so we can target homeowners of specific companies during
 * feature rollouts.
 */
export const useUnauthLdCompanyContextUpdater = () => {
  const ldClient = useLDClient()
  const [isLdContextUpdated, setIsLdContextUpdated] = useState(false)
  const updateLdContext = useCallback(
    (companyGuid: string) => {
      ldClient?.identify({
        kind: 'multi',
        user: { key: UNAUTH_USER_KEY },
        company: { key: companyGuid },
      })
      setIsLdContextUpdated(true)
    },
    [ldClient],
  )

  return { updateLdContext, isLdContextUpdated }
}

// Thanks ChatGPT
const getCookiesMap = (): Record<string, boolean | undefined> => {
  // Create an empty object to store the cookies
  const cookiesObj: Record<string, boolean | undefined> = {}

  // Access the browser cookies
  const cookiesString = document.cookie

  // Split the cookie string into individual cookies
  const cookiesArray = cookiesString.split('; ')

  // Iterate over the array of cookies and populate the object
  cookiesArray.forEach(cookie => {
    const [name, value] = cookie.split('=')
    cookiesObj[name] = value === 'true'
  })

  return cookiesObj
}
