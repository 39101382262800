import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { Input, InputRef, Select } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { ItemPicker } from '../../components/Pricebook/ItemPicker'
import { BzSelectProps } from '../BzSelect/BzSelect'
import {
  BzSelectMode,
  BzSelectOption,
  BzSelectSize,
  BzSheetSize,
} from '../BzSelect/BzSelectTypes'
import './BzMobileSelect.less'

type BzMobileSingleSelectProps<T> = {
  title: string
  value: T | undefined
  labelOverride?: string
  placeholder?: string
  disabled?: boolean
  options?: BzSelectOption[]
  onChange: (value: T) => void
  size?: BzSelectSize
  sheetSize?: BzSheetSize
  onSearch?: (term: string) => void
  emptyState?: React.ReactNode
  className?: string
  loading?: boolean
  name?: string
  ddActionName?: string
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  hideInput?: boolean
}

const BzMobileSingleSelectInner = <T extends string>({
  title,
  value,
  labelOverride,
  placeholder,
  options = [],
  onChange,
  disabled,
  size = 'large',
  sheetSize = 'full',
  onSearch,
  emptyState,
  className,
  loading = false,
  name,
  ddActionName,
  open: externalOpen,
  onClose: externalOnClose,
  onOpen: externalOnOpen,
  hideInput,
}: BzMobileSingleSelectProps<T>) => {
  const searchInputRef = React.useRef<InputRef>(null)

  const [internalIsOpen, setInternalIsOpen] = useState(!!externalOpen)
  const isOpen = externalOpen ?? internalIsOpen

  useLayoutEffect(() => {
    if (onSearch && isOpen && searchInputRef.current) {
      // This setTimeout is a hack to flush the focus at the end of the event loop.
      // For whatever reason without it, calling focus on the ref doesn't do the trick.
      // The time out value matches the transition duration of the OnsiteModal.
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 500)
    }
  }, [onSearch, isOpen, searchInputRef])

  const label = useMemo(
    () => options.find(option => option.value === value)?.label,
    [options, value],
  )

  const onClose = useCallback(() => {
    setInternalIsOpen(false)
    externalOnClose?.()
  }, [externalOnClose])
  return (
    <>
      <div
        onClickCapture={e => {
          e.preventDefault()
          e.stopPropagation()
          if (disabled) {
            return
          } else {
            externalOnOpen?.()
            setInternalIsOpen(true)
          }
        }}
      >
        <Input
          className={classNames(
            {
              hidden: hideInput,
            },
            className,
          )}
          value={labelOverride ?? label ?? value}
          aria-label={title}
          role="button"
          readOnly
          data-testid={name}
          data-dd-action-name={ddActionName}
          placeholder={placeholder}
          disabled={disabled}
          size={size}
          prefix={
            onSearch ? (
              <SearchOutlined className="text-bz-gray-700" />
            ) : undefined
          }
          suffix={
            !onSearch && <DownOutlined className="text-[rgba(0,0,0,0.25)]" />
          }
        />
      </div>
      <OnsiteModal
        drawer={sheetSize === 'half'}
        onClose={onClose}
        className="z-[2000]"
        open={isOpen}
        size={sheetSize === 'half' ? 'small' : 'large'}
      >
        <ItemPicker
          canSelectMultiple={false}
          title={title}
          items={options.map(option => ({
            id: option.value,
            name: option.label,
            customNode: option.customNode,
            disabled: option.disabled,
            value: 0,
          }))}
          hideSearch={sheetSize === 'half'}
          onCancel={onClose}
          onSearch={onSearch}
          searchInputRef={searchInputRef}
          selectedItemCountMap={
            value
              ? {
                  [value]: 1,
                }
              : {}
          }
          onItemSelect={id => {
            onChange(id as T)
            onClose()
          }}
          hideFooter
          showItemValue={false}
          emptyState={emptyState}
          loading={loading}
        />
      </OnsiteModal>
    </>
  )
}

export const BzMobileSingleSelect = React.memo(
  BzMobileSingleSelectInner,
) as typeof BzMobileSingleSelectInner

type BzMobileMultiSelectProps<T> = {
  title: string
  values: T[] | []
  placeholder?: string
  disabled?: boolean
  options?: BzSelectOption[]
  onChange: (values: T[]) => void
  tagRender?: (props: {
    value: T
    label: React.ReactNode
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  }) => React.ReactElement
  size?: BzSelectSize
  sheetSize?: BzSheetSize
  className?: string
  name?: string
  ddActionName?: string
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  hideInput?: boolean
}

const BzMobileMultiSelectInner = <T extends string>({
  title,
  values = [],
  placeholder,
  options = [],
  onChange,
  disabled,
  tagRender,
  size = 'large',
  sheetSize = 'full',
  className,
  name,
  ddActionName,
  open: externalOpen,
  onClose: externalOnClose,
  onOpen: externalOnOpen,
  hideInput,
}: BzMobileMultiSelectProps<T>) => {
  const [internalIsOpen, setInternalIsOpen] = useState(!!externalOpen)
  const isOpen = externalOpen ?? internalIsOpen

  const [selectedItemCountMap, setSelectedItemCountMap] = useState(
    values.reduce((acc, value) => {
      acc[value as string] = 1
      return acc
    }, {} as Record<string, number>),
  )

  useEffect(() => {
    if (isOpen) {
      setSelectedItemCountMap(
        values.reduce((acc, value) => {
          acc[value as string] = 1
          return acc
        }, {} as Record<string, number>),
      )
    }
  }, [isOpen, values])

  const onClose = useCallback(() => {
    setInternalIsOpen(false)
    externalOnClose?.()
  }, [externalOnClose])
  return (
    <>
      <Select
        className={classNames(
          'bz-mobile-multi-select',
          {
            hidden: hideInput,
          },
          className,
        )}
        popupClassName="bz-mobile-multi-select-popup"
        size={size}
        mode="multiple"
        placeholder={placeholder}
        onDeselect={value => {
          onChange(values.filter(v => v !== value))
          setSelectedItemCountMap(prev => {
            const { [value]: _, ...rest } = prev
            return rest
          })
        }}
        title={title}
        data-testid={name}
        data-dd-action-name={ddActionName}
        aria-label={title}
        aria-roledescription="Select Button"
        tagRender={tagRender}
        disabled={disabled}
        value={values}
        options={options.map(option => ({
          label: option.label,
          value: option.value,
        }))}
        open={false}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          if (disabled) {
            return
          } else {
            externalOnOpen?.()
            setInternalIsOpen(true)
          }
        }}
      />
      <OnsiteModal
        drawer={sheetSize === 'half'}
        onClose={onClose}
        className="z-[2000]"
        open={isOpen}
        size={sheetSize === 'half' ? 'small' : 'default'}
      >
        <ItemPicker
          canSelectMultiple
          submitText="Save"
          title={title}
          items={options.map(option => ({
            id: option.value?.toString() ?? '',
            name: option.label?.toString() ?? '',
            customNode: option.customNode,
            disabled: option.disabled,
            value: 0,
          }))}
          hideSearch={sheetSize === 'half'}
          onCancel={() => {
            onClose()
            setSelectedItemCountMap({})
          }}
          selectedItemCountMap={selectedItemCountMap}
          onItemSelect={(id, count) => {
            setSelectedItemCountMap(prev => ({
              ...prev,
              [id as string]: count,
            }))
          }}
          onSave={() => {
            onChange(
              Object.keys(selectedItemCountMap).filter(
                key => selectedItemCountMap[key] > 0,
              ) as T[],
            )
            onClose()
          }}
          showItemValue={false}
          hideMultipleQuantityPicker
        />
      </OnsiteModal>
    </>
  )
}

export const BzMobileMultiSelect = React.memo(
  BzMobileMultiSelectInner,
) as typeof BzMobileMultiSelectInner

const BzMobileSelect = <
  SelectMode extends BzSelectMode = 'default',
  T extends string = '',
>(
  props: BzSelectProps<SelectMode, T>,
) => {
  const { mode = 'default' } = props
  if (mode === 'default') {
    return <BzMobileSingleSelect {...(props as BzSelectProps<'default', T>)} />
  }
  return <BzMobileMultiSelect {...(props as BzSelectProps<'multiple', T>)} />
}

const MemoizedBzMobileSelect = React.memo(
  BzMobileSelect,
) as typeof BzMobileSelect

export default MemoizedBzMobileSelect
