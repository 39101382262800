import { z } from 'zod'
import { AsyncFn, Log, Logger, ThisShouldNeverHappenError, isNullish } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts/_common'
import { CompanyGuid, CompanySchema } from '../Company/Company'
import { emailAddressValueSchema } from '../Email/EmailTypes'
import { PermissionV1, PermissionV2, V2SystemPermissions, isPermissionV1, isPermissionV2 } from '../Permission'
import {
  PhoneNumber,
  PhoneNumberSchema,
  PhoneNumberType,
  PhoneNumberTypeSchema,
  telephoneNumberSchema,
} from '../PhoneNumbers/Phone'
import { Role, RoleId, RoleSchema, inferredPermissions } from '../Role'
import { bzOptional, firstNameSchema, lastNameSchema } from '../common-schemas'

export const BreezySystemUserGuid = '00000000-0000-0000-0000-000000000000'
export const UnauthorizedUnknownUserGuid = '06468934-eac6-4a4f-8009-a5cb433955f7'

export const UserGuidContainerSchema = z.object({
  userGuid: guidSchema,
})

export const SCHEDULING_CAPABILITIES = ['NONE', 'INTERNAL_EVENTS_ONLY', 'JOB_APPOINTMENTS_AND_INTERNAL_EVENTS'] as const

export const SchedulingCapabilitySchema = z.enum(SCHEDULING_CAPABILITIES)
export type SchedulingCapability = z.infer<typeof SchedulingCapabilitySchema>
export const DEFAULT_SCHEDULING_CAPABILITY: SchedulingCapability = 'JOB_APPOINTMENTS_AND_INTERNAL_EVENTS'

export const ELIGIBLE_FOR_SCHEDULING: SchedulingCapability[] = [
  'INTERNAL_EVENTS_ONLY',
  'JOB_APPOINTMENTS_AND_INTERNAL_EVENTS',
]

export const eligibleForScheduling = (capability: SchedulingCapability) => {
  return ELIGIBLE_FOR_SCHEDULING.includes(capability)
}

export const eligibleToBeAssignedToJobAppointments = (capability: SchedulingCapability) => {
  return capability === 'JOB_APPOINTMENTS_AND_INTERNAL_EVENTS'
}

export const SCHEDULING_CAPABILITY_DISPLAY_INFO: Record<
  SchedulingCapability,
  { displayName: string; details: string }
> = {
  INTERNAL_EVENTS_ONLY: {
    displayName: 'Internal Events Only',
    details: 'Team member can be assigned to internal events, but not to jobs',
  },
  JOB_APPOINTMENTS_AND_INTERNAL_EVENTS: {
    displayName: 'Jobs & Internal Events',
    details: 'Team member can be assigned to both jobs and internal events',
  },
  NONE: {
    displayName: 'None',
    details: 'Team member is ineligible to be assigned to both jobs and internal events',
  },
}

export type UserGuidContainer = z.infer<typeof UserGuidContainerSchema>
export type MaybeUserGuidContainer = {
  userGuid?: UserGuid
}

export type ForMaybeUser<T> = T & MaybeUserGuidContainer

export const UserSchema = z.object({
  userGuid: guidSchema,
  emailAddress: emailAddressValueSchema,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  permissions: z.array(z.nativeEnum(PermissionV1)),
  permissionsV2: z.array(z.nativeEnum(PermissionV2)),
  roles: z.array(RoleSchema),
  phoneNumbers: z.array(PhoneNumberSchema),
  company: bzOptional(CompanySchema),
  deactivatedAt: bzOptional(isoDateStringSchema),
  schedulingCapability: SchedulingCapabilitySchema,
})

export type MinimalUser = {
  readonly userGuid: UserGuid
  readonly firstName: string
  readonly lastName: string
}

export type UserGuid = string
export type User = z.infer<typeof UserSchema>

export type UserContainer = { user: User }

export const isUser = (u: unknown): u is User => {
  const res = UserSchema.safeParse(u)
  if (!res.success) {
    Log.warn('UserSchema failed to parse', { error: res.error, expectedUser: u })
  }
  return res.success
}

export const hasPermission = (user: User, permission: PermissionV1): boolean => {
  const perms = getEffectivePermissions(user)
  return perms.includes(permission) || perms.includes(PermissionV1.ALL_ACTIONS)
}

export const hasPermissionV2 = (user: User, permission: PermissionV2): boolean => {
  const perms = user.permissionsV2

  // System permissions are higher-level permissions than the super admin so
  // we check for that here
  if (V2SystemPermissions.includes(permission)) {
    return perms.includes(permission)
  }

  return perms.includes(permission) || perms.includes(PermissionV2.ALL_ACTIONS)
}

export const principalHasPermission = (principal: User, to: PermissionV1 | PermissionV2) => {
  if (isPermissionV2(to)) {
    return hasPermissionV2(principal, to)
  } else if (isPermissionV1(to)) {
    return hasPermission(principal, to)
  } else {
    return false
  }
}

export function getEffectivePermissions(user: User): Readonly<PermissionV1[]> {
  if (user.permissions.includes(PermissionV1.ALL_ACTIONS)) {
    return Object.values(PermissionV1) // return all permissions if the user has the all-actions permission
  }

  const permissions = new Set(user.permissions)

  user.roles.forEach((role: Role) => {
    ;(inferredPermissions[role.role] || []).forEach(perm => {
      permissions.add(perm)
    })
  })

  return Array.from(permissions.values())
}

// Extends User Schema but company must be present
export const CompanyUserSchema = UserSchema.omit({ company: true }).extend({
  company: CompanySchema,
})

export type CompanyUser = z.infer<typeof CompanyUserSchema>

export const isCompanyUser = (u: unknown): u is CompanyUser => CompanyUserSchema.safeParse(u).success
export const expectUserToBeCompanyUser = (u: User, logger: Logger = Log) => {
  if (!isCompanyUser(u)) {
    logger.error('Erroneously expected user to be company user', u, CompanyUserSchema.safeParse(u))

    throw new ThisShouldNeverHappenError('Erroneously expected user to be company user')
  }

  return u as CompanyUser
}

export type ForUser<T> = T & UserGuidContainer
export type UserReader = AsyncFn<UserGuidContainer, User>

export const isInternalBreezyAdminUser = (user: User) => {
  return (
    isNullish(user.company) &&
    hasPermission(user, PermissionV1.ALL_ACTIONS) &&
    hasPermission(user, PermissionV1.IMPERSONATE_USER)
  )
}

export const DeactivateUserRequestSchema = z.object({
  email: z.string(),
})

export type DeactivateUserRequest = z.infer<typeof DeactivateUserRequestSchema>

export type UserByEmailReader = AsyncFn<string, User | undefined>
export type UserByGuidReader = AsyncFn<UserGuid, User | undefined>
export type UsersByCompanyGuidReader = AsyncFn<CompanyGuid, CompanyUser[]>

export interface UserCreatorRequest {
  creatingUserGuid: UserGuid
  companyGuid: CompanyGuid
  email: string
  firstName: string
  lastName: string
  roles: Readonly<RoleId[]>
  phoneNumber: string
  phoneNumberType: PhoneNumberType
  permissions: Readonly<PermissionV1[]>
  permissionsV2: Readonly<PermissionV2[]>
  skipEmailVerification?: boolean
  schedulingCapability: SchedulingCapability
}

export type UserCreator = AsyncFn<UserCreatorRequest, CompanyUser>

export interface UserRolesCreatorRequest {
  userGuid: UserGuid
  roles: Readonly<RoleId[]>
}

export type UserRolesCreator = AsyncFn<UserRolesCreatorRequest>
export type UserRolesUpdater = AsyncFn<UserRolesCreatorRequest>

export interface UserWriterRequest {
  userGuid: string
  emailAddress: string
  firstName: string
  lastName: string
}

export type UserWriter = AsyncFn<UserWriterRequest>

export interface UserPermissionsWriterRequest {
  userGuid: string
  permissions: Readonly<PermissionV1[]>
  createdByUserGuid: string
}

export type UserPermissionsWriter = AsyncFn<UserPermissionsWriterRequest>

export interface UserPermissionsV2WriterRequest {
  userGuid: string
  permissions: Readonly<PermissionV2[]>
  createdByUserGuid: string
}

export type UserPermissionsV2Writer = AsyncFn<UserPermissionsV2WriterRequest>

export interface UserPermissionsUpdaterRequest {
  userGuid: string
  permissions: Readonly<PermissionV1[]>
  updatedByUserGuid: string
}

export type UserPermissionsUpdater = AsyncFn<UserPermissionsUpdaterRequest>

export interface UserPermissionsV2UpdaterRequest {
  userGuid: string
  permissions: Readonly<PermissionV2[]>
  updatedByUserGuid: string
}

export type UserPermissionsV2Updater = AsyncFn<UserPermissionsV2UpdaterRequest>

export interface UserPhoneNumberWriterRequest {
  userGuid: string
  phoneNumber: PhoneNumber
}

export type UserPhoneNumberWriter = AsyncFn<UserPhoneNumberWriterRequest>

export interface UserPhoneNumberUpdaterRequest {
  userGuid: string
  newPhoneNumber: PhoneNumber
  oldPhoneNumber: PhoneNumber
}

export type UserPhoneNumberUpdater = AsyncFn<UserPhoneNumberUpdaterRequest>

export interface UserFirstLastUpdaterRequest {
  userGuid: string
  firstName: string
  lastName: string
}

export type UserFirstLastUpdater = AsyncFn<UserFirstLastUpdaterRequest>

export type UserByEmailDeactivator = AsyncFn<string>

export interface UserUpdaterRequest {
  updatingUserGuid: string
  userGuid: string
  companyGuid: string
  firstName: string
  lastName: string
  phoneNumber: string
  phoneNumberType: PhoneNumberType
  roles?: Readonly<RoleId[]>
  permissions?: Readonly<PermissionV1[]>
  permissionsV2?: Readonly<PermissionV2[]>
  schedulingCapability?: SchedulingCapability
}

export type UserUpdater = AsyncFn<UserUpdaterRequest>

export interface UserDeactivatorRequest {
  companyGuid: CompanyGuid
  email: string
}

export type UserDeactivator = AsyncFn<UserDeactivatorRequest>

export const getRootUser = (): User => ({
  userGuid: '8c26643a-f8d0-435f-ab63-210f60c02472',
  firstName: 'root',
  lastName: 'mcRooterson',
  emailAddress: 'root@getbreezyapp.com',
  phoneNumbers: [],
  permissions: [],
  permissionsV2: [],
  roles: [],
  schedulingCapability: DEFAULT_SCHEDULING_CAPABILITY,
})

export const InviteUserDtoSchema = z.object({
  emailAddress: UserSchema.shape.emailAddress,
  companyGuid: bzOptional(CompanySchema.shape.companyGuid),
  permissions: UserSchema.shape.permissions,
  permissionsV2: UserSchema.shape.permissionsV2,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  roles: z.array(z.nativeEnum(RoleId)),
  phoneNumber: telephoneNumberSchema,
  phoneNumberType: PhoneNumberTypeSchema,
  schedulingCapability: SchedulingCapabilitySchema,
})

export type InviteUserDto = z.infer<typeof InviteUserDtoSchema>

export interface UserInviterResponse {
  user: User
  changePasswordTicket: string
}

export type UserInviter = AsyncFn<
  ForUser<Omit<InviteUserDto, 'companyGuid'> & Required<Pick<InviteUserDto, 'companyGuid'>>>,
  UserInviterResponse
>
