import { datadogRum } from '@datadog/browser-rum'
import { Button } from 'antd'
import { useCallback } from 'react'
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'
// import { excludeAlertsFor } from '../../apps/common/CommonAppWrapper'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useIsProduction } from '../../hooks/useIsProduction'
import { Composable } from '../../utils/Composable'
import { postErrorAlert } from '../../utils/GraphqlAlerts'
import './GeneralErrorFallback.less'

export type ErrorFallbackProps = {
  error: Error
  resetErrorBoundary: () => void
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const isProduction = useIsProduction()

  return (
    <div className="error_display fixed inset-0 text-bz-text" role="alert">
      <div className="error_display__inner">
        <h3>Component Error:</h3>
        <p className="error_display__message">
          {isProduction || !error.stack ? error.message : error.stack}
        </p>
        <Button onClick={resetErrorBoundary} type="primary" size="large">
          Recover
        </Button>
      </div>
    </div>
  )
}

const GeneralErrorFallback: React.FC<Composable> = ({ children }) => {
  const navigate = useNavigate()
  const recover = () => navigate('/')
  const frontendAlerts = useFeatureFlag('frontendAlerts')
  const onError = useCallback<
    NonNullable<ErrorBoundaryPropsWithComponent['onError']>
  >(
    async (e, info) => {
      datadogRum.addError(e, info)
      if (
        frontendAlerts
        // &&
        // !excludeAlertsFor.some(excludedMessage =>
        //   e.message.includes(excludedMessage),
        // )
      ) {
        await postErrorAlert(`FE: ${e.message}`)
      }
    },
    [frontendAlerts],
  )

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={onError}
      onReset={recover}
    >
      {children}
    </ErrorBoundary>
  )
}

export const withGeneralFallbackOnError = (Component: React.ReactNode) => {
  return <GeneralErrorFallback>{Component}</GeneralErrorFallback>
}

export default GeneralErrorFallback
