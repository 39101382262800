import { ComponentType, Suspense } from 'react'
import { createSafeLazy } from 'react-safe-lazy'
import { FullScreenLoadingSpinner } from '../components/LoadingSpinner'

const safeLazy = createSafeLazy({
  importRetries: 2,
  forceReload: {
    maxRetries: 2,
  },
})

type GetProps<T> = T extends ComponentType<infer P> ? P : never

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyLoad = <TModule extends { default: ComponentType<any> }>(
  importFn: () => Promise<TModule>,
  fallback = FullScreenLoadingSpinner,
) => {
  const LazyComponent = safeLazy(importFn)
  return (props: GetProps<TModule['default']>) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  )
}

export const lazyLoadDrawer: typeof lazyLoad = importFn =>
  lazyLoad(importFn, <></>)

// TODO
export const lazyLoadOnsiteModal: typeof lazyLoad = importFn =>
  lazyLoad(importFn, <></>)
