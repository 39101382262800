import { useLayoutEffect } from 'react'

// https://stackoverflow.com/a/51733026/2108612
export const useIOSHorizScrollingFix = (
  elem: HTMLDivElement | undefined | null,
) => {
  useLayoutEffect(() => {
    if (!elem) {
      return
    }
    let lastX = 0
    const touchStartEvent = (e: TouchEvent) => {
      lastX = e.touches[0].clientX
    }
    const touchMoveEvent = (e: TouchEvent) => {
      const left = e.touches[0].clientX
      const currentTarget = e.currentTarget as HTMLDivElement
      if (currentTarget) {
        const scrollLeft = currentTarget.scrollLeft
        const maxScrollLeft =
          currentTarget.scrollWidth - currentTarget.offsetWidth
        const direction = lastX - left < 0 ? 'left' : 'right'

        if (
          e.cancelable &&
          ((direction === 'left' && scrollLeft <= 0) ||
            (direction === 'right' && scrollLeft >= maxScrollLeft))
        ) {
          e.preventDefault()
        }
        lastX = left
      }
    }

    elem.addEventListener('touchstart', touchStartEvent)
    elem.addEventListener('touchmove', touchMoveEvent)
    return () => {
      elem?.removeEventListener('touchstart', touchStartEvent)
      elem?.removeEventListener('touchmove', touchMoveEvent)
    }
  }, [elem])
}

export const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  )
}
