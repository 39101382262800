import { z } from 'zod'
import { AsyncFn, AsyncTask, BzDateFns, IsoDateString, TimeZoneId } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts'
import { AccountGuid, AccountGuidContainer } from '../Accounts/Account'
import { CompanyGuid, ForCompany, ForCompanyUser } from '../Company/Company'
import { UserGuid } from '../Users/User'
import { Guid, bzOptional } from '../common-schemas'

export const accountReminderUpsertRequestSchema = z.object({
  accountGuid: guidSchema,
  reminderGuid: bzOptional(guidSchema),
  description: z.string(),
  dueAt: isoDateStringSchema,
  reminderAssignmentUserGuid: guidSchema,
})

export type AccountReminderUpsertRequest = z.infer<typeof accountReminderUpsertRequestSchema>

export type AccountReminderUpsertResponse = {
  accountGuid: Guid
  reminderGuid: Guid
}

export type AccountReminderWriter = AsyncFn<ForCompanyUser<AccountReminderUpsertRequest>, AccountReminderUpsertResponse>
export type AccountReminderUpserter = AsyncFn<
  ForCompanyUser<AccountReminderUpsertRequest>,
  AccountReminderUpsertResponse
>

type RemindAssignmentGuidContainer = {
  reminderAssignmentGuid: Guid
}

export type AccountReminderAssignmentReader = AsyncFn<
  Required<Pick<AccountReminderUpsertRequest, 'accountGuid' | 'reminderGuid'>>,
  RemindAssignmentGuidContainer[]
>

export type ReminderStatus = 'COMPLETE' | 'INCOMPLETE'

export type AccountReminder = {
  accountGuid: AccountGuid
  account: {
    accountDisplayName: string
  }
  reminder: {
    reminderGuid: Guid
    companyGuid: Guid
    status: ReminderStatus
    description: string
    dueAt: IsoDateString
    updatedAt: IsoDateString
    createdByUser: {
      userGuid: Guid
      firstName: string
      firstNameInitial?: string
    }
    reminderAssignments: {
      user: {
        userGuid: Guid
        emailAddress: string
        firstName: string
        firstNameInitial?: string
      }
    }[]
  }
}

type AccountReminderRequest = ForCompany<AccountGuidContainer> & {
  reminderGuid: Guid
}

export type AccountReminderReader = AsyncFn<AccountReminderRequest, AccountReminder>
type AccountRemindersAssignmentRequest = {
  companyGuid: CompanyGuid
  userGuid: Guid
  status: ReminderStatus
}

type UnnotifiedAccountRemindersQuery = {
  type: 'overdue'
  companyGuid: CompanyGuid
  companyTimeZoneId: TimeZoneId
}

export type UnnotifiedAccountRemindersQuerier = AsyncFn<UnnotifiedAccountRemindersQuery, AccountReminder[]>

export type AccountRemindersAssignmentReader = AsyncFn<AccountRemindersAssignmentRequest, AccountReminder[]>
export type AccountReminderAssignmentEmailPublisher = AsyncFn<AccountReminderRequest, void>
export type AccountReminderAssignmentEmailer = AsyncFn<AccountReminder, void>

export type AccountReminderWeeklyDigestEmailPublisher = AsyncTask
export type AccountRemindersDueNotificationPublisher = AsyncTask

export type AccountRemindersUniqueAssignmentsReader = AsyncFn<CompanyGuid, UserGuid[]>
export type AccountReminderWeeklyDigestEmailer = AsyncFn<ForCompanyUser<{ accountReminders: AccountReminder[] }>, void>

export const getReminderDisplayDate = (date: IsoDateString, timeZoneId: TimeZoneId): string => {
  const parsedDate = BzDateFns.parseISO(date, timeZoneId)
  const startOfToday = BzDateFns.getToday(timeZoneId)

  if (isDueToday(date, timeZoneId)) {
    return 'Today'
  } else if (isDueTomorrow(date, timeZoneId)) {
    return 'Tomorrow'
  } else if (isDueYesterday(date, timeZoneId)) {
    return 'Yesterday'
  } else {
    const dayDifference = BzDateFns.differenceInCalendarDays(parsedDate, BzDateFns.now(timeZoneId))
    if (dayDifference >= 1 && dayDifference <= 5) {
      return BzDateFns.format(parsedDate, 'EEEE')
    } else {
      const sameYear = BzDateFns.getYear(parsedDate) === BzDateFns.getYear(startOfToday)
      return BzDateFns.format(parsedDate, sameYear ? 'MMMM d' : 'MMMM d, yyyy')
    }
  }
}

export const getReminderOverdueStatus = (date: IsoDateString, timeZoneId: TimeZoneId): boolean => {
  const parsedDate = BzDateFns.parseISO(date, timeZoneId)
  const startOfToday = BzDateFns.getToday(timeZoneId)
  return BzDateFns.isBefore(parsedDate, startOfToday)
}

const isDueToday = (date: IsoDateString, timeZoneId: TimeZoneId): boolean => {
  const parsedDate = BzDateFns.parseISO(date, timeZoneId)
  const startOfToday = BzDateFns.getToday(timeZoneId)
  return BzDateFns.isSameDay(parsedDate, startOfToday)
}

const isDueTomorrow = (date: IsoDateString, timeZoneId: TimeZoneId): boolean => {
  const parsedDate = BzDateFns.parseISO(date, timeZoneId)
  const startOfTomorrow = BzDateFns.getTomorrow(timeZoneId)
  return BzDateFns.isSameDay(parsedDate, startOfTomorrow)
}

const isDueYesterday = (date: IsoDateString, timeZoneId: TimeZoneId): boolean => {
  const parsedDate = BzDateFns.parseISO(date, timeZoneId)
  const startOfYesterday = BzDateFns.getYesterday(timeZoneId)
  return BzDateFns.isSameDay(parsedDate, startOfYesterday)
}

export const getWeeklyDigestTimeRange = (timeZoneId: TimeZoneId) => {
  const today = BzDateFns.now(timeZoneId)
  const dayOfWeek = BzDateFns.getDay(today)

  // Calculate the start of the last workweek (previous Monday)
  const daysToSubtractFromMonday = dayOfWeek - 1
  const previousMonday = BzDateFns.subDays(today, daysToSubtractFromMonday + 7) // Go back one more week
  const startOfWorkWeek = BzDateFns.startOfDay(previousMonday)

  // Calculate the end of the upcoming week (next Sunday)
  const daysToAddToSunday = 7 - dayOfWeek
  const nextSunday = BzDateFns.addDays(today, daysToAddToSunday)
  const endOfWeek = BzDateFns.endOfDay(nextSunday)

  return {
    start: BzDateFns.formatISO(startOfWorkWeek, timeZoneId),
    end: BzDateFns.formatISO(endOfWeek, timeZoneId),
  }
}

export const isDueLastWeek = (date: IsoDateString, timeZoneId: TimeZoneId) => {
  const today = BzDateFns.now(timeZoneId)
  const dayOfWeek = BzDateFns.getDay(today)

  // Calculate the end of the last work week which would be end of day of the last Sunday
  const daysToSubtractFromSunday = dayOfWeek
  const lastSunday = BzDateFns.subDays(today, daysToSubtractFromSunday)
  const endOfWorkWeek = BzDateFns.endOfDay(lastSunday)

  // Calculate the start of the week before the last work week (previous to previous Monday)
  const startOfLastWorkWeek = BzDateFns.subDays(endOfWorkWeek, 7)
  const startOfLastWeek = BzDateFns.startOfDay(startOfLastWorkWeek)

  // Check if the date is within the interval of the week before the last work week
  const isWithinLastWeek = BzDateFns.isWithinInterval(BzDateFns.parseISO(date, timeZoneId), {
    start: startOfLastWeek,
    end: endOfWorkWeek,
  })

  return isWithinLastWeek
}

export const isDueThisWeek = (date: IsoDateString, timeZoneId: TimeZoneId) => {
  const today = BzDateFns.now(timeZoneId)
  const dayOfWeek = BzDateFns.getDay(today)

  // Calculate the beginning of the work week (this Monday)
  const daysToSubtractFromMonday = dayOfWeek - 1
  const thisMonday = BzDateFns.subDays(today, daysToSubtractFromMonday)
  const startOfWorkWeek = BzDateFns.startOfDay(thisMonday)

  // Calculate the end of the upcoming week (next Sunday)
  const daysToAddToSunday = 7 - dayOfWeek
  const nextSunday = BzDateFns.addDays(today, daysToAddToSunday)
  const endOfWeek = BzDateFns.endOfDay(nextSunday)

  return BzDateFns.isWithinInterval(BzDateFns.parseISO(date, timeZoneId), {
    start: startOfWorkWeek,
    end: endOfWeek,
  })
}

export type AccountReminderEmail = {
  accountGuid: AccountGuid
  description: string
  dueAt: IsoDateString
  status: ReminderStatus
  reminderAssignment: {
    user: {
      firstName: string
      firstNameInitial: string
    }
  }
  account: {
    accountDisplayName: string
  }
  createdByUser: {
    firstName: string
    firstNameInitial: string
  }
}

export type ReminderAssignmentEmailData = {
  accountDetailsUrl: string
  companyLogoUrl?: string
  companyTimeZoneId: TimeZoneId
  accountReminderEmail: AccountReminderEmail
}

export type RemindersWeeklyDigestEmailData = {
  userFirstName: string
  breezyAppUrl: string
  companyTimeZoneId: TimeZoneId
  accountReminderEmails: AccountReminderEmail[]
  companyLogoUrl?: string
}
