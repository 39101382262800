import { Guid, R, isNullish, toPlural } from '@breezy/shared'
import { faClose, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InputRef, List } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useScrollbarWidth } from 'react-use'
import {
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import BzCheckBox from '../../elements/BzCheckBox/BzCheckBox'
import BzRadioButton from '../../elements/BzRadioButton/BzRadioButton'
import { HighlightedText } from '../../elements/HighlightedText/HighlightedText'
import useIsMobile from '../../hooks/useIsMobile'
import { useIsPricebookPhotosEnabled } from '../../hooks/useIsPricebookPhotosEnabled'
import {
  OnResize,
  useResizeObserverForElement,
} from '../../hooks/useResizeObserver'
import {
  PricebookPickerCategory,
  PricebookPickerItem,
} from '../../providers/PricebookProvider'
import { StateSetter, stopPropagation } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import { PricebookPhotoThumbnail } from '../PricebookPhotoThumbnail/PricebookPhotoThumbnail'

const CHECKBOX_CLASS_NAME = 'ml-3 h-8'

const moneyFormatter = (value: number) =>
  value.toLocaleString('en-US', { currency: 'USD', style: 'currency' })

export type ItemPickerItem = PricebookPickerItem & {
  customNode?: React.ReactNode
  disabled?: boolean
  numberFormatter?: (value: number) => string
}

export type ItemPickerCategory = PricebookPickerCategory<ItemPickerItem> & {
  disabled?: boolean
}

const isCategory = (
  item: ItemPickerItem | ItemPickerCategory,
): item is ItemPickerCategory => !!(item as ItemPickerCategory).items

type SearchedItemPickerItem = ItemPickerItem & {
  path: string[]
}

const itemHasPath = (
  item: ItemPickerItem | SearchedItemPickerItem | ItemPickerCategory,
): item is SearchedItemPickerItem =>
  ((item as SearchedItemPickerItem).path ?? []).length > 0

type CartItem = ItemPickerItem & {
  count: number
}

type CartSquareProps = {
  header: string
  value: number
  count: number
  colored?: boolean
  onDelete?: () => void
  showItemValue?: boolean
}

const CartSquare = React.memo<CartSquareProps>(
  ({ header, value, count, colored, onDelete, showItemValue = true }) => {
    return (
      <div
        className={classNames(
          'flex h-[78px] w-min min-w-[110px] max-w-[160px] flex-col justify-between rounded-lg p-2',
          {
            'bg-bz-gray-400': colored,
          },
        )}
      >
        <div className="flex flex-row">
          <div
            className={classNames('line-clamp-2 flex-1 font-semibold', {
              'my-4': !showItemValue,
            })}
          >
            {header}
          </div>
          {onDelete ? (
            <Button
              icon={<FontAwesomeIcon icon={faClose} />}
              size="small"
              shape="circle"
              type="text"
              className="relative right-[-5px] top-[-5px] ml-[-5px]"
              onClick={onDelete}
            />
          ) : null}
        </div>
        <div>
          {count > 1 ? `x${count} ` : ''}
          {showItemValue && moneyFormatter(value)}
        </div>
      </div>
    )
  },
)

const DEFAULT_ITEM_HEIGHT = 72
const WITH_PATH_ADDITIONAL_HEIGHT = 31
const SELECTED_ADDITIONAL_HEIGHT = 52

const getItemHeight = (
  canSelectMultiple: boolean,
  isSelected: boolean,
  isSearching: boolean,
  hasPath: boolean,
): number =>
  // This is slightly faster than an if/elseif/else block or a switch because it's instant lookup instead of several
  // boolean checks. If I did it the boolean check way and wrapped it in `memoize`, it would basically just make this,
  // so I'm just doing this directly. VERY minor performance optimization but this will be called a LOT
  ({
    true_true:
      DEFAULT_ITEM_HEIGHT +
      WITH_PATH_ADDITIONAL_HEIGHT +
      SELECTED_ADDITIONAL_HEIGHT,
    true_false: DEFAULT_ITEM_HEIGHT + WITH_PATH_ADDITIONAL_HEIGHT,
    false_true: DEFAULT_ITEM_HEIGHT + SELECTED_ADDITIONAL_HEIGHT,
    false_false: DEFAULT_ITEM_HEIGHT,
    // The first boolean is if we show space for the path. We only show the paths if we're searching and the item has a
    // path. The second is if we show the quantity picker. We only show the quantity picker if it's possible to select
    // multiple and this item is selected.
  }[`${isSearching && hasPath}_${canSelectMultiple && isSelected}`])

type VirtualItem = {
  height: number
  offset: number
}

// ChatGPT baby!
const binaryVirtualItemSearch = (
  items: VirtualItem[],
  position: number,
  before: boolean,
): number => {
  let low = 0
  let high = items.length - 1
  while (low <= high) {
    const mid = Math.floor((low + high) / 2)
    // If we're finding the first element that starts BEFORE our position, we want the offset (where it begins). If
    // we're looking for the first one AFTER our position, we need the offset plus its height to get where it really
    // ends.
    const midVal = items[mid].offset + (before ? 0 : items[mid].height)

    if (midVal < position) {
      low = mid + 1
    } else if (midVal > position) {
      high = mid - 1
    } else {
      return mid // Exact match found
    }
  }
  // Remember that when we're here, high/low had a crossover that broke the loop. When `before` is true, `low` is the
  // smallest item whose offset is greater than our position. We want the one before that, because we want the one
  // partially visible, so that's `high`. Likewise, if `before` is false, `high` is the largest item whose offset is
  // BEFORE our position. `low` is the next one, which is the one partially visible.
  return before ? high : low
}

// This is a poor-man's virtual list. There are tons of libraries out there, but they generally create a container that
// scrolls. I can't do that because I need the parent to scroll (because I have this special scroll thing where the
// header shrinks). We also have variable-height items (since selecting an item makes it taller) so our logic is a
// little complex. Because of that and because our scroll/offset need is so specific, I decided to just implement our
// own virtualization algorithm. Since my items have variable heights, I need to go item-by-item, summing up their
// heights, until I get "into my view" (based on the current scroll position). Then I keep going until I'm "out of my
// view" (the items are beyond the bottom of the view), and I return those indices.
const useVisibleIndexes = (
  listContainerRef: React.RefObject<HTMLDivElement>,
  scrollTop: number,
  itemsToDisplay: (ItemPickerItem | ItemPickerCategory)[],
  isSearching: boolean,
  canSelectMultiple: boolean,
  selectedItemCountMap: Record<Guid, number>,
) => {
  // Distance between the top of our container and our list. We need to know this so we can back that out of the
  // scrollTop to know where our elements truly start to be scrolled out of view.
  const [listOffset, setLiftOffset] = useState(0)
  // How much space we have.
  const [containerHeight, setContainerHeight] = useState(0)

  // Doing this with a resize observer. Not only can they resize the window, but selecting items creates a "cart" at the
  // bottom of the modal that shrinks the available space, requiring us to recompute.
  const onResize = useCallback<OnResize>(() => {
    if (listContainerRef.current) {
      const parent = listContainerRef.current.parentElement

      const listY = listContainerRef.current.getBoundingClientRect().y

      const parentBoundingRect = parent?.getBoundingClientRect()

      // The distance between the top of the list and the top of the container. In this component, it a header that says
      // something like "120 results" if you're searching, or the category name if you're in a category. We can take the
      // y position of our list, then take away top position of the parent (if the parent starts 100px from the top of
      // the screen, and our list starts 150px from the top of the screen, we know the offset is 50px). But since the
      // interior of the parent can scroll, which affects the y position of the list, we need to adjust that list
      // position by taking away the scroll top of the parent (the y position is negative because it's off the screen,
      // so we add the scroll top to counteract that).
      const listOffset =
        listY + (parent?.scrollTop ?? 0) - (parentBoundingRect?.y ?? 0)

      setLiftOffset(listOffset)
      setContainerHeight(parentBoundingRect?.height ?? 0)
    }
  }, [listContainerRef])

  useResizeObserverForElement(listContainerRef.current?.parentElement, onResize)

  // Init the values
  useEffect(() => {
    if (listContainerRef.current?.parentElement) {
      onResize(listContainerRef.current.parentElement.getBoundingClientRect())
    }
  }, [listContainerRef, onResize])

  // We are actually doing an optimized version of the algorithm described in the header comment. The general alg is to
  // go item-by-item, summing them up until we're in our view, then keep going until we're out. That's an O(n) on every
  // scroll event, and there will be a LOT of scroll events. However, we can do that one-by-one summing up-front (which
  // is still just O(n)) and create an array. Then we can do a binary search to find the start/end positions on scroll.
  // So that turns an `O(n)` on each scroll event to two `O(log(n))`s. So this part is where we pre-compute that array.
  const virtualItems = useMemo(() => {
    const virtualItems: VirtualItem[] = []

    let currentOffset = 0
    for (let i = 0; i < itemsToDisplay.length; i++) {
      const item = itemsToDisplay[i]
      const isSelected = !!selectedItemCountMap[item.id]
      const itemHeight = getItemHeight(
        canSelectMultiple,
        isSelected,
        isSearching,
        itemHasPath(item),
      )

      virtualItems.push({
        height: itemHeight,
        offset: currentOffset,
      })
      currentOffset += itemHeight
    }
    return virtualItems
  }, [canSelectMultiple, isSearching, itemsToDisplay, selectedItemCountMap])

  // This is where we do the binary search to find the current elements (changes based on "scrollTop").
  const [startIndex, endIndex] = useMemo(() => {
    // We don't want to "start" until the offset header thing is scrolled out of view.
    const viewStartPosition = Math.max(0, scrollTop - listOffset)
    const viewEndPosition = viewStartPosition + containerHeight

    const startIndex = binaryVirtualItemSearch(
      virtualItems,
      viewStartPosition,
      true,
    )
    const endIndex = binaryVirtualItemSearch(
      virtualItems,
      viewEndPosition,
      false,
    )

    return [startIndex, endIndex]
  }, [scrollTop, listOffset, containerHeight, virtualItems])

  return [startIndex, endIndex]
}

// Number of items we're going to show above/below the ones we are supposed to render. If the user scrolls quicker than
// we can compute, then they'll see blank space before we render the items. This can be mitigated a bit by
// "pre-rendering" this many items ahead. The bigger this is, the less "blankness" they'll see, and the smaller the
// performance gains are from virtualization.
const OVERSCROLL = 5

type ItemListProps = {
  searchTerm: string
  itemsToDisplay: (ItemPickerItem | ItemPickerCategory)[]
  selectedItemCountMap: Record<Guid, number>
  setSelectedCategoryPath: StateSetter<Guid[]>
  onItemSelect: (id: Guid, count: number) => void
  canSelectMultiple?: boolean
  hideMultipleQuantityPicker?: boolean
  scrollTop: number
  showItemValue?: boolean
  loading?: boolean
  emptyState?: React.ReactNode
  enablePhotos?: boolean
}

const ItemList = React.memo<ItemListProps>(
  ({
    searchTerm,
    itemsToDisplay,
    selectedItemCountMap,
    setSelectedCategoryPath,
    onItemSelect,
    canSelectMultiple = false,
    hideMultipleQuantityPicker = false,
    scrollTop,
    showItemValue = true,
    loading = false,
    emptyState,
    enablePhotos = false,
  }) => {
    const isMobile = useIsMobile()

    const isSearching = !!searchTerm

    const listContainerRef = useRef<HTMLDivElement>(null)

    // This list can be incredibly long. This is particularly true when you search, since it "unfurls" all the
    // categories. We do a lot of rendering work for each element (not the least of which is the highlighting of the
    // search text). So we use a virtualization strategy to not render invisible elements.
    const [rawStartIndex, rawEndIndex] = useVisibleIndexes(
      listContainerRef,
      scrollTop,
      itemsToDisplay,
      isSearching,
      canSelectMultiple && !hideMultipleQuantityPicker,
      selectedItemCountMap,
    )

    const startIndex = Math.max(0, rawStartIndex - OVERSCROLL)
    const endIndex = Math.min(
      itemsToDisplay.length - 1,
      rawEndIndex + OVERSCROLL,
    )

    // In a case where the list of items is long enough that any of the virtualization stuff we're doing is relevant,
    // the number of selected items, and the number of top level (no category) items, should be dwarfed by the number of
    // items in the list. The only thing on an item-by-item basis that determines its height is if it's selected and if
    // we're searching and it has a category (we display the path underneath). So when calculating our buffer heights
    // (see the `useMemo` below to see what that means) it's much faster to just find all the SELECTED and NO-PATH items
    // before and after our visible window, take the differences of those and the total numbers, and multiply. I'm
    // `useMemo`ing this separately from the buffers, because this only changes when the items change or items are
    // selected and the buffers change on scroll. I want to split out any calculation I can avoid doing per-scroll
    const [orderedSelectedIndexes, orderedNoPathIndexes] = useMemo(() => {
      const selectedIndexes: number[] = []
      const noPathIndexes: number[] = []
      for (let i = 0; i < itemsToDisplay.length; i++) {
        const item = itemsToDisplay[i]
        if (selectedItemCountMap[item.id]) {
          selectedIndexes.push(i)
        }
        if (!itemHasPath(item)) {
          noPathIndexes.push(i)
        }
      }
      return [selectedIndexes, noPathIndexes]
    }, [itemsToDisplay, selectedItemCountMap])

    // This is for a further optimization for virtualization. We could loop through each element and say "if it's out of
    // our range, render the container with the correct item height but just don't render the contents". But that's
    // still more rendering work than we should need to do (when you type "h" for ECS you get 3588 results!). So
    // instead, we only render the items we want to render, then to maintain the correct height/scroll/etc we put empty
    // divs above and below the list that are the sizes of the elements that are hidden.
    const [topBufferHeight, bottomBufferHeight] = useMemo(() => {
      const numBefore = startIndex
      const numAfter = itemsToDisplay.length - 1 - endIndex
      // See above comment on `orderedSelectedIndexes`
      let selectedBeforeCount = 0
      let selectedAfterCount = 0
      // If they can't select multiple, then we don't show the picker thing anyway, so we don't have to adjust the
      // height for them.
      if (canSelectMultiple && !hideMultipleQuantityPicker) {
        for (const index of orderedSelectedIndexes) {
          // Don't count the starts and ends themselves since we're already rendering them, making their height actually
          // apply to the DOM.
          if (index < startIndex) {
            selectedBeforeCount++
          }
          if (index > endIndex) {
            selectedAfterCount++
          }
        }
      }

      let noPathBeforeCount = 0
      let noPathAfterCount = 0

      // We only show the path if we're searching, so don't do any of this crap if we're not searching
      if (isSearching) {
        for (const index of orderedNoPathIndexes) {
          if (index < startIndex) {
            noPathBeforeCount++
          }
          if (index > endIndex) {
            noPathAfterCount++
          }
        }
      } else {
        // If we aren't searching, then none of the items have paths for our purposes
        noPathBeforeCount = numBefore
        noPathAfterCount = numAfter
      }

      const topBufferHeight =
        numBefore * DEFAULT_ITEM_HEIGHT +
        selectedBeforeCount * SELECTED_ADDITIONAL_HEIGHT +
        (numBefore - noPathBeforeCount) * WITH_PATH_ADDITIONAL_HEIGHT

      const bottomBufferHeight = Math.max(
        numAfter * DEFAULT_ITEM_HEIGHT +
          selectedAfterCount * SELECTED_ADDITIONAL_HEIGHT +
          (numAfter - noPathAfterCount) * WITH_PATH_ADDITIONAL_HEIGHT,
      )

      return [topBufferHeight, bottomBufferHeight]
    }, [
      canSelectMultiple,
      endIndex,
      hideMultipleQuantityPicker,
      isSearching,
      itemsToDisplay.length,
      orderedNoPathIndexes,
      orderedSelectedIndexes,
      startIndex,
    ])

    const truncatedList = useMemo(
      () => itemsToDisplay.slice(startIndex, endIndex + 1),
      [endIndex, itemsToDisplay, startIndex],
    )

    const hasNoItems = !itemsToDisplay.length

    const { pricebookPhotosEnabled } = useIsPricebookPhotosEnabled()

    return (
      <div ref={listContainerRef}>
        <div style={{ height: `${topBufferHeight}px` }} />

        {loading ? (
          <LoadingSpinner />
        ) : hasNoItems ? (
          <div
            className={classNames(
              'rounded-lg border border-solid border-[#D9D9D9] bg-bz-gray-200 px-4 py-5 text-sm text-bz-gray-700',
              {
                // I want 24px space between this and the header. The header has a 24px bottom margin on non-mobile and
                // 16 on mobile, so supplement with 8px extra on mobile
                'mt-2': isMobile,
              },
            )}
          >
            {isSearching ? (
              <>
                No results matched{' '}
                <span className="font-semibold">"{searchTerm}"</span>. Please
                try again.
              </>
            ) : (
              emptyState ?? 'This category has no items.'
            )}
          </div>
        ) : (
          <List
            className={classNames(
              // To get a top border, I need a header. So I make a blank header, which gets 1.5rem of padding, then do
              // `mt-[-1.5rem]` to counteract that
              'mt-[-1.5rem]',
              // This will make the colored-in selected items span the full container
              isMobile ? 'mx-[-1rem]' : 'mx-[-1.5rem]',
            )}
            // This is to give us a top border
            header=" "
          >
            {truncatedList.map(item => {
              const count = selectedItemCountMap[item.id] ?? 0
              const isSelected = !!count

              const itemIsCategory = isCategory(item)

              return (
                <List.Item
                  key={item.id}
                  className={classNames(
                    'flex flex-col items-start justify-center p-0 text-base transition-all duration-200 ease-in-out',
                    {
                      'bg-bz-gray-300 text-bz-primary': isSelected,
                      'opacity-25': item.disabled,
                    },
                  )}
                  style={{
                    height:
                      itemIsCategory || !item.customNode
                        ? `${getItemHeight(
                            canSelectMultiple && !hideMultipleQuantityPicker,
                            isSelected,
                            isSearching,
                            itemHasPath(item),
                          )}px`
                        : 'auto',
                  }}
                >
                  <div
                    className={classNames(
                      'flex w-full flex-1 select-none flex-col justify-center text-bz-gray-1000',
                      {
                        'cursor-pointer': !item.disabled,
                      },
                      isMobile ? 'px-4' : 'px-6',
                    )}
                    onClick={
                      item.disabled
                        ? undefined
                        : () =>
                            itemIsCategory
                              ? setSelectedCategoryPath(path => [
                                  ...path,
                                  item.id,
                                ])
                              : onItemSelect(item.id, isSelected ? 0 : 1)
                    }
                  >
                    <div className="flex w-full flex-row items-center text-bz-gray-1000">
                      {pricebookPhotosEnabled &&
                        enablePhotos &&
                        (item.icon ? (
                          item.icon
                        ) : (
                          <PricebookPhotoThumbnail
                            noPopup
                            cdnUrl={item.cdnUrl}
                          />
                        ))}
                      {(itemIsCategory || !item.customNode) && (
                        <div className="mr-2 line-clamp-2 flex-1 font-semibold">
                          {isSearching ? (
                            <HighlightedText highlightText={searchTerm}>
                              {item.name}
                            </HighlightedText>
                          ) : (
                            <span
                              className={classNames({
                                'text-bz-primary': isSelected,
                              })}
                            >
                              {item.name}
                            </span>
                          )}
                          {itemIsCategory && (
                            <span className="ml-2 text-bz-gray-700">
                              ({item.items.length})
                            </span>
                          )}
                        </div>
                      )}
                      {!itemIsCategory && item.customNode}

                      {itemIsCategory ? (
                        <Button
                          shape="circle"
                          type="text"
                          icon={<FontAwesomeIcon icon={faChevronRight} />}
                        />
                      ) : (
                        <>
                          {showItemValue && (
                            <div>
                              {(item.numberFormatter ?? moneyFormatter)(
                                item.value,
                              )}
                            </div>
                          )}
                          {canSelectMultiple ? (
                            <BzCheckBox
                              className={CHECKBOX_CLASS_NAME}
                              value={isSelected}
                              uncheckedColor="text-bz-gray-700"
                            />
                          ) : (
                            <BzRadioButton
                              value=""
                              className={CHECKBOX_CLASS_NAME}
                              checked={isSelected}
                              // We're handling this in the click on the parent so don't do anything
                              onChange={() => {}}
                            />
                          )}
                        </>
                      )}
                    </div>
                    {itemHasPath(item) && (
                      <div className="flex w-full max-w-full overflow-hidden">
                        <div className="direction-rtl truncate pt-2.5 text-sm text-bz-gray-700">
                          {item.path.map((category, i) => (
                            <span key={`${category}_${i}`}>
                              {i !== 0 && (
                                <FontAwesomeIcon
                                  className="px-2 text-bz-gray-500"
                                  icon={faChevronRight}
                                />
                              )}
                              <HighlightedText highlightText={searchTerm}>
                                {category}
                              </HighlightedText>
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {isSelected &&
                    canSelectMultiple &&
                    !isCategory(item) &&
                    !hideMultipleQuantityPicker && (
                      <div
                        onClick={stopPropagation}
                        className={classNames(
                          'flex w-full flex-row items-center bg-bz-primary py-2.5 text-bz-gray-100',
                          isMobile ? 'px-4' : 'px-6',
                        )}
                      >
                        <div className="flex flex-1 flex-row items-center">
                          <Button
                            className="text-base text-bz-primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faChevronLeft} />}
                            onClick={() => onItemSelect(item.id, count - 1)}
                          />
                          <div className="mx-4 min-w-[30px] text-center">
                            x{count}
                          </div>
                          <Button
                            className="text-base text-bz-primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faChevronRight} />}
                            onClick={() => onItemSelect(item.id, count + 1)}
                          />
                        </div>
                        {showItemValue && (
                          <div>
                            {(item.numberFormatter ?? moneyFormatter)(
                              item.value * selectedItemCountMap[item.id],
                            )}
                          </div>
                        )}
                        {/* Invisible checkbox to get the price to line up properly */}
                        <div className="invisible">
                          <BzCheckBox
                            className="ml-3"
                            value={false}
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                    )}
                </List.Item>
              )
            })}
          </List>
        )}
        <div style={{ height: `${bottomBufferHeight}px` }} />
      </div>
    )
  },
)

export type ItemPickerRenderCreateItemProps = {
  onCancel: () => void
  onSave: (itemGuid: Guid, count: number) => void
}

type ItemPickerProps = {
  title: string
  subtitle?: string
  items: (ItemPickerItem | ItemPickerCategory)[]
  adHocItems?: ItemPickerItem[]
  onSave?: () => void
  onBackFallback?: () => void
  onCancel: () => void
  selectedItemCountMap: Record<Guid, number>
  onItemSelect: (id: Guid, count: number) => void
  canSelectMultiple?: boolean
  itemLabel?: string
  addText?: string
  renderCreateItem?: (props: ItemPickerRenderCreateItemProps) => React.ReactNode
  isLoading?: boolean
  isSubmitting?: boolean
  hideSearch?: boolean
  hideMultipleQuantityPicker?: boolean
  hideFooter?: boolean
  hideSubmitButton?: boolean
  hideCancelButton?: boolean
  hideCart?: boolean
  onSearch?: (term: string) => void
  cancelText?: string
  submitText?: string
  preSelectedAdHocItem?: ItemPickerItem
  adHocEditButtonDisabled?: boolean
  allowEmpty?: boolean
  showItemValue?: boolean
  emptyState?: React.ReactNode
  loading?: boolean
  searchInputRef?: React.RefObject<InputRef>
  appendAdHocItems?: boolean
  enablePhotos?: boolean
}

export const ItemPicker = React.memo<ItemPickerProps>(
  ({
    title,
    subtitle,
    items: externalItems,
    onBackFallback,
    onCancel,
    onSave,
    selectedItemCountMap,
    onItemSelect,
    canSelectMultiple,
    itemLabel = 'item',
    addText = 'Add',
    renderCreateItem,
    adHocItems = [],
    isLoading,
    isSubmitting,
    hideSearch = false,
    hideMultipleQuantityPicker = false,
    hideCancelButton = false,
    hideSubmitButton = false,
    hideCart = false,
    onSearch,
    hideFooter = false,
    cancelText = 'Cancel',
    submitText,
    preSelectedAdHocItem,
    adHocEditButtonDisabled,
    allowEmpty,
    showItemValue = true,
    emptyState,
    loading = false,
    searchInputRef,
    appendAdHocItems,
    enablePhotos = false,
  }) => {
    const isMobile = useIsMobile()

    const items = useMemo(() => {
      if (preSelectedAdHocItem) {
        return [preSelectedAdHocItem, ...externalItems]
      }
      return externalItems
    }, [externalItems, preSelectedAdHocItem])

    const [selectedCategoryPath, setSelectedCategoryPath] = useState<Guid[]>([])

    const selectedCategory = useMemo(() => {
      let currentLevelCategory: ItemPickerCategory | undefined
      for (const guid of selectedCategoryPath) {
        const item = (currentLevelCategory?.items ?? items).find(
          item => item.id === guid,
        )
        // You shouldn't be able to make a path to something that isn't there
        if (!item) {
          throw new Error(`Item not found: ${guid}`)
        }
        // Also shouldn't be possible. You shouldn't be able to make a path to a non-category
        if (!isCategory(item)) {
          throw new Error(`Item is not a category: ${guid}`)
        }
        currentLevelCategory = item
      }
      return currentLevelCategory
    }, [items, selectedCategoryPath])

    const atRoot = isNullish(selectedCategory)

    const [cart, cartTotal] = useMemo<[CartItem[], number]>(() => {
      const cart: CartItem[] = []
      let cartTotal = 0
      const seenItemGuids = new Set<Guid>()

      const recursivelyAddItems = (
        items: (ItemPickerCategory | ItemPickerItem)[],
      ) => {
        for (const item of items) {
          if (isCategory(item)) {
            recursivelyAddItems(item.items)
          } else if (
            selectedItemCountMap[item.id] &&
            !seenItemGuids.has(item.id)
          ) {
            const count = selectedItemCountMap[item.id]
            cartTotal += item.value * count
            cart.push({
              ...item,
              count,
            })
            seenItemGuids.add(item.id)
          }
        }
      }

      recursivelyAddItems(items)

      for (const item of adHocItems) {
        const count = selectedItemCountMap[item.id]
        if (count) {
          cartTotal += item.value * count
          cart.push({
            ...item,
            count,
          })
        }
      }

      return [cart, cartTotal]
    }, [adHocItems, items, selectedItemCountMap])

    const [searchTerm, setSearchTermRaw] = useState('')

    const setSearchTerm = useCallback(
      (term: string) => {
        setSearchTermRaw(term)
        // When they search, we want to reset the category path
        setSelectedCategoryPath([])
        onSearch?.(term)
      },
      [onSearch],
    )

    const isSearching = !!searchTerm

    const itemsToDisplay = useMemo(() => {
      let ourItems = items
      if (appendAdHocItems) {
        ourItems = [...ourItems, ...adHocItems]
      }
      if (isSearching) {
        const lcSearchTerm = searchTerm.toLowerCase()
        const search = (
          items: (ItemPickerItem | ItemPickerCategory)[],
          path: string[],
        ) => {
          const results: SearchedItemPickerItem[] = []
          for (const item of items) {
            if (isCategory(item)) {
              const childResults = search(item.items, [...path, item.name])
              if (childResults.length) {
                results.push(...childResults)
              }
            } else if (
              !item.ignoreSearch &&
              (item.name.toLowerCase().includes(lcSearchTerm) ||
                path.some(p => p.toLowerCase().includes(lcSearchTerm)))
            ) {
              results.push({
                ...item,
                path,
              })
            }
          }
          return results
        }
        return search(ourItems, [])
      }
      return selectedCategory?.items ?? ourItems
    }, [
      adHocItems,
      appendAdHocItems,
      isSearching,
      items,
      searchTerm,
      selectedCategory?.items,
    ])

    const [displayTitle, displaySubtitle] = useMemo(() => {
      if (isSearching) {
        return [
          `${
            itemsToDisplay.length === 0 ? 'No' : itemsToDisplay.length
          } ${toPlural(itemsToDisplay.length, 'result')}`,
        ]
      } else if (atRoot) {
        return [title, subtitle]
      } else {
        return [selectedCategory?.name ?? '']
      }
    }, [
      atRoot,
      isSearching,
      itemsToDisplay.length,
      selectedCategory?.name,
      subtitle,
      title,
    ])

    const [isCreateMode, setIsCreateMode] = useState(false)

    const onBack = useMemo(() => {
      if (isCreateMode) {
        return () => {
          setSelectedCategoryPath([])
          setIsCreateMode(false)
        }
      } else if (isSearching) {
        return () => {
          setSearchTerm('')
        }
      } else if (!atRoot) {
        return () => {
          setSelectedCategoryPath(path => R.dropLast(1, path))
        }
      } else {
        return onBackFallback
      }
    }, [atRoot, isCreateMode, isSearching, onBackFallback, setSearchTerm])

    const canCreate = !!renderCreateItem

    const createModeContent = useMemo(() => {
      // "!onBack" is to appease TypeScript. I know it will be defined. `createModeContent` will only appear if
      // `replaceWithCreateMode` is true. That's only true if `isCreateMode` is true. When that's true, `onBack` is
      // defined.
      if (!canCreate || !onBack) {
        return null
      }
      return renderCreateItem({
        onCancel: onBack,
        onSave: (itemGuid: Guid, count: number) => {
          onItemSelect(itemGuid, count)
          onBack()
        },
      })
    }, [canCreate, onBack, onItemSelect, renderCreateItem])

    // If we have a radio-button situation and we have a pre selected item, then our button will show "Edit"
    const adHocButtonEditMode = !canSelectMultiple && !!preSelectedAdHocItem

    // If we're in edit mode and they selected an option that isn't the custom one, we disable the edit button
    const resolvedAdHocEditButtonDisabled =
      adHocEditButtonDisabled ||
      (adHocButtonEditMode && !selectedItemCountMap[preSelectedAdHocItem.id])

    const scrollBarWidth = useScrollbarWidth()

    const [scrollTop, setScrollTop] = useState(0)

    if (isCreateMode) {
      return <>{createModeContent}</>
    }

    const rest = hideSearch ? {} : { searchTerm, onSearch: setSearchTerm }
    return (
      <OnsiteModalContent
        alwaysShowScrollbar
        isLoading={isLoading}
        onBack={onBack}
        onClose={onCancel}
        header={displayTitle}
        subtitle={displaySubtitle}
        onScroll={setScrollTop}
        searchInputRef={searchInputRef}
        {...rest}
        footer={
          !hideFooter && (
            <>
              {canSelectMultiple && cart.length > 0 && !hideCart && (
                <div className="mt-1 flex min-w-0 max-w-full flex-row space-x-1 overflow-x-auto text-xs">
                  {showItemValue && (
                    <CartSquare header="Total" value={cartTotal} count={1} />
                  )}
                  {cart.map(item => (
                    <CartSquare
                      colored
                      key={item.id}
                      header={item.name}
                      showItemValue={showItemValue}
                      value={item.value}
                      count={item.count}
                      onDelete={() => onItemSelect(item.id, 0)}
                    />
                  ))}
                </div>
              )}
              <OnsiteModalFooter
                loading={isSubmitting}
                submitDisabled={!allowEmpty && cart.length === 0}
                onCancel={onCancel}
                onSubmit={onSave}
                cancelText={cancelText}
                submitText={
                  submitText ??
                  `${addText} ${
                    cart.length > 1
                      ? `${cart.length} ${toPlural(cart.length, itemLabel)}`
                      : itemLabel
                  }`
                }
                hideCancelButton={hideCancelButton}
                hideSubmitButton={hideSubmitButton}
              />
            </>
          )
        }
      >
        <ItemList
          scrollTop={scrollTop}
          searchTerm={searchTerm}
          itemsToDisplay={itemsToDisplay}
          selectedItemCountMap={selectedItemCountMap}
          setSelectedCategoryPath={setSelectedCategoryPath}
          onItemSelect={onItemSelect}
          canSelectMultiple={canSelectMultiple}
          showItemValue={showItemValue}
          hideMultipleQuantityPicker={hideMultipleQuantityPicker}
          emptyState={emptyState}
          loading={loading}
          enablePhotos={enablePhotos}
        />
        {/* This is to make sure there's blank space at the bottom for the "Create" button. Otherwise the last item in
            the list will always be covered by it. */}
        <div className="min-h-[60px] w-full" />
        {canCreate && (
          <Button
            data-testid="item-picker-create-button"
            type="primary"
            shape="round"
            icon={
              adHocButtonEditMode ? (
                <FontAwesomeIcon icon={faEdit} className="text-xl" />
              ) : (
                <FontAwesomeIcon icon={faPlus} className="text-2xl" />
              )
            }
            className={classNames(
              'absolute flex h-14 min-w-24 flex-row items-center justify-center font-semibold',
              isMobile ? 'bottom-4' : 'bottom-6',
            )}
            style={{
              // Because of how we have the scrolling and relative positioning in the parent, we need this to get it to line up properly
              right: isMobile ? 0 : `${scrollBarWidth}px`,
            }}
            onClick={() => setIsCreateMode(true)}
            disabled={resolvedAdHocEditButtonDisabled}
          >
            {adHocButtonEditMode ? 'Edit' : 'Create'}
          </Button>
        )}
      </OnsiteModalContent>
    )
  },
)
